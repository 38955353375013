import React, { Component } from "react";
/*-- Store --*/
import { connect } from "react-redux";
import {
  readServicePeriods,
  readTimeRules,
} from "../../service_periods/store/actionCreators";
import {
  readServiceCenters,
  readWallets,
  readPaygoAccountWallets,
} from "../../business/store/actionCreators";
import {
  makeReserve,
  updateReserve,
  freeReserve,
  changeProductQuantity,
  checkReserve,
} from "./../store/actionCreators";
import { selectPrePurchase, clearShoppingCar } from "../store/actionCreators";
import {
  readCategories,
  readProducts,
  readStockProducts,
  resetStoreCatalog,
} from "../../catalog/store/actionCreators";
import { readTimeUnits } from "../../system/store/actionCreators";
import {
  readTaxConfigurations,
  readTaxRates,
} from "../../taxes/store/actionCreators";

/*-- Custom Services --*/
import {
  getProductComponents,
  getWallets,
  getBalances,
} from "../services/SaleService";

/*-- Enums --*/
import { dateFormat, dateTimeFormat } from "../enums/OrdersEnums";
import { InventoryPurposesEnums } from "../../catalog/enums/ProductsEnums";
/*-- Custom Components --*/
import WrappedPrePurchaseForm from "../components/PrePurchaseForm";
import ProductList from "../components/ProductList";
/*-- Tools --*/
import { findObjectById, openMessage } from "../../shared/utils/utils";
import moment from "moment";

class BuyMain extends Component<any, any> {
  private intervalRefreshPaygoAccWallets: any;
  filtersProducts = {};

  componentDidMount(): void {
    this.handleResetStoreSale();
    const { selectedServiceConfigurationId } = this.props;
    const params = {
      page: 1,
      page_size: 10000,
      service_configuration_id: selectedServiceConfigurationId,
      is_active: true,
    };
    this.props.readServicePeriods([], params);
    this.props.readServiceCenters([], {
      service_configuration_id: selectedServiceConfigurationId,
    });
    this.props.readTimeUnits();
    this.props.readTimeRules();
    this.props.readTaxConfigurations();
    this.props.readTaxRates();

    getWallets({
      readWallets: this.props.readWallets,
      selectedServiceConfigurationId: selectedServiceConfigurationId,
      cb: this.getWalletsCB,
    });
    this.refreshBalances();
  }

  /**
   * Función callback de getWallets que consulta los balances del cliente.
   */
  private getWalletsCB = (response: any) => {
    getBalances({
      paygoAccWallets: response.paygoAccWallets,
      selectedAccount: this.props.selectedAccount,
      readPaygoAccountWallets: this.props.readPaygoAccountWallets,
      cb: this.getBalancesCB,
    });
  };

  /**
   * Función callback de getBalances que actualiza el estado de las accwallets
   */
  private getBalancesCB = (response: any) => {
    if (!response.error)
      this.setState({ paygoAccWallets: response.paygoAccWallets });
  };

  /**
   * Ejecuta la lectura de los balances del cliente, después de
   * esperar 4 minutos y se repite la ejecución.
   */
  refreshBalances() {
    this.intervalRefreshPaygoAccWallets = setInterval(
      () =>
        getBalances({
          paygoAccWallets: this.state.paygoAccWallets,
          selectedAccount: this.props.selectedAccount,
          readPaygoAccountWallets: this.props.readPaygoAccountWallets,
          cb: this.getBalancesCB,
        }),
      60 * 4000
    ); // Cada 4 minutos
  }

  /**
   * Suma por subcuenta los items en el carrito de compras
   * @param wallet_id
   */
  getTotalsForWallet(wallet_id: number): number {
    return this.props.shoppingCart
      .filter((detail: any) => detail.product.wallet_id === wallet_id)
      .reduce(
        (acc: number, detail: any) =>
          detail.product.price * detail.quantity + acc,
        0
      );
  }

  /**
   * Cuanto saldo se puede gastar de una billetera
   * @param wallet_slug
   */
  getFundsForWallet(wallet_slug: string): number {
    const currentAccount = this.props.user.paygo_accounts.find(
      (account: any) => account.account_id === this.props.selectedAccount
    );

    const currentWallet = this.props.accountWallets.find(
      (wallet: any) => wallet.subaccount === wallet_slug
    );

    const overdraft =
      currentAccount.legacy_client["overdraft_" + wallet_slug.toLowerCase()];
    const consumedToday =
      currentAccount.legacy_client.today_totals[wallet_slug];

    const amountMaxDaily =
      currentAccount.legacy_client["amount_ctrl_" + wallet_slug.toLowerCase()];
    const amountMaxCanSpend = amountMaxDaily - consumedToday;

    let currentFund =
      (currentWallet ? parseInt(currentWallet.balance) : 0) +
      (overdraft ? parseInt(overdraft) : 0);

    if (amountMaxDaily) {
      return Math.min(currentFund, amountMaxCanSpend);
    } else {
      return currentFund;
    }
  }

  /**
   * Si una billetera tiene suficiente saldo para un nuevo item
   * @param wallet_id
   * @param newPrice
   */
  hasUserFundsForProduct(wallet_id: any, newPrice: any): boolean {
    const wallet = this.props.serviceWallets.find(
      (wallet: any) => wallet.wallet_type_id === parseInt(wallet_id)
    );

    if (!wallet) return false;

    const costWallet = this.getTotalsForWallet(wallet_id);
    const fundsWallet = this.getFundsForWallet(wallet.wallet_type_slug);
    return fundsWallet >= costWallet + parseInt(newPrice);
  }

  handleSendSale = () => {
    // Se valida la reserva de todos los productos excluyendo los
    // productos que tengan como proposito de inventario "Solo venta".
    let reservation = {
      service_center_id: this.props.preOrderBuy.receiving_node.value,
      products: this.props.shoppingCart
        .filter(
          (detail: any) =>
            // eslint-disable-next-line
            detail.product.inventory_purpose_id != InventoryPurposesEnums.SALE
        )
        .map((detail: any) => ({
          paygo_id: detail.product.id,
          price: detail.product.price,
          quantity: detail.quantity,
          inventoryInfo: getProductComponents(detail.product),
        })),
    };

    this.props.checkReserve(
      reservation,
      () => {
        this.props.history.push("/sales/confirmation");
      },
      () => {
        openMessage(
          "error",
          "Algunos de los productos seleccionados ya no estan disponibles."
        );
      }
    );
  };

  private canBuy = (product: any, newQuantity: any = null) => {
    const currentDetail = this.props.shoppingCart.find(
      (d: any) => d.product.id === product.id
    );

    const currentAccount = this.props.user.paygo_accounts.find(
      (account: any) => account.account_id == this.props.selectedAccount
    );

    const maxQuantityServiceConfiguration =
      currentAccount.legacy_service_configuration?.max_app_product_count;

    // Si newQuantity es null, asigne la cantidad atual del producto
    // en el carrito de compras más uno (1).
    const currentQuantity = currentDetail ? currentDetail.quantity : null;

    if (newQuantity === null) {
      newQuantity = currentQuantity + 1;
    }

    if (
      !isNaN(maxQuantityServiceConfiguration) &&
      maxQuantityServiceConfiguration != "0" &&
      newQuantity > maxQuantityServiceConfiguration
    ) {
      openMessage("error", "Alcanzo el limite máximo de unidades por cliente");
      return false;
    }

    const operator =
      currentQuantity &&
      (newQuantity || newQuantity == 0) &&
      newQuantity < currentQuantity
        ? -1
        : 1;

    if (
      !this.hasUserFundsForProduct(product.wallet_id, product.price * operator)
    ) {
      openMessage("error", "Saldo máximo alcanzado");
      return false;
    }

    return true;
  };

  /**
   * Meneja el evento cuando el usuario seleciona un producto
   * @param product - objecto con todos los datos del producto
   */
  private handleChangedProduct = (product: any) => {
    if (!this.canBuy(product)) return;

    // eslint-disable-next-line
    if (product.inventory_purpose_id == InventoryPurposesEnums.SALE) {
      this.props.changeProductQuantity({ productId: product.id, product });
    } else {
      const reserve = {
        service_center_id: this.props.preOrderBuy.receiving_node.value,
        service_configuration_id: this.props.selectedServiceConfigurationId,
        product_id: product.id,
        reservation_quantity: 1,
        product_components: getProductComponents(product),
      };

      this.props.makeReserve(
        reserve,
        () => {
          this.props.changeProductQuantity({ productId: product.id, product });
        },
        () => {
          openMessage("error", "No hay más unidades disponibles");
        }
      );
    }
  };

  handleQuantities = (product: any, quantity: number) => {
    if (!this.canBuy(product, quantity)) return;

    const changeStoreQuantity = () => {
      this.props.changeProductQuantity({ productId: product.id, quantity });
    };

    // eslint-disable-next-line
    if (product.inventory_purpose_id == InventoryPurposesEnums.SALE) {
      changeStoreQuantity();
    } else {
      const reserve = {
        service_center_id: this.props.preOrderBuy.receiving_node.value,
        product_id: product.id,
        product_components: getProductComponents(product),
        reservation_value: quantity,
      };

      if (quantity <= 0) {
        reserve.reservation_value = 1;

        this.props.freeReserve(reserve, changeStoreQuantity, () => {
          openMessage("error", "No hay más unidades disponibles");
        });
      } else {
        this.props.updateReserve(reserve, changeStoreQuantity, () => {
          openMessage("error", "No hay más unidades disponibles");
        });
      }
    }
  };

  private handleSubmit = (dataForm: any) => {
    window.scrollTo(0, 0);
    const servicePeriod = findObjectById(
      this.props.servicePeriods,
      "service_period_id",
      dataForm.service_period.value
    );
    if (servicePeriod !== undefined) {
      const isAllowed = this.heCanBuy(servicePeriod, dataForm.datetime_due);
      if (isAllowed) {
        const filtersCategories = {
          is_active: { values: ["1"], filterType: "set" },
          show_in_app_block_list: { values: ["1"], filterType: "set" },
          bc_id: { values: servicePeriod.categories, filterType: "set" },
        };
        this.props.readCategories(
          [],
          {
            page_size: 20000,
            service_configuration_id: this.props.selectedServiceConfigurationId,
            spreadsheet_filters: JSON.stringify(filtersCategories),
          },
          (res: any) => {
            if (res.results.length) {
              const categoriesId = res.results.map((item: any) => item.id);
              const filtersProducts = {
                categories: { values: categoriesId, filterType: "set" },
                product_state_id: { values: ["1"], filterType: "set" },
                inventory_purpose_id: {
                  values: [null, "1", "3"],
                  filterType: "set",
                },
                can_sell_as_consumable_units: {
                  values: ["0"],
                  filterType: "set",
                },
                allowed_nodes: {
                  values: [dataForm.receiving_node.value, null],
                  filterType: "set",
                },
              };
              this.filtersProducts = filtersProducts;
              this.props.readStockProducts(
                [
                  this.props.selectedServiceConfigurationId,
                  dataForm.receiving_node.value,
                ],
                {},
                () => {
                  this.props.readProducts([], {
                    page_size: 20000,
                    service_configuration_id: this.props
                      .selectedServiceConfigurationId,
                    spreadsheet_filters: JSON.stringify(filtersProducts),
                  });
                }
              );
            }
            this.props.selectPrePurchase(dataForm);
          }
        );
      } else {
        const msg =
          "No es posible realizar comprar en la fecha indicada, " +
          "por favor seleccione una nueva fecha.";
        openMessage("warning", msg);
      }
    }
  };

  /**
   * Manrja el avento de buscar productos por nombres
   * @param keyword - texto a buscar
   * @param callBackSuccess
   */
  private handleSearchProduct = (
    keyword: string,
    callBackSuccess: Function
  ) => {
    const filtersProducts = {
      ...this.filtersProducts,
      name: { filterType: "text", type: "contains", filter: keyword },
    };
    this.props.readProducts(
      [],
      {
        page_size: 20000,
        service_configuration_id: this.props.selectedServiceConfigurationId,
        spreadsheet_filters: JSON.stringify(filtersProducts),
      },
      (res: any) => {
        callBackSuccess(res);
      }
    );
  };

  private handleResetStoreSale = () => {
    this.props.clearShoppingCar();
    this.props.selectPrePurchase(null);
    this.props.resetStoreCatalog();
  };

  /**
   * Permite validar si el usuario puede hacer un pedido o no
   * @param servicePeriod - objecto con toda la informacion del periodo de servicio selecionado
   * @param datetime_due - fecha selecionada para dia de entrega de pedido
   */
  private heCanBuy = (servicePeriod: any, datetime_due: any): boolean => {
    let isAllowed = false;
    const timeRules = this.props.timeRules.find(
      (item: any) =>
        item.rule_for_reservation_time_id ===
        servicePeriod.rule_for_reservation_time_id
    );
    const minTimeUnits = this.props.timeUnits.find(
      (item: any) =>
        item.time_unit_id === servicePeriod.min_preorder_time_unit_id
    );
    const maxTimeUnits = this.props.timeUnits.find(
      (item: any) =>
        item.time_unit_id === servicePeriod.max_preorder_time_unit_id
    );
    const nowDate = moment().format(dateFormat);
    // Valida si la fecha esta permitida
    if (servicePeriod.service_dates.length) {
      isAllowed = servicePeriod.service_dates.includes(nowDate);
    } else {
      let resultDays = [];
      const daysWeek: any = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 0,
      };
      for (let key in daysWeek) {
        if (servicePeriod[key]) {
          resultDays.push(daysWeek[key]);
        }
      }
      isAllowed = resultDays.includes(moment(datetime_due).day());
    }

    // Validamos la hora
    if (isAllowed && timeRules && minTimeUnits && maxTimeUnits) {
      const startTime = moment(servicePeriod.start_time, "HH:mm:ss");
      const endTime = moment(servicePeriod.end_time, "HH:mm:ss");
      let dateTimeStart = datetime_due.clone().set({
        hour: startTime.get("hour"),
        minute: startTime.get("minute"),
        second: startTime.get("second"),
      });
      let dateTimeEnd = datetime_due.clone().set({
        hour: endTime.get("hour"),
        minute: endTime.get("minute"),
        second: endTime.get("second"),
      });

      const initPreOrder = dateTimeStart
        .clone()
        .subtract(
          servicePeriod.min_preorder_time,
          minTimeUnits.slug.toLowerCase()
        );

      let endPreOrder = null;
      if (timeRules.slug === "BEFORE_START") {
        endPreOrder = dateTimeStart
          .clone()
          .subtract(
            servicePeriod.max_preorder_time,
            maxTimeUnits.slug.toLowerCase()
          );
      } else if (timeRules.slug === "AFTER_START") {
        endPreOrder = dateTimeStart
          .clone()
          .add(
            servicePeriod.max_preorder_time,
            maxTimeUnits.slug.toLowerCase()
          );
      } else if (timeRules.slug === "BEFORE_END") {
        endPreOrder = dateTimeEnd
          .clone()
          .subtract(
            servicePeriod.max_preorder_time,
            maxTimeUnits.slug.toLowerCase()
          );
      }
      isAllowed = moment().isBetween(initPreOrder, endPreOrder);
    }

    return isAllowed;
  };

  render() {
    const currentAccount = this.props.user.paygo_accounts.find(
      (account: any) =>
        account.account_id === parseInt(this.props.selectedAccount)
    );

    //const preOrderBuy = true;
    const {
      servicePeriods,
      serviceCenters,
      categories,
      products,
      stockProducts,
      preOrderBuy,
      loadingCategories,
      loadingProducts,
      loadingStockProducts,
      loadingWallets,
      loadingPaygoAccountWallets,
    } = this.props;

    return (
      <>
        {preOrderBuy ? (
          <ProductList
            currentAccount={currentAccount}
            handleQuantities={this.handleQuantities}
            shoppingCartItems={this.props.shoppingCart?.length}
            handleSendSale={this.handleSendSale}
            categories={categories}
            products={products}
            stockProducts={stockProducts}
            loadingProducts={
              loadingProducts ||
              loadingStockProducts ||
              loadingWallets ||
              loadingPaygoAccountWallets
            }
            onChangeProduct={this.handleChangedProduct}
            onChangeSearchProduct={this.handleSearchProduct}
            onResetView={this.handleResetStoreSale}
          />
        ) : (
          <WrappedPrePurchaseForm
            onSubmit={this.handleSubmit}
            loading={
              loadingCategories || loadingWallets || loadingPaygoAccountWallets
            }
            servicePeriods={servicePeriods}
            serviceCenters={serviceCenters}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  servicePeriods: storeData.service_periods.service_periods,
  serviceCenters: storeData.business.service_centers,
  selectedServiceConfigurationId:
    storeData.shared.selected_service_configuration_id,
  shoppingCart: storeData.sales.shopping_cart,
  categories: storeData.catalog.categories,
  products: storeData.catalog.products,
  stockProducts: storeData.catalog.stock_products,
  preOrderBuy: storeData.sales.pre_order_buy,
  taxConfigurations: storeData.taxes.tax_configurations,
  taxRates: storeData.taxes.tax_rates,
  loadingCategories: storeData.catalog.loading_categories,
  loadingProducts: storeData.catalog.loading_products,
  loadingStockProducts: storeData.catalog.loading_stock_products,
  loadingWallets: storeData.business.loading_wallets,
  loadingPaygoAccountWallets: storeData.business.loading_paygo_account_wallets,
  loadingTaxConfigurations: storeData.taxes.loading_tax_configurations,
  loadingTaxRates: storeData.taxes.loading_tax_rates,
  accountWallets: storeData.business.paygo_account_wallets,
  serviceWallets: storeData.business.wallets,
  selectedAccount: storeData.shared.selected_paygo_account_id,
  user: storeData.auth.user,
  timeRules: storeData.service_periods.time_rules,
  timeUnits: storeData.system.time_units,
});

const mapDispatchToProps = {
  readServicePeriods,
  readServiceCenters,
  makeReserve,
  updateReserve,
  freeReserve,
  checkReserve,
  changeProductQuantity,
  selectPrePurchase,
  readCategories,
  readProducts,
  clearShoppingCar,
  readStockProducts,
  resetStoreCatalog,
  readTimeUnits,
  readTimeRules,
  readWallets,
  readPaygoAccountWallets,
  readTaxConfigurations,
  readTaxRates,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyMain);
