import React, { Component } from "react";

/*-- Store --*/
import { connect } from "react-redux";
import { readSales, selectOrderToDeliver } from "../store/actionCreators";

/*-- Ant Design --*/
import { List, Skeleton } from "antd";
import Text from "antd/lib/typography/Text";

/*-- Components --*/
import CustomerBasicInformation from "../components/CustomerBasicInformation";

/*-- Enums --*/
import { dateFormatExtend, hourFormat, pagination } from "../enums/OrdersEnums";

/*-- Tools --*/
import moment from "moment";
import {
  getPaygoAccountFromServiceConfigSelected,
  getUserFullName,
  getUserPhotoUrl,
} from "../../shared/utils/utils";

class OrderSelectionMain extends Component<any, any> {
  componentDidMount() {
    this.getSales();
  }

  /**
   * Obtiene las ventas del cliente seleccionado
   */
  getSales() {
    const { serviceConfigId, selectedClient } = this.props;

    let paygoAccount = getPaygoAccountFromServiceConfigSelected(
      selectedClient,
      serviceConfigId
    );
    let clientId = paygoAccount?.legacy_client?.ii;

    const kwargs = {
      page: 1,
      page_size: pagination.page_size,
      ii_client: clientId,
      expand: "products,client_info,service_period,node,delivery_node",
      attention_state: "DSP",
    };

    this.props.readSales([], kwargs, false, (res: any) => {
      let sales = res.results;

      if (sales.length === 1) {
        this.props.selectOrderToDeliver(sales[0]);
        this.props.history.push("/sales/confirm-delivery");
      }
    });
  }

  handleSaleSelected(sale: any) {
    this.props.selectOrderToDeliver(sale);
    this.props.history.push("/sales/confirm-delivery");
  }

  render() {
    const {
      selectedClient,
      serviceConfigId,
      orders,
      loadingOrders,
    } = this.props;
    let customerFullName = getUserFullName(selectedClient);
    let customerPhoto = getUserPhotoUrl(selectedClient);
    let paygoAccount = getPaygoAccountFromServiceConfigSelected(
      selectedClient,
      serviceConfigId
    );
    let delivery_node_name = orders.length
      ? orders[0]?.delivery_node?.name
      : "";
    let userCode = "";

    if (paygoAccount) {
      userCode = paygoAccount.legacy_client?.code;
    }

    return (
      <div className="p-3 mx-2">
        <CustomerBasicInformation
          userName={customerFullName}
          userPhoto={customerPhoto}
          userCode={userCode}
          delivery_node_name={delivery_node_name}
        />

        <Text className="mt-3" style={{ color: "#31C81B" }} strong>
          Seleccione el pedido a entregar
        </Text>

        <List
          loading={loadingOrders}
          itemLayout="horizontal"
          dataSource={orders}
          renderItem={(order: any) => (
            <List.Item
              onClick={() => this.handleSaleSelected(order)}
              actions={[
                <p className="mb-0">
                  {moment(order?.datetime_atention).format(hourFormat)}
                </p>,
              ]}
            >
              <Skeleton avatar title={false} loading={loadingOrders} active>
                <List.Item.Meta
                  title={order?.service_period?.name}
                  description={`
                    ${moment(order?.datetime_atention).format(
                      dateFormatExtend
                    )} ${order?.delivery_node?.name}`}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  selectedClient: storeData.sales.selected_client,
  serviceConfigId: storeData.shared.selected_service_configuration_id,
  orders: storeData.sales.orders,
  loadingOrders: storeData.sales.loading_orders,
});

const mapDispatchToProps = {
  readSales,
  selectOrderToDeliver,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSelectionMain);
