import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface InterfaceItem {
  title: string;
  path: string;
  icon: any;
  selectedIcon: any;
}

export const clientMenu: Array<InterfaceItem> = [
  {
    title: "Mi cuenta",
    path: "/",
    icon: <FontAwesomeIcon icon={["far", "user"]} size="2x" />,
    selectedIcon: (
      <FontAwesomeIcon icon={["far", "user"]} size="2x" color="green" />
    ),
  },
  {
    title: "Pedidos",
    path: "/sales/list",
    icon: <FontAwesomeIcon icon={["far", "list-alt"]} size="2x" />,
    selectedIcon: (
      <FontAwesomeIcon icon={["far", "list-alt"]} size="2x" color="green" />
    ),
  },
  {
    title: "Compras",
    path: "/sales/buy",
    icon: <FontAwesomeIcon icon={["fas", "shopping-cart"]} size="2x" />,
    selectedIcon: (
      <FontAwesomeIcon
        icon={["fas", "shopping-cart"]}
        size="2x"
        color="green"
      />
    ),
  },
  {
    title: "Notificaciones",
    path: "/messages",
    icon: <FontAwesomeIcon icon={["far", "comment"]} size="2x" />,
    selectedIcon: (
      <FontAwesomeIcon icon={["far", "comment"]} size="2x" color="green" />
    ),
  },
];

export const deliveryMenu: Array<InterfaceItem> = [
  {
    title: "Pedidos",
    path: "/sales/for-dispatch",
    icon: <FontAwesomeIcon icon={["far", "list-alt"]} size="2x" />,
    selectedIcon: (
      <FontAwesomeIcon icon={["far", "list-alt"]} size="2x" color="green" />
    ),
  },
  {
    title: "Escanear código QR",
    path: "/",
    icon: <FontAwesomeIcon icon={["fas", "qrcode"]} size="2x" />,
    selectedIcon: (
      <FontAwesomeIcon icon={["fas", "qrcode"]} size="2x" color="green" />
    ),
  },
];
