/*-- Store --*/
import { getUrl } from "./urls";

/*-- Actions Types --*/
import { LOAD_MESSAGES } from "./actionTypes";

/*-- Tools --*/
import { RestDataSource } from "../../shared/webservice/RestDataSource";

export const readMessages = (message_id: number, kwargs: object) => async (
  dispatch: Function
) => {
  try {
    let args = message_id ? [message_id] : undefined;
    const dataSource = await new RestDataSource(
      getUrl(LOAD_MESSAGES, args, kwargs)
    ).getData();

    dispatch({ type: LOAD_MESSAGES, payload: dataSource });
  } catch (error) {}
};
