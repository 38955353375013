import { LOADING_TIME_UNITS, READ_TIME_UNITS } from "./actionTypes";
import { getUrl } from "./urls";

/*-- Tools --*/
import { sleep } from "../../shared/utils/utils";
import { RestDataSource } from "../../shared/webservice/RestDataSource";

export const readTimeUnits = (params: Object = {}) => async (
  dispatch: Function
) => {
  dispatch({ type: LOADING_TIME_UNITS, payload: true });
  await sleep();

  try {
    const dataSource = await new RestDataSource(
      getUrl(READ_TIME_UNITS, [], params)
    ).getData();

    dispatch({
      type: READ_TIME_UNITS,
      payload: dataSource?.results,
    });
    dispatch({ type: LOADING_TIME_UNITS, payload: false });
  } catch (e) {
    dispatch({ type: LOADING_TIME_UNITS, payload: false });
  }
};
