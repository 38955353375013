import React from "react";
import OrderConfirmationMain from "../containers/OrderConfirmationMain";

const OrderManagement = (props: any) => {
  return (
    <React.Fragment>
      <OrderConfirmationMain {...props} />
    </React.Fragment>
  );
};

export default OrderManagement;
