import React from "react";
import QrScanMain from "../containers/QrScanMain";

const QrScan = (props: any) => {
  return (
    <React.Fragment>
      <QrScanMain {...props} />
    </React.Fragment>
  );
};

export default QrScan;
