/*-- Store --*/
import { getUrl } from "./urls";
import { CLEAR_MESSAGE, GENERATE_USER_QR, STORE_MESSAGE } from "./actionTypes";

/*-- Tools --*/
import { RestDataSource } from "../../shared/webservice/RestDataSource";

/**
 *
 * @param data
 * @param successCallback
 * @param errorCallback
 */
export const generateQRCode = (
  paygoAccountId: number,
  successCallback: Function = () => {},
  errorCallback: Function = (msg: string) => {}
) => async (dispatch: Function) => {
  try {
    const response = await new RestDataSource(
      getUrl(GENERATE_USER_QR, [paygoAccountId, "qr"])
    ).getData();

    successCallback(response.access);
  } catch (error) {
    errorCallback(error);
  }
};

export const setMessageInQrScanMain = (messageObject: any) => (
  dispatch: Function
) => {
  dispatch({
    type: STORE_MESSAGE,
    payload: messageObject,
  });
};

export const clearMessageInQrScanMain = () => (dispatch: Function) => {
  dispatch({
    type: CLEAR_MESSAGE,
  });
};
