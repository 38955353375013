import React, { forwardRef } from "react";

/*-- Ant Design --*/
import { FormInstance } from "antd/lib/form";
import { Form, Input, Button, Row } from "antd";

/*-- Font-Awesome --*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*-- Tools --*/
import { isRequired } from "../../shared/validators/generics";

interface Props {
  onSubmit: any;
  history?: any;
}

const SecurityTokenForm = (props: Props, ref: React.Ref<FormInstance>) => {
  const { onSubmit } = props;

  return (
    <Form ref={ref} onFinish={onSubmit}>
      <Row justify="center" className="mt-3">
        <p style={{ textAlign: "center" }}>
          Se ha enviado un código de seguridad
          <br /> a su email, por favor digitelo.
        </p>
      </Row>

      <Row justify="center" className="mt-2">
        <Form.Item name="token" rules={[isRequired]}>
          <Input
            placeholder="Código de seguridad"
            bordered={false}
            className="one-border-input"
            size="large"
            prefix={
              <FontAwesomeIcon icon={["fas", "unlock-alt"]} color="#3BC62E" />
            }
          />
        </Form.Item>
      </Row>

      <Row justify="center" className="mt-4">
        <Form.Item>
          <Button
            size="large"
            style={{ width: 180 }}
            className="btn-success mt-3"
            shape="round"
            htmlType="submit"
          >
            Seguir
          </Button>
        </Form.Item>
      </Row>

      <Row justify="center" className="mt-2">
        <b
          onClick={() => {
            props.history.push("/home");
          }}
          style={{ color: "#1A60B4" }}
        >
          Regresar
        </b>
      </Row>
    </Form>
  );
};

const WrappedLoginForm = forwardRef(SecurityTokenForm);
export default WrappedLoginForm;
