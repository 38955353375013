import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";
import DefaultLayout from "../modules/layouts/containers/DefaultLayout/DefaultLayoutMain";

interface PrivateRouteProps extends RouteProps {
  isLoggedIn?: boolean;
}

const AuthenticatedRoute = (appProps: PrivateRouteProps) => {
  // `component: Component` is not typing, it assign the value to a new variable.
  const { isLoggedIn, component: Component, ...rest }: any = appProps;

  return (
    <Route
      {...rest}
      render={(props) =>
        appProps.isLoggedIn ? (
          <DefaultLayout history={props.history}>
            <Component {...props} />
          </DefaultLayout>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (storeData: any) => ({
  isLoggedIn: storeData.auth.is_logged_in,
});

export default connect(mapStateToProps, null)(AuthenticatedRoute);
