import React from "react";

/*-- Ant Design --*/
import { Row, Col, Divider } from "antd";
import Text from "antd/lib/typography/Text";

/*-- Components --*/
import CustomAvatar from "../../shared/components/Avatar";

interface Props {
  userName: string;
  userPhoto?: string;
  userCode: string;
  delivery_node_name: string;
}

const CustomerBasicInformation = (props: Props) => {
  const { userName, userPhoto, userCode, delivery_node_name } = props;

  return (
    <>
      <Row>
        <Col>
          <h2 className="text-capitalize m-block-none">{userName}</h2>
        </Col>
      </Row>

      <Row className="my-2 mb-3" align="middle">
        <Col xs={12} sm={12} md={6} lg={8} xl={10}>
          <CustomAvatar src={userPhoto} name={userName} />
        </Col>

        <Col xs={12} sm={24} md={12} lg={8} xl={10}>
          <Text strong>Código del estudiante:</Text>
          <h3>{userCode}</h3>

          <Divider style={{ margin: "4px 0" }} />

          <Text strong>{delivery_node_name}</Text>
        </Col>
      </Row>
    </>
  );
};

export default CustomerBasicInformation;
