import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

/*-- Css --*/
import "./CustomMenuBar.css";

/*-- Custom --*/
import { MenuBarItem } from "./MenuBarItem";

/*-- Tools --*/
import { clientMenu, deliveryMenu } from "../../utils/menuItems";

interface Props {
  userRole: any;
}

export function CustomMenuBar(props: Props) {
  const [tabs, setTabs] = useState([] as any);
  const history = useHistory();
  const location = useLocation();
  const { userRole } = props;

  const mapRoutes = (allMenu: any) => {
    return allMenu.map((item: any) => {
      return {
        ...item,
        selected: location.pathname === item.path,
        onClick: () => {
          history.push(item.path);
        },
      };
    });
  };

  useEffect(
    () => {
      if (userRole === "CLIENT") {
        setTabs(mapRoutes(clientMenu));
      } else if (userRole === "DELIVERY") {
        setTabs(mapRoutes(deliveryMenu));
      }
    },
    // eslint-disable-next-line
    [userRole, location]
  );

  return (
    <div className="custom-menu-bar">
      {tabs.map((tab: any, index: number) => (
        <MenuBarItem
          title={tab.title}
          key={index}
          selected={tab.selected}
          onClick={tab.onClick}
          icon={tab.icon}
          selectedIcon={tab.selectedIcon}
        />
      ))}
    </div>
  );
}
