import React, { Component } from "react";

/*-- Css --*/
import "./DefaultLayout.css";

/*-- Redux --*/
import { connect } from "react-redux";
import { signOut } from "../../../auth/store/actionCreators";
import {
  selectPayGOAccount,
  selectServiceConfigurationId,
} from "../../../shared/store/actionCreators";

/*-- Ant-design --*/
import { Layout } from "antd";

/*-- Custom --*/
import { CustomHeader } from "../../components/CustomHeader/CustomHeader";
import { CustomMenuBar } from "../../components/CustomMenuBar/CustomMenuBar";
import { CustomSider } from "../../components/CustomSider/CustomSider";

/*-- Tools --*/
import { getUserFullName } from "../../../shared/utils/utils";

const { Content } = Layout;
const base = process.env.REACT_APP_API_URL;

interface Props {
  children: any;
  user?: any;
  selectedPayGOAccountId?: number | null;
  selectPayGOAccount: any;
  selectServiceConfigurationId: any;
  signOut: any;
  history: any;
}

class DefaultLayout extends Component<Props> {
  private handleSelectPayGOAccount = (paygoAccountId: any) => {
    const {
      user,
      selectServiceConfigurationId,
      selectPayGOAccount,
    } = this.props;

    const paygoAccountSelected = user?.paygo_accounts?.find(
      (item: any) => item.account_id === paygoAccountId
    );

    if (paygoAccountSelected) {
      let serviceConfigId =
        paygoAccountSelected.legacy_service_configuration.ii;
      selectServiceConfigurationId(serviceConfigId);
    }
    selectPayGOAccount(paygoAccountId);
  };

  private handleConfigAccount = () => {
    this.props.history.push("/settings");
  };

  render() {
    const { children, user, selectedPayGOAccountId, signOut } = this.props;

    let payGOAccount = user.paygo_accounts?.find(
      (item: any) => item.account_id === selectedPayGOAccountId
    );

    let serviceConfigName = "";
    let serviceConfigPhoto = "";
    let userPhoto = "";
    let fullName = getUserFullName(user);

    if (payGOAccount) {
      userPhoto = payGOAccount?.legacy_client?.photo;
      serviceConfigName = payGOAccount.legacy_service_configuration.name;
      serviceConfigPhoto =
        payGOAccount.legacy_service_configuration.logo_assoc_small;

      if (serviceConfigPhoto) {
        let serviceConfigPhotoName = serviceConfigPhoto.split("/").pop();
        serviceConfigPhoto = `${base}/imgs_entity/${serviceConfigPhotoName}`;
      }
    }

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <CustomHeader
          userName={fullName}
          userPhoto={userPhoto}
          serviceConfigName={serviceConfigName}
          serviceConfigPhoto={serviceConfigPhoto}
          payGOAccounts={user?.paygo_accounts}
          handlePayGOAccountSelected={this.handleSelectPayGOAccount}
          handleSignOut={signOut}
          handleConfigAccount={this.handleConfigAccount}
        />

        <Layout>
          <CustomSider userRole={user.temp_role} />

          <Content style={{ marginTop: 64, marginBottom: 89 }}>
            {children}
          </Content>

          <CustomMenuBar userRole={user.temp_role} />
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  user: storeData.auth.user,
  selectedPayGOAccountId: storeData.shared.selected_paygo_account_id,
});

const mapDispatchToProps = {
  signOut,
  selectPayGOAccount,
  selectServiceConfigurationId,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
