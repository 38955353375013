import React, { Component } from "react";
import { Row, Col } from "antd";
import moment from "moment";

/*-- Store --*/
import { connect } from "react-redux";
import { readMessages } from "../store/actionCreators";

import MessagesList from "../components/MessagesList";
import MessageRead from "../components/MessageRead";

class MessagesMain extends Component<any, any> {
  actualPage = 1;
  dualScreen = false;

  constructor(props: any) {
    super(props);

    this.state = {
      messageReading: null,
    };
  }

  componentDidMount(): void {
    const currentAccount = this.props.user.paygo_accounts.find(
      (account: any) => account.account_id == this.props.selectedAccount
    );

    const maxDaysMessagesToRead =
      currentAccount.legacy_service_configuration?.max_messages_days_limit ||
      15;
    const formatedDateFilter = moment()
      .subtract(maxDaysMessagesToRead, "days")
      .format("YYYY-MM-DD");

    const messageId = this.props.match.params.message_id;
    this.setState({ messageId: messageId });
    this.props.readMessages(null, {
      dispatch_datetime_gte: formatedDateFilter,
    });

    let width = window.innerWidth;
    this.dualScreen = width > 700;
  }

  componentWillReceiveProps(newProps: any) {
    const messageId = newProps.match.params.message_id;
    this.setState({ messageId: messageId });
  }

  private handleOnSubmit = (values: any) => {};

  private handleOnChangePage = (reset: boolean) => {};

  /**
   * Lipia la data del fomulario de filtros
   * @param values objecto de todos los valores
   */
  private clearDataForm = (values: any) => {};

  handleMessageRead = (message: any) => {
    let width = window.innerWidth;

    if (width < 700) {
      this.dualScreen = false;
      this.props.history.push("/messages/" + message.message_id);
    }

    this.setState({ messageId: message.message_id });
  };

  render() {
    return (
      <>
        {this.dualScreen ? (
          <Row>
            <Col md={10} sm={24}>
              <MessagesList
                messages={this.props.messages}
                onMessageRead={this.handleMessageRead}
              />
            </Col>

            {this.state.messageId ? (
              <Col md={14}>
                <MessageRead
                  history={this.props.history}
                  messageId={this.state.messageId}
                  messages={this.props.messages}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
        ) : this.state.messageId ? (
          <MessageRead
            history={this.props.history}
            messageId={this.state.messageId}
            messages={this.props.messages}
          />
        ) : (
          <MessagesList
            messages={this.props.messages}
            onMessageRead={this.handleMessageRead}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  messages: storeData.messaging.messages,
  user: storeData.auth.user,
  selectedAccount: storeData.shared.selected_paygo_account_id,
});

const mapDispatchToProps = {
  readMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesMain);
