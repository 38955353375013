import {
  READ_TAX_CONFIGURATIONS,
  LOADING_TAX_CONFIGURATIONS,
  READ_TAX_RATES,
  LOADING_TAX_RATES,
} from "./actionTypes";

const INITIAL_STATE = {
  tax_configurations: [],
  loading_tax_configurations: false,
  tax_rates: [],
  loading_tax_rates: false,
};

export default function (storeData: any = INITIAL_STATE, action: any) {
  switch (action.type) {
    case READ_TAX_CONFIGURATIONS:
      return {
        ...storeData,
        tax_configurations: action.payload,
      };
    case LOADING_TAX_CONFIGURATIONS:
      return {
        ...storeData,
        loading_tax_configurations: action.payload,
      };
    case READ_TAX_RATES:
      return {
        ...storeData,
        tax_rates: action.payload,
      };
    case LOADING_TAX_RATES:
      return {
        ...storeData,
        loading_tax_rates: action.payload,
      };
    default:
      return storeData;
  }
}
