/*-- Store --*/
import { getUrl } from "./urls";
import {
  SIGN_IN,
  SOCIAL_SIGN_IN,
  RECOVER_PASSWORD,
  VALIDATE_USER_LOGIN,
  VALIDATE_SECURITY_TOKEN_AUTH,
  SIGN_OUT,
  CHANGE_PASSWORD,
  STORE_AUTH_DATA,
  READ_AUTH_USER,
} from "./actionTypes";

/*-- Tools --*/
import { RestDataSource } from "../../shared/webservice/RestDataSource";
import { validateRefreshTokenError } from "../../shared/store/actionCreators";

/*-- Enums --*/
import { AuthResponseEnum } from "../enums/AuthResponseEnum";

/**
 * Realiza la validación del email con el que se autentica
 * @param data
 * @param successCallback
 * @param errorCallback
 */
export const validateUserLogin = (
  data: any,
  successCallback: Function = () => {},
  errorCallback: Function = (msg: string) => {}
) => async (dispatch: Function) => {
  try {
    const response = await new RestDataSource(getUrl(VALIDATE_USER_LOGIN))
      .method("POST")
      .simpleRequest(data);

    const status = response?.status === "true";
    const hasTempPassword =
      !status && response?.detail === AuthResponseEnum.USER_TEMP_PASSWORD_SET;

    if (
      response?.detail === AuthResponseEnum.INVALID_CREDENTIALS ||
      response?.detail === AuthResponseEnum.USER_INACTIVE
    ) {
      return errorCallback("El usuario no existe o se encuentra inactivo");
    }

    if (hasTempPassword) {
      return successCallback({ temporalPassword: true });
    }

    successCallback({ temporalPassword: false });
  } catch (error) {
    errorCallback(error);
  }
};

/**
 * Realiza la validación del token para cambiar la contraseña
 * @param data
 * @param successCallback
 * @param errorCallback
 */
export const validateSecurityTokenToChangePassword = (
  data: any,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    const response = await new RestDataSource(
      getUrl(VALIDATE_SECURITY_TOKEN_AUTH)
    )
      .method("POST")
      .simpleRequest(data);
    if (response?.detail === AuthResponseEnum.VALID_TOKEN) {
      return successCallback({});
    }

    return errorCallback();
  } catch (error) {
    return errorCallback();
  }
};

/**
 * Realiza el envio de un token de seguridad para
 * cambiar la contraseña.
 * @param data
 * @param successCallback
 * @param errorCallback
 */
export const recoverPassword = (
  data: any,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    const response = await new RestDataSource(getUrl(RECOVER_PASSWORD))
      .method("POST")
      .simpleRequest(data);

    if (response?.detail === AuthResponseEnum.TOKEN_SENT) {
      return successCallback();
    }

    return errorCallback("Usuario inactivo o no existe.");
  } catch (error) {
    errorCallback(error);
  }
};

/**
 * Realiza el cambio de contraseña.
 * @param data
 * @param successCallback
 * @param errorCallback
 */
export const changePassword = (
  data: any,
  successCallback: Function = () => {},
  errorCallback: Function = (msg: string) => {}
) => async (dispatch: Function) => {
  let defaultMsg = "No fue posible cambiar la contraseña";
  try {
    const response = await new RestDataSource(getUrl(CHANGE_PASSWORD)).store(
      data
    );

    if (response.detail === AuthResponseEnum.PASSWORD_CHANGED) {
      return successCallback();
    } else {
      return errorCallback(response.msg || defaultMsg);
    }
  } catch (error) {
    console.log("error", error);
    errorCallback(defaultMsg);
  }
};

/**
 * Realiza la authenticación.
 * @param data
 * @param successCallback
 * @param errorCallback
 */
export const signIn = (
  data: any,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    const response = await new RestDataSource(getUrl(SIGN_IN))
      .method("POST")
      .simpleRequest(data);

    if (response.access) {
      localStorage.setItem("clientsAuthV2", JSON.stringify(response));
      successCallback();
    } else {
      return errorCallback();
    }
  } catch (error) {
    validateRefreshTokenError(error);
    errorCallback();
  }
};

/**
 * Realiza la autenticación con google
 * @param data
 */
export const socialSignIn = (
  data: any,
  onSuccess: Function,
  onError: Function
) => async (dispatch: Function) => {
  try {
    const response: any = await new RestDataSource(
      getUrl(SOCIAL_SIGN_IN, [], data)
    )
      .method("GET")
      .simpleRequest(data);

    if (response.access) {
      localStorage.setItem("clientsAuthV2", JSON.stringify(response));
      return onSuccess();
    }

    onError(response);
  } catch (error) {
    onError(error);
  }
};

/**
 * Realiza la consulta de la información para el
 * usuario autenticado.
 */
export const readAuthUser = (
  onSuccess: Function = () => {},
  onError: Function = () => {}
) => async (dispatch: Function) => {
  try {
    const user: any = await new RestDataSource(
      getUrl(READ_AUTH_USER)
    ).getData();
    let userSelected = user.results[0];
    dispatch({ type: SIGN_IN, payload: userSelected });
    onSuccess(userSelected);
  } catch (error) {
    onError(error);
  }
};

/**
 * Desautenticación.
 * @param {object} data
 * @param successCallback
 * @param errorCallback
 */
export const signOut = () => async (dispatch: Function) => {
  localStorage.removeItem("clientsAuthV2");
  dispatch({ type: SIGN_OUT, payload: null });
};

export const storeLoginData = (data: any) => (dispatch: Function) => {
  dispatch({ type: STORE_AUTH_DATA, payload: data });
};
