import React from "react";
import OrderManagementMain from "../containers/OrderManagementMain";

const OrderManagement = (props: any) => {
  return (
    <React.Fragment>
      <OrderManagementMain {...props}/>
    </React.Fragment>
  )
};

export default OrderManagement;