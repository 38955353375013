/*-- Store --*/
import { getUrl } from "./urls";

/*-- Actions Types --*/
import {
  CLEAR_SALES,
  LOADING_SALES,
  READ_SALES,
  READ_SALES_SCROLL_INFINITE,
  MAKE_RESERVE,
  CHANGE_RESERVE,
  CHECK_RESERVE,
  FREE_RESERVE,
  BULK_FREE_RESERVE,
  DELETE_PRODUCT_CART,
  ADD_PRODUCT_CART,
  CHANGE_PRODUCT_CART_QUANTITY,
  SELECT_PRE_PURCHASE,
  LOADING_RESERVATION_STATUS,
  STORE_SALE,
  APPLY_BULK_ACTIONS_ORDERS,
  LOADING_APPLY_BULK_ACTIONS_ORDERS,
  SELECT_CLIENT,
  SELECT_ORDER_TO_DELIVER,
  CLEAR_SHOPPING_CART,
} from "./actionTypes";

/*-- Tools --*/
import { sleep } from "../../shared/utils/utils";
import { RestDataSource } from "../../shared/webservice/RestDataSource";

export const readSales = (
  args: any[],
  kwargs: Object,
  isInfiniteScroll: false,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  dispatch({
    type: LOADING_SALES,
    payload: true,
  });
  if (!isInfiniteScroll) {
    dispatch({ type: CLEAR_SALES });
  }
  await sleep();

  try {
    const dataSource = await new RestDataSource(
      getUrl(READ_SALES, args, kwargs)
    ).getData();

    dispatch({
      type: isInfiniteScroll ? READ_SALES_SCROLL_INFINITE : READ_SALES,
      payload: dataSource,
    });
    dispatch({
      type: LOADING_SALES,
      payload: false,
    });
    successCallback(dataSource);
  } catch (error) {
    dispatch({
      type: LOADING_SALES,
      payload: false,
    });
    errorCallback(error);
  }
};

export const makeReserve = (
  data: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    const response = await new RestDataSource(getUrl(MAKE_RESERVE)).store(data);

    if (response.detail?.reserved) {
      successCallback();
    } else {
      errorCallback("No hay cantidades suficientes disponibles del producto.");
    }
  } catch (error) {
    errorCallback("No hay cantidades suficientes disponibles del producto.");
  }
};

export const updateReserve = (
  data: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    dispatch({
      type: LOADING_RESERVATION_STATUS,
      payload: true,
    });

    const response = await new RestDataSource(getUrl(CHANGE_RESERVE)).store(
      data
    );

    if (response.detail?.reserved) {
      successCallback();
    } else {
      errorCallback("No hay cantidades suficientes disponibles del producto.");
    }
  } catch (error) {
    errorCallback("No hay cantidades suficientes disponibles del producto.");
  } finally {
    dispatch({
      type: LOADING_RESERVATION_STATUS,
      payload: false,
    });
  }
};

export const freeReserve = (
  data: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  dispatch({
    type: LOADING_RESERVATION_STATUS,
    payload: true,
  });

  try {
    const response = await new RestDataSource(getUrl(FREE_RESERVE)).store(data);
    successCallback();
  } catch (error) {
    errorCallback("Hubo un problema al realizar la accion especificada.");
  } finally {
    dispatch({
      type: LOADING_RESERVATION_STATUS,
      payload: false,
    });
  }
};

export const checkReserve = (
  data: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    dispatch({
      type: LOADING_RESERVATION_STATUS,
      payload: true,
    });

    const response = await new RestDataSource(getUrl(CHECK_RESERVE)).store(
      data
    );

    if (response?.detail) {
      successCallback();
    } else {
      errorCallback(
        "Los productos seleccionados no cuentan con unidades disponibles"
      );
    }
  } catch (error) {
    errorCallback(
      "Los productos seleccionados no cuentan con unidades disponibles"
    );
  } finally {
    dispatch({
      type: LOADING_RESERVATION_STATUS,
      payload: false,
    });
  }
};

export const bulkfreeReserve = (
  data: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    const response = await new RestDataSource(getUrl(BULK_FREE_RESERVE)).store(
      data
    );

    successCallback();
  } catch (error) {
    errorCallback("Hubo un problema al realizar la accion especificada.");
  }
};

export const changeProductQuantity = (data: any) => (dispatch: Function) => {
  if (data.quantity <= 0) {
    dispatch({ type: DELETE_PRODUCT_CART, payload: data });
  } else if (data.product) {
    dispatch({ type: ADD_PRODUCT_CART, payload: data });
  } else {
    dispatch({ type: CHANGE_PRODUCT_CART_QUANTITY, payload: data });
  }
};

/**
 * Guarda los valores selecionados en el componente PrePurchaseForm
 * @param values
 */
export const selectPrePurchase = (values: any) => {
  return {
    type: SELECT_PRE_PURCHASE,
    payload: values,
  };
};

export const storeSale = (
  data: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    dispatch({
      type: LOADING_RESERVATION_STATUS,
      payload: true,
    });

    const response = await new RestDataSource(getUrl(STORE_SALE)).store(data);
    if (!response.detail && response.ii_client) {
      dispatch({
        type: CLEAR_SHOPPING_CART,
      });

      return successCallback();
    } else {
      let message = "";

      switch (response.detail) {
        case "RESERVATION_TIMEOUT":
          message =
            "La reserva de los productos seleccionados se encuentra agotada y no es posible comprarlos en estos momentos";
          break;
        case "NOT_FUNDS":
          message = "No tiene fondos suficientes en la billetera";
          break;
        case "NOT_SUBACCOUNT_ASSIGNED":
          message = "No tiene saldo en la billetera indicada";
          break;
        case "PRICES_CHANGED_FOR_PRODUCTS":
          message =
            "Los precios de los productos cambiaron mientras compraba y la venta fue anulada";
          break;
        default:
          message = "No fue posible realizar la venta";
      }

      return errorCallback(message);
    }
  } catch (error) {
    errorCallback("Hubo un problema al realizar la venta.");
  } finally {
    dispatch({
      type: LOADING_RESERVATION_STATUS,
      payload: false,
    });
  }
};

/**
 * Permite limpiar el carrito de compras
 */
export const clearShoppingCar = () => {
  return {
    type: CLEAR_SHOPPING_CART,
  };
};

/**
 * Seleccionar cliente para entregar pedidos
 * @param clientId
 */
export const selectCustomerToDeliver = (client: any) => {
  return {
    type: SELECT_CLIENT,
    payload: client,
  };
};

/**
 * Seleccionar venta para entregar al cliente
 * @param clientId
 */
export const selectOrderToDeliver = (sale: any) => {
  return {
    type: SELECT_ORDER_TO_DELIVER,
    payload: sale,
  };
};

export const applyActionsOrders = (
  data: any,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  dispatch({
    type: LOADING_APPLY_BULK_ACTIONS_ORDERS,
    payload: true,
  });
  await sleep();

  try {
    const response = await new RestDataSource(
      getUrl(APPLY_BULK_ACTIONS_ORDERS)
    ).store(data);

    dispatch({
      type: LOADING_APPLY_BULK_ACTIONS_ORDERS,
      payload: false,
    });

    if (response.hasOwnProperty("detail")) {
      errorCallback(response.detail);
    } else {
      successCallback();
    }
  } catch (error) {
    dispatch({
      type: LOADING_APPLY_BULK_ACTIONS_ORDERS,
      payload: false,
    });
    errorCallback();
  }
};

/**
 * Limpia una variable del store.
 */
export const clearSales = () => {
  return { type: CLEAR_SALES };
};
