import { CLEAR_MESSAGE, STORE_MESSAGE } from "./actionTypes";

const INITIAL_STATE: any = {
  show_message: {
    text: "",
    type: "",
    time: 0,
  },
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case STORE_MESSAGE:
      return {
        ...state,
        show_message: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        show_message: {
          text: "",
          type: "",
          time: 0,
        },
      };
    default:
      return state;
  }
}
