import React from "react";
import { Alert } from "antd";
import { connect } from "react-redux";
import { alertFeedback } from "../store/actionCreators";

interface Props {
  alertFeedbackData: any;
  alertFeedback: any;
  timeShow?: number;
}

/**
 * Componente de feedbacks.
 */
class AlertFeedback extends React.Component<Props, any> {
  private handleClose = (event: any) => {
    this.props.alertFeedback("", "", "", false);
  };

  /**
   * Construye el componente de alerta a mostrar.
   */
  private displayAlert = () => {
    if (this.props.alertFeedbackData && this.props.alertFeedbackData.show) {
      let { timeShow, alertFeedbackData } = this.props;

      setTimeout(
        () => {
          this.handleClose(undefined);
        },
        timeShow ? timeShow : 8000
      );

      const type = alertFeedbackData.type.toLowerCase();
      return (
        <div>
          <Alert
            message={alertFeedbackData.message}
            description={alertFeedbackData.description}
            type={type}
            closeText="X"
            onClose={this.handleClose}
            showIcon
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  render = () => this.displayAlert();
}

const mapStateToProps = (storeData: any) => ({
  alertFeedbackData: storeData.shared.alertFeedback
});

const mapDispatchToProps = {
  alertFeedback: alertFeedback
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertFeedback);
