import React from "react";
import { Route, Switch } from "react-router-dom";

/*-- Components --*/
import AuthenticatedRoute from "./AuthenticatedRoute";
import Login from "../modules/auth/entries/Login";
import SetPassword from "../modules/auth/entries/SetPassword";
import QrScan from "../modules/home/entries/QrScan";
import OrderSelection from "../modules/sales/entries/OrderSelection";
import ConfirmDelivery from "../modules/sales/entries/ConfirmDelivery";
import ReadyForDispatch from "../modules/sales/entries/ReadyForDispatch";

/**
 * Rutas del despachador
 * @constructor
 */
const DispatcherRoutes = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route path="/change-password" component={SetPassword} />

    <AuthenticatedRoute
      exact
      path="/sales/order-selection"
      component={OrderSelection}
    />
    <AuthenticatedRoute
      exact
      path="/sales/confirm-delivery"
      component={ConfirmDelivery}
    />
    <AuthenticatedRoute
      exact
      path="/sales/for-dispatch"
      component={ReadyForDispatch}
    />

    <AuthenticatedRoute path="/" component={QrScan} />
    <AuthenticatedRoute path="/sales/qr-scan" component={QrScan} />
    {/*<Route path="*" component={ NoMatch }/>*/}
  </Switch>
);

export default DispatcherRoutes;
