import React from "react";
import contrast from "contrast";

interface Props {
  borderRadius?: string;
  src?: string;
  srcset?: string;
  name: string;
  color?: string;
  colors?: string[];
  size?: "small" | "medium" | "big" | "very-big";
  style?: Object;
  onClick?: Function;
  className?: string;
}

const defaultColors = [
  "#2ecc71", // emerald
  "#3498db", // peter river
  "#8e44ad", // wisteria
  "#e67e22", // carrot
  "#e74c3c", // alizarin
  "#1abc9c", // turquoise
  "#2c3e50", // midnight blue
];

/**
 * Obtiene las iniciales del nombre
 * @param name
 */
const _getInitials = (name: string) => {
  return name
    .split(" ")
    .map((item) => item.charAt(0))
    .slice(0, 2)
    .join("")
    .toUpperCase();
};

const sumChars = (str: string) => {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }

  return sum;
};

const CustomAvatar = (props: Props) => {
  let {
    borderRadius = "100%",
    src,
    srcset,
    name,
    color,
    colors = defaultColors,
    size = "very-big",
    style = {},
    className,
  } = props;
  const initialsName = _getInitials(name);
  let imageStyle: any = { borderRadius };
  let content,
    classes = ["user-avatar", size];
  let innerStyle: any = { textAlign: "center", borderRadius };
  const handleEvent = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  if (className && typeof className != undefined) {
    classes.push(className);
  }

  if (src || srcset) {
    content = (
      <img
        className={`${classes.join(" ")} user-avatar--img`}
        style={imageStyle}
        src={src}
        srcSet={srcset}
        alt={name}
      />
    );
  } else {
    let background;

    if (color) {
      background = color;
    } else {
      // pick a deterministic color from the list
      let i = sumChars(name) % colors.length;
      background = colors[i];
    }

    innerStyle.backgroundColor = background;
    classes.push(`user-avatar--${contrast(background)}`);
    content = (
      <div
        className={`${classes.join(" ")} user-avatar--inner`}
        style={innerStyle}
      >
        {initialsName}
      </div>
    );
  }

  return (
    <div
      aria-label={name}
      className={classes.join(" ")}
      style={style}
      onClick={handleEvent}
    >
      {content}
    </div>
  );
};

export default CustomAvatar;
