import React from "react";
import AlertFeedback from "../../shared/components/AlertFeedback";
import LoginMain from "../containers/LoginMain";

const Login = (props: any) => {
  return (
    <React.Fragment>
      <AlertFeedback />
      <LoginMain {...props} />
    </React.Fragment>
  );
};

export default Login;
