import React from "react";
import AlertFeedback from "../../shared/components/AlertFeedback";
import SetPasswordMain from "../containers/SetPasswordMain";

const SetPassword = (props: any) => {
  return (
    <React.Fragment>
      <AlertFeedback />
      <SetPasswordMain {...props} />
    </React.Fragment>
  );
};

export default SetPassword;
