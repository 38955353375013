import {
  READ_WALLETS,
  START_LOAD_READ_WALLETS,
  END_LOADING_READ_WALLETS,
  READ_SERVICE_CONFIGURATIONS,
  READ_SERVICE_CENTERS,
  READ_CASH_REGISTERS,
  LOADING_CASH_REGISTERS,
  LOADING_SERVICE_CENTERS,
  LOADING_SERVICE_CONFIGURATIONS,
  READ_SERVICE_CENTERS_PAGINATE,
  READ_PAYGO_ACCOUNT_WALLETS,
  LOADING_PAYGO_ACCOUNT_WALLETS,
} from "./actionTypes";

const INITIAL_STATE = {
  wallets: [],
  loading_wallets: false,
  service_configurations: [],
  loading_service_configurations: false,
  service_centers: [],
  service_centers_paginate: [],
  loading_service_centers: false,
  cash_registers: [],
  loading_cash_registers: false,
  paygo_account_wallets: [],
  loading_paygo_account_wallets: false,

  //Pagination
  service_centers_total_rows: 0, // Cantidad total de registros
  service_centers_current_page: 1, // Pagina mostrada
  service_centers_page_size: 1, // Cantidad de registros por pagina
};

export default function (storeData: any = INITIAL_STATE, action: any) {
  switch (action.type) {
    case START_LOAD_READ_WALLETS:
      return {
        ...storeData,
        loading_wallets: true,
      };
    case END_LOADING_READ_WALLETS:
      return {
        ...storeData,
        loading_wallets: false,
      };
    case LOADING_SERVICE_CONFIGURATIONS:
      return {
        ...storeData,
        loading_service_configurations: action.payload,
      };
    case LOADING_SERVICE_CENTERS:
      return {
        ...storeData,
        loading_service_centers: action.payload,
      };
    case LOADING_CASH_REGISTERS:
      return {
        ...storeData,
        loading_cash_registers: action.payload,
      };
    case READ_WALLETS:
      return {
        ...storeData,
        wallets: action.payload,
      };
    case READ_SERVICE_CONFIGURATIONS:
      return {
        ...storeData,
        service_configurations: action.payload,
      };
    case READ_SERVICE_CENTERS:
      return {
        ...storeData,
        service_centers: action.payload.result,
      };
    case READ_SERVICE_CENTERS_PAGINATE:
      return {
        ...storeData,
        service_centers_paginate: action.payload.result,
        service_centers_total_rows: action.payload.total_rows || 0,
        service_centers_page_size: action.payload.page || 0,
      };
    case READ_CASH_REGISTERS:
      return {
        ...storeData,
        cash_registers: action.payload,
      };
    case READ_PAYGO_ACCOUNT_WALLETS:
      return {
        ...storeData,
        paygo_account_wallets: action.payload,
      };
    case LOADING_PAYGO_ACCOUNT_WALLETS:
      return {
        ...storeData,
        loading_paygo_account_wallets: action.payload,
      };
    default:
      return storeData;
  }
}
