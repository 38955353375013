import React from "react";

/*-- Ant Design --*/
import { List, Skeleton } from "antd";
import Text from "antd/lib/typography/Text";

/*-- Tools --*/
import { currencyFormatter } from "../../shared/utils/value-formatters";

interface Props {
  loading?: boolean;
  balances: any[];
}

const CustomerBalances = (props: Props) => {
  const { balances, loading } = props;

  return (
    <Skeleton loading={(balances && balances.length === 0) || loading} active>
      <List
        loading={loading}
        size="small"
        dataSource={balances}
        renderItem={(item: any) => {
          let hasMoney = Number(item.value) > 0;
          let color = hasMoney ? "#3BC62E" : "#F0123E";

          return (
            <List.Item
              style={{ color: "#000000" }}
              extra={
                <Text style={{ color }} strong>
                  {currencyFormatter(item.value)}
                </Text>
              }
            >
              {item.name}
            </List.Item>
          );
        }}
      />
    </Skeleton>
  );
};

export default CustomerBalances;
