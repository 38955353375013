import React from "react";

import Text from "antd/lib/typography/Text";
import { Divider, Row } from "antd";
import moment from "moment";

interface Props {
  messages: any;
  onMessageRead: any;
}

const MessagesList = (props: Props) => {
  return (
    <>
      <Row style={{ background: "#F8F8F8", padding: "10px 20px 20px 3rem" }}>
        <Text strong style={{ fontSize: 28 }}>
          Notificaciones
        </Text>
      </Row>
      {!props.messages || props.messages.length === 0 ? (
        <Row justify="center" align="middle" className="mt-5">
          <Text strong>No tiene mensajes</Text>
        </Row>
      ) : (
        <></>
      )}

      {props.messages?.results?.map((message: any) => (
        <div
          style={{ padding: "0.5rem 3rem 0 3rem" }}
          key={message.message_id}
          onClick={() => {
            props.onMessageRead(message);
          }}
        >
          <Row key={message.message_id} justify="space-between">
            <Text strong style={{ fontSize: 18 }}>
              {message.title}
            </Text>
            <Text>
              {moment(message.dispatch_datetime).format("MMMM DD h:mm a")}
            </Text>
          </Row>
          <Row style={{ color: "#888888", fontSize: 15 }}>
            {message.text.slice(0, 50) +
              (message.text.length > 50 ? "..." : "")}
          </Row>
          <Divider style={{ marginTop: 15, marginBottom: 10 }} />
        </div>
      ))}
    </>
  );
};

export default MessagesList;
