import React, { ComponentElement } from "react";

/*-- Css --*/
import "./MenuBarItem.css";

interface Props {
  title: string;
  icon: ComponentElement<any, any>;
  selectedIcon?: ComponentElement<any, any>;
  selected: boolean;
  onClick: Function;
}

export const MenuBarItem = (props: Props) => {
  const { selected, onClick } = props;
  let selectedClass = selected ? "menu-bar-item-selected" : "";

  return (
    <div className="menu-bar-item m-1" onClick={() => onClick()}>
      <div className={"p-2 " + selectedClass}>
        <div className="menu-bar-item-icon">
          {props.selected ? props.selectedIcon : props.icon}
        </div>

        <div className="menu-bar-item-title">{props.title}</div>
      </div>
    </div>
  );
};
