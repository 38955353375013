import React from "react";
import OrderSelectionMain from "../containers/OrderSelectionMain";

const OrderSelection = (props: any) => {
  return (
    <React.Fragment>
      <OrderSelectionMain {...props} />
    </React.Fragment>
  );
};

export default OrderSelection;
