import moment from "moment";

/**
 * Reglas de validación de los campos en el formulario.
 */

export const isAlphanumericSpacesCharactersType = {
  pattern: "^[0-9A-Za-zÁÉÍÓÚÑáéíóúÀÈÌÒÙàèìòùñ/+*_#()\\s.'-]*$",
  message: "¡Formato erróneo, debe ser alfanumérico y puede contener espacios!",
};

export const isAlphanumericAndSpacesType = {
  pattern: "^[0-9A-Za-zÁÉÍÓÚÑáéíóúñÀÈÌÒÙàèìòù\\s]*$",
  message: "¡Formato erróneo, debe ser alfanumérico y puede contener espacios!",
};

export const isAlphanumericWithoutSpacesType = {
  pattern: "^[0-9A-Za-zÁÉÍÓÚÑáéíóúñÀÈÌÒÙàèìòù]*$",
  message: "¡Formato erróneo, debe ser alfanumérico!",
};

export const isAlphabeticalAndSpacesType = {
  pattern: "^[A-Za-zÁÉÍÓÚÑáéíóúñÀÈÌÒÙàèìòù\\s]*$",
  message:
    "¡Formato erróneo, debe contener valores alfabéticos y puede contener espacios!",
};

export const isCodeType = {
  pattern: "^[0-9A-Za-z]*$",
  message: "¡Formato erróneo, debe ser alfanumérico y sin espacios!",
};

export const isCodeWithCharactersType = {
  pattern: "^[0-9A-Za-z_+-]*$",
  message: "¡Formato erróneo, debe ser alfanumérico y sin espacios!",
};

export const isNumericCodeNotStartZeroType = {
  pattern: "^[1-9][0-9]*$",
  message: "¡Debe ser un valor numérico y no puede comenzar con cero!",
};

export const isNumberType = {
  type: "number",
  message: "¡Debe ser un valor numérico!",
};

export const isBooleanType = {
  type: "boolean",
  message: "¡Debe ser un valor verdadero o falso!",
};

export const isArrayType = {
  type: "array",
  message: "¡Debe ser un array de elementos!",
};

export const isRequired = {
  required: true,
  message: "¡Este campo es requerido!",
};

export const isPassword: any = {
  type: "string",
  max: 25,
  min: 10,
  message: "¡Debe tener entre 10 y 25 caracteres!",
};

export const isRequiredTrue = {
  required: true,
  message: "¡Este campo es requerido!",
  type: "boolean",
  transform: (value: boolean) => value || undefined,
};

export const isNumericCodeCanStartZeroType = {
  pattern: "^[0-9]+$",
  message: "¡Debe ser un valor numérico y puede comenzar con cero!",
};

export const isDateFuture = {
  validator: (_: any, value: moment.Moment) => {
    return value.isSameOrAfter(moment());
  },
  message: "¡Debe ser una fecha en el futuro!",
};
