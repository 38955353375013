export const VALIDATE_USER_LOGIN = "VALIDATE_USER_LOGIN";
export const VALIDATE_SECURITY_TOKEN_AUTH = "VALIDATE_SECURITY_TOKEN_AUTH";
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

/* Authentication */
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const STORE_AUTH_DATA = "STORE_AUTH_DATA";

/* Socials */
export const SOCIAL_SIGN_IN = "SOCIAL_SIGN_IN";

/* User */
export const READ_AUTH_USER = "READ_AUTH_USER";
