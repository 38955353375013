import {
  ALERT_FEEDBACK,
  SELECT_PAYGO_ACCOUNT,
  SELECT_SERVICE_CONFIGURATION,
} from "./actionTypes";
import { signOut } from "../../auth/store/actionCreators";

/*-- Tools --*/
import { RefreshTokenError } from "../utils/exceptions";

/**
 * Muestra o oculta comentarios de alerta.
 * @param {string} type - success, info, warning, error
 * @param {string} message
 * @param {string} description
 * @param {boolean} show - true, false
 */
export const alertFeedback = (
  type: string,
  message: string,
  description: string,
  show: boolean = true
) => {
  return {
    type: ALERT_FEEDBACK,
    payload: { show, message, description, type },
  };
};

/**
 * Válida si el error fue por el refresh token, y en ese
 * caso desautentica al usuario.
 * @param error
 */
export const validateRefreshTokenError = (error: any) => {
  if (error instanceof RefreshTokenError) {
    signOut();
  }
};

/**
 * Actualiza el id de la cuenta paygo seleccionada.
 * @param paygoAccountId
 */
export const selectPayGOAccount = (paygoAccountId: any) => (
  dispatch: Function
) => {
  dispatch({
    type: SELECT_PAYGO_ACCOUNT,
    payload: paygoAccountId,
  });
};

/**
 * Actualiza el id de la configuración de servicio seleccionada.
 * @param id
 */
export const selectServiceConfigurationId = (id: any) => (
  dispatch: Function
) => {
  dispatch({
    type: SELECT_SERVICE_CONFIGURATION,
    payload: id,
  });
};
