import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderConfirmation from "../components/OrderConfirmation";

import { Popover, Button, Row, Spin } from "antd";
import { connect } from "react-redux";

interface Props {
  style?: any;
  products?: any;
  preOrderBuy?: any;
  handleQuantities: any;
  loadingReservations?: boolean;
  handleSendSale?: any;
  accountWallets?: any;
  wallets?: any;
}

const CartDetail = (props: Props) => {
  const horizontalMode = window.innerHeight < window.innerWidth;
  const style: any = { maxWidth: 310, paddingRight: 25, overflow: "scroll" };

  if (horizontalMode) {
    style["maxHeight"] = "250px";
  } else {
    style["maxHeight"] = "550px";
  }

  return (
    <div style={style}>
      <Row justify="center" style={{ fontSize: "1.2rem" }}>
        <h3>Mi carrito de compras</h3>
      </Row>
      {props.products.length > 0 ? (
        <Spin spinning={props.loadingReservations}>
          <Row justify="start">
            <OrderConfirmation
              periodName={props.preOrderBuy.service_period.label}
              originServiceCenterName={props.preOrderBuy.delivery_node.label}
              deliveryServiceCenterName={props.preOrderBuy.receiving_node.label}
              products={props.products}
              inputIn={true}
              handleQuantities={props.handleQuantities}
              wallets={props.accountWallets}
              systemWallets={props.wallets}
            />
          </Row>
        </Spin>
      ) : (
        <div>No tiene productos agregados al carrito</div>
      )}

      <Row justify="center" className="mt-3">
        <Button
          className="btn-success"
          shape="round"
          size="large"
          onClick={props.handleSendSale}
          disabled={!props.products || props.products.length === 0}
        >
          ENVIAR PEDIDO
        </Button>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeData: any) => ({
  preOrderBuy: storeData.sales.pre_order_buy,
  products: storeData.sales.shopping_cart,
  loadingReservations: storeData.sales.is_loading_reservation_status,
  wallets: storeData.business.wallets,
  accountWallets: storeData.business.paygo_account_wallets,
});

const ConnectedCartDetail = connect(mapStateToProps, null)(CartDetail);

const ShoppingCart = (props: Props) => {
  return (
    <Popover
      placement="bottomRight"
      content={
        <ConnectedCartDetail
          handleQuantities={props.handleQuantities}
          handleSendSale={props.handleSendSale}
        />
      }
      trigger="click"
      overlayStyle={{ zIndex: 50 }}
    >
      <FontAwesomeIcon icon={["fas", "shopping-cart"]} size="2x" />
    </Popover>
  );
};

export default ShoppingCart;
