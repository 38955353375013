import React from "react";

/*-- Ant Design --*/
import { Row, Col } from "antd";

/*-- Tools --*/
import QRCode from "qrcode.react";

/*-- Components --*/
import CustomAvatar from "../../shared/components/Avatar";

interface Props {
  loading?: boolean;
  userName: string;
  userPhoto?: string;
  qrValue: string;
}

const CustomerInformation = (props: Props) => {
  const { userName, userPhoto, qrValue } = props;

  return (
    <Row gutter={[0, 16]}>
      <Col xs={12} sm={12} md={12} lg={10}>
        <CustomAvatar className="center" src={userPhoto} name={userName} />
      </Col>

      <Col xs={12} sm={12} md={12} lg={10}>
        <QRCode className="center" value={qrValue} size={112} />
      </Col>
    </Row>
  );
};

export default CustomerInformation;
