import {
  SIGN_IN,
  SIGN_OUT,
  RECOVER_PASSWORD,
  STORE_AUTH_DATA,
  READ_AUTH_USER,
} from "./actionTypes";

const INITIAL_STATE: any = {
  is_logged_in: false,
  temp_token: "",
  user: null,
};

export default function (state = INITIAL_STATE, action: any) {
  let { payload } = action;

  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        is_logged_in: true,
        user: action.payload,
      };
    }
    case SIGN_OUT: {
      return {
        is_logged_in: false,
        user: null,
      };
    }
    case READ_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case RECOVER_PASSWORD: {
      return {
        ...state,
        temp_token: action.payload,
      };
    }
    case STORE_AUTH_DATA: {
      return {
        ...state,
        data: payload,
      };
    }
    default:
      return state;
  }
}
