import { generateParamsDynamicUrl } from "../../shared/store/urls";
import {
  READ_SERVICE_PERIOD,
  READ_SERVICE_PERIODS,
  READ_DATE_RULES,
  STORE_SERVICE_PERIOD,
  UPDATE_SERVICE_PERIOD,
  READ_TIME_RULES,
} from "./actionTypes";

const base = process.env.REACT_APP_API_URL;

/**
 * Obtiene la url al end point de la acción a realizar.
 *
 * La URL debe terminar en una barra inclinada. Django no puede redirigir a
 * la barra diagonal URL mientras mantiene los datos POST.
 *
 * @param {string} actionType - Tipo de acción.
 * @param {Array} args - Array de parametros a mapear en la URL.
 * @param kwargs parametros adicionales objecto de typo {key: valor, ...}
 */
export function getUrl(
  actionType: any,
  args: any[] = [],
  kwargs: object = {}
): string {
  const paramsUrl = generateParamsDynamicUrl(args, kwargs);
  switch (actionType) {
    case STORE_SERVICE_PERIOD:
    case READ_SERVICE_PERIODS:
    case UPDATE_SERVICE_PERIOD:
    case READ_SERVICE_PERIOD:
      return `${base}/service-periods${paramsUrl}`;
    case READ_DATE_RULES:
      return `${base}/service-periods/date-rules${paramsUrl}`;
    case READ_TIME_RULES:
      return `${base}/service-periods/reservation-time-rules/`;
    default:
      return "";
  }
}
