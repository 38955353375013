/*-- Store --*/
import { getUrl } from "./urls";

/*-- Actions Types --*/
import {
  CLEAR_USERS,
  LOADING_USERS,
  READ_USERS,
  READ_USER,
  DELETE_ACCOUNT,
  LOADING_DELETE_ACCOUNT,
} from "./actionTypes";

/*-- Tools --*/
import { RestDataSource } from "../../shared/webservice/RestDataSource";

/**
 * Consulta los usuarios de clientes
 * @param args
 * @param kwargs
 * @param successCallback
 * @param errorCallback
 */
export const readUsers = (
  args: any[],
  kwargs: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    dispatch({ type: LOADING_USERS, payload: true });
    const dataSource = await new RestDataSource(
      getUrl(READ_USERS, args, kwargs)
    ).getData();

    let data = dataSource.results;

    dispatch({
      type: READ_USERS,
      payload: data,
    });
    dispatch({ type: LOADING_USERS, payload: false });

    successCallback(data);
  } catch (error) {
    errorCallback(error);
    dispatch({ type: LOADING_USERS, payload: false });
  }
};

/**
 * Consulta el usuario por id
 * @param args
 * @param kwargs
 * @param successCallback
 * @param errorCallback
 */
export const readUser = (
  args: any[],
  kwargs: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    const dataSource = await new RestDataSource(
      getUrl(READ_USERS, args, kwargs)
    ).getData();

    dispatch({
      type: READ_USER,
      payload: dataSource,
    });

    successCallback(dataSource);
  } catch (error) {
    errorCallback(error);
  }
};

/**
 * Limpia los clientes
 */
export const clearUser = () => (dispatch: Function) => {
  dispatch({
    type: CLEAR_USERS,
  });
};

/**
 * Realiza la eliminacion de la cuenta.
 * @param data
 * @param successCallback
 * @param errorCallback
 */
export const deleteAccount = (
  data: any,
  successCallback: Function = () => {},
  errorCallback: Function = (msg: string) => {}
) => async (dispatch: Function) => {
  let defaultMsg = "No fue posible eliminar la cuenta";

  try {
    dispatch({ type: LOADING_DELETE_ACCOUNT, payload: true });
    const response = await new RestDataSource(getUrl(DELETE_ACCOUNT)).delete(
      {}
    );

    dispatch({ type: LOADING_DELETE_ACCOUNT, payload: false });

    if (response.status === "false") {
      return errorCallback(response.msg || defaultMsg);
    }

    return successCallback();
  } catch (error) {
    dispatch({ type: LOADING_DELETE_ACCOUNT, payload: false });
    errorCallback(defaultMsg);
  }
};
