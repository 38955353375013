import {
  READ_TAX_CONFIGURATIONS,
  LOADING_TAX_CONFIGURATIONS,
  READ_TAX_RATES,
  LOADING_TAX_RATES,
} from "./actionTypes";
import { RestDataSource } from "../../shared/webservice/RestDataSource";
import { getUrl } from "./urls";
import { sleep } from "../../shared/utils/utils";

/**
 * Obtiene todas las configuraciones de impuestos.
 */
export const readTaxConfigurations = () => async (dispatch: Function) => {
  try {
    const dataSource = new RestDataSource(getUrl(READ_TAX_CONFIGURATIONS));

    // Mostrar indicador de carga.
    dispatch({ type: LOADING_TAX_CONFIGURATIONS, payload: true });
    await sleep();

    // Realizar solicitud.
    const response = await dataSource.getData();
    dispatch({
      type: READ_TAX_CONFIGURATIONS,
      payload: response.results,
    });
    dispatch({ type: LOADING_TAX_CONFIGURATIONS, payload: false });
  } catch (error) {
    dispatch({ type: LOADING_TAX_CONFIGURATIONS, payload: false });
  }
};

/**
 * Obtiene todos las rates de impuestos.
 */
export const readTaxRates = () => async (dispatch: Function) => {
  try {
    const dataSource = new RestDataSource(getUrl(READ_TAX_RATES));

    // Mostrar indicador de carga.
    dispatch({ type: LOADING_TAX_RATES, payload: true });
    await sleep();

    // Realizar solicitud.
    const response = await dataSource.getData();
    dispatch({
      type: READ_TAX_RATES,
      payload: response.results,
    });
    dispatch({ type: LOADING_TAX_RATES, payload: false });
  } catch (error) {
    dispatch({ type: LOADING_TAX_RATES, payload: false });
  }
};
