import React, { forwardRef } from "react";

/*-- Ant Design --*/
import { FormInstance } from "antd/lib/form";
import { Form, Input, Button, Row, Col } from "antd";

/*-- Font-Awesome --*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*-- Tools --*/
import { isRequired, isPassword } from "../../shared/validators/generics";

interface Props {
  temporalPass: boolean;
  onSubmit: any;
  recoverPassword?: any;
  history?: any;
}

const PasswordForm = (props: Props, ref: React.Ref<FormInstance>) => {
  const { temporalPass, onSubmit, recoverPassword } = props;
  let recoverPass = null;
  let title =
    "Hemos enviado una contraseña temporal a su correo electronico. Por favor ingresela aqui abajo.";
  let label = "Contraseña temporal";

  if (!temporalPass) {
    title = "Por favor ingrese su contraseña.";
    label = "Contraseña";
    recoverPass = (
      <b onClick={recoverPassword} style={{ color: "#1A60B4" }}>
        Restablecer contraseña
      </b>
    );
  }

  return (
    <Form ref={ref} onFinish={onSubmit}>
      <Row>
        <Col span={18} offset={3} style={{ textAlign: "center" }}>
          <h4 style={{ color: "#ababab" }}>{title}</h4>
        </Col>
      </Row>
      <Row className="mt-4" justify="center">
        <Col sm={18} md={6}>
          <Form.Item name="password" rules={[isRequired, isPassword]}>
            <Input.Password
              prefix={
                <FontAwesomeIcon icon={["fas", "unlock-alt"]} color="#3BC62E" />
              }
              placeholder={label}
              className="one-border-input"
              size="large"
              bordered={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" className="mt-4">
        <Form.Item>
          <Button
            className="btn-success"
            shape="round"
            htmlType="submit"
            size="large"
            style={{ width: 150 }}
          >
            Ingresar
          </Button>
        </Form.Item>
      </Row>

      <Row justify="center" className="mt-1">
        <b
          onClick={() => {
            props.history.push("/home");
          }}
          style={{ color: "#1A60B4" }}
        >
          Regresar
        </b>
      </Row>

      <Row justify="center" className="mt-4">
        {recoverPass}
      </Row>
    </Form>
  );
};

const WrappedLoginForm = forwardRef(PasswordForm);
export default WrappedLoginForm;
