import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/*-- Css --*/
import "./CustomSider.css";

/*-- Ant-design --*/
import { Layout, Menu } from "antd";

/*-- Custom --*/
import { clientMenu, deliveryMenu } from "../../utils/menuItems";

const { Sider } = Layout;

interface Props {
  userRole: any;
}

export function CustomSider(props: Props) {
  const [collapsed, setCollapsed] = useState(false);
  const [tabs, setTabs] = useState([] as any);
  const { userRole } = props;
  const history = useHistory();

  const mapRoutes = (allMenu: any) => {
    return allMenu.map((item: any) => {
      return {
        ...item,
        onClick: () => {
          history.push(item.path);
        },
      };
    });
  };

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  useEffect(
    () => {
      if (userRole === "CLIENT") {
        setTabs(mapRoutes(clientMenu));
      } else if (userRole === "DELIVERY") {
        setTabs(mapRoutes(deliveryMenu));
      }
    },
    // eslint-disable-next-line
    [userRole]
  );

  return (
    <Sider
      className="custom-sider"
      collapsedWidth={65}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <div className="logo" />
      <Menu theme="dark" defaultSelectedKeys={["0"]} mode="inline">
        {tabs.map((tab: any, index: number) => (
          <Menu.Item key={index} onClick={tab.onClick} icon={tab.icon}>
            <span className={collapsed ? "ml-4" : "ml-3"}>{tab.title}</span>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
}
