import React from "react";
import { Route, Switch } from "react-router-dom";

/*-- Components --*/
import AuthenticatedRoute from "./AuthenticatedRoute";
import Login from "../modules/auth/entries/Login";
import SetPassword from "../modules/auth/entries/SetPassword";
import OrderManagement from "../modules/sales/entries/OrderManagement";
import Buy from "../modules/sales/entries/Buy";
import OrderConfirmation from "../modules/sales/entries/OrderConfirmation";
import Home from "../modules/home/entries/Home";
import Messages from "../modules/messaging/entries/Messages";
import Settings from "../modules/security/entries/Settings";

/**
 * Rutas del cliente
 * @constructor
 */
const CustomerRoutes = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route path="/change-password" component={SetPassword} />

    <AuthenticatedRoute
      exact
      path="/messages/:message_id"
      component={Messages}
    />
    <AuthenticatedRoute exact path="/messages" component={Messages} />
    <AuthenticatedRoute exact path="/sales/list" component={OrderManagement} />
    <AuthenticatedRoute exact path="/sales/buy" component={Buy} />
    <AuthenticatedRoute
      exact
      path="/sales/confirmation"
      component={OrderConfirmation}
    />
    <AuthenticatedRoute path="/settings" component={Settings} />
    <AuthenticatedRoute path="/" component={Home} />
    <AuthenticatedRoute path="/home" component={Home} />

    {/*<Route path="*" component={ NoMatch }/>*/}
  </Switch>
);

export default CustomerRoutes;
