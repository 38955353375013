import React from "react";
import AlertFeedback from "../../shared/components/AlertFeedback";
import HomeMain from "../containers/HomeMain";

const Home = (props: any) => {
  return (
    <React.Fragment>
      <AlertFeedback />
      <HomeMain {...props} />
    </React.Fragment>
  );
};

export default Home;
