export const READ_SALES = "READ_SALES";
export const STORE_SALE = "STORE_SALE";
export const CLEAR_SALES = "CLEAR_SALES";
export const READ_SALES_SCROLL_INFINITE = "READ_SALES_SCROLL_INFINITE";
export const LOADING_SALES = "LOADING_SALES";

//Para las reservas de inventario
export const MAKE_RESERVE = "MAKE_RESERVE";
export const CHANGE_RESERVE = "CHANGE_RESERVE";
export const CHECK_RESERVE = "CHECK_RESERVE";
export const FREE_RESERVE = "FREE_RESERVE";
export const BULK_FREE_RESERVE = "BULK_FREE_RESERVE";

//Carro de compras
export const DELETE_PRODUCT_CART = "DELETE_PRODUCT_CART";
export const ADD_PRODUCT_CART = "ADD_PRODUCT_CART";
export const CHANGE_PRODUCT_CART_QUANTITY = "CHANGE_PRODUCT_CART_QUANTITY";
export const SELECT_PRE_PURCHASE = "SELECT_PRE_PURCHASE";
export const LOADING_RESERVATION_STATUS = "LOADING_RESERVATION_STATUS";
export const APPLY_BULK_ACTIONS_ORDERS = "APPLY_BULK_ACTIONS_ORDERS";
export const LOADING_APPLY_BULK_ACTIONS_ORDERS =
  "LOADING_APPLY_BULK_ACTIONS_ORDERS";
export const CLEAR_SHOPPING_CART = "CLEAR_SHOPPING_CART";

/* Cliente */
export const SELECT_CLIENT = "SELECT_CLIENT";

/*-- Venta seleccionada para entregar al cliente --*/
export const SELECT_ORDER_TO_DELIVER = "SELECT_SALE_TO_DELIVER";
