import React from "react";

/*-- Ant Design --*/
import Text from "antd/lib/typography/Text";
import { Row, Col, Button } from "antd";

/*-- FontAwesome --*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  min?: number;
  max?: number;
  onChange: any;
  value?: any;
}

const InputIn = (props: Props) => {
  const { min = 1, max, onChange, value = 1 } = props;

  const add = () => {
    if (!max || (max && max !== undefined && value <= max)) {
      let newValue = value + 1;
      onChange(newValue);
    }
  };

  const substract = () => {
    if (value > min) {
      let newValue = value - 1;
      onChange(newValue);
    }
  };

  let disabledClass = "btn-custom-secondary";
  let enabledClass = "btn-success";
  let substractBtnColorClass = min < value ? enabledClass : disabledClass;
  let addBtnColorClass = enabledClass;

  // eslint-disable-next-line
  if (max && max !== undefined && max == value) {
    addBtnColorClass = disabledClass;
  }

  return (
    <Row>
      <Col xs={8}>
        <Button
          className={substractBtnColorClass}
          style={{ background: "#c4ccc8", border: "none" }}
          type="primary"
          shape="circle"
          size="small"
          icon={<FontAwesomeIcon icon={["fas", "minus"]} color="#FFF" />}
          onClick={substract}
        />
      </Col>

      <Col xs={8} className="text-center">
        <Text strong>{value}</Text>
      </Col>

      <Col xs={8} className="text-right">
        <Button
          className={addBtnColorClass}
          type="primary"
          shape="circle"
          size="small"
          icon={<FontAwesomeIcon icon={["fas", "plus"]} color="#FFF" />}
          onClick={add}
        />
      </Col>
    </Row>
  );
};

export default InputIn;
