import axios from "axios";

/*-- Store --*/
import { signOut } from "../../auth/store/actionCreators";
import { getUrl } from "../../auth/store/urls";
import { REFRESH_TOKEN } from "../../auth/store/actionTypes";

export const setResponseInterceptor = (store: any) => {
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalReq = error.config;

      const hasInvalidToken =
        error.response.status === 401 &&
        error.response.data?.code === "token_not_valid";

      if (hasInvalidToken) {
        try {
          let oauthInfo = JSON.parse(
            localStorage.getItem("clientsAuthV2") || "{}"
          );
          let data: any = { refresh: oauthInfo.refresh };

          //Refresca el token
          let response = await fetch(getUrl(REFRESH_TOKEN), {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          }).then((res) => res.json());

          if (response.code === "token_not_valid") {
            store.dispatch(signOut());
            return;
          }

          let newToken = response.access;
          oauthInfo.access = newToken;
          localStorage.setItem("clientsAuthV2", JSON.stringify(oauthInfo));
          originalReq.headers["Authorization"] = `Secret ${newToken}`;

          return axios(originalReq);
        } catch (error) {
          store.dispatch(signOut());
        }
      }

      return Promise.reject(error);
    }
  );
};
