import React from "react";
import { connect } from "react-redux";

/*-- Enums --*/
import { AuthRole } from "../modules/auth/enums/AuthRole";

/*-- Routes --*/
import DispatcherRoutes from "./DispatcherRoutes";
import CustomerRoutes from "./CustomerRoutes";

const Routes = (props: any) => {
  const { tempRole } = props;

  if (tempRole === AuthRole.DELIVERY) {
    return <DispatcherRoutes />;
  }

  return <CustomerRoutes />;
};

const mapStateToProps = (storeData: any) => ({
  tempRole: storeData.auth.user?.temp_role,
});

export default connect(mapStateToProps, null)(Routes);
