import { LOAD_MESSAGES } from "./actionTypes";

const INITIAL_STATE: any = {
  messages: [],
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case LOAD_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    default:
      return state;
  }
}
