import React from "react";
import MessagesMain from "../containers/MessagesMain";

const Messages = (props: any) => {
  return (
    <React.Fragment>
      <MessagesMain {...props} />
    </React.Fragment>
  );
};

export default Messages;
