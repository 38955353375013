import React from "react";
import ReadyForDispatchMain from "../containers/ReadyForDispatchMain";

const ReadyForDispatch = (props: any) => {
  return (
    <React.Fragment>
      <ReadyForDispatchMain {...props} />
    </React.Fragment>
  );
};

export default ReadyForDispatch;
