import { generateParamsDynamicUrl } from "../../shared/store/urls";
import {
  READ_SALES,
  MAKE_RESERVE,
  CHANGE_RESERVE,
  CHECK_RESERVE,
  FREE_RESERVE,
  BULK_FREE_RESERVE,
  STORE_SALE,
  APPLY_BULK_ACTIONS_ORDERS,
} from "./actionTypes";

const base = process.env.REACT_APP_API_URL;

/**
 * Obtiene la url al end point de la acción a realizar.
 *
 * La URL debe terminar en una barra inclinada. Django no puede redirigir a
 * la barra diagonal URL mientras mantiene los datos POST.
 *
 * @param {string} actionType - Tipo de acción.
 * @param {Array} args - Array de parametros a mapear en la URL.
 * @param kwargs - argumentos en ur de tipo clave valor
 */
export function getUrl(
  actionType: any,
  args: any[] = [],
  kwargs: object = {}
): string {
  const paramsUrl = generateParamsDynamicUrl(args, kwargs);
  switch (actionType) {
    case READ_SALES:
      return `${base}/sales${paramsUrl}`;
    case STORE_SALE:
      return `${base}/sales/`;
    case MAKE_RESERVE:
      return `${base}/inventory/reservations/reserve/`;
    case CHECK_RESERVE:
      return `${base}/inventory/reservations/check/`;
    case CHANGE_RESERVE:
      return `${base}/inventory/reservations/change/`;
    case FREE_RESERVE:
      return `${base}/inventory/reservations/free/`;
    case BULK_FREE_RESERVE:
      return `${base}/inventory/reservations/bulk-free/`;
    case APPLY_BULK_ACTIONS_ORDERS:
      return `${base}/sales/execute-massive-action/`;
    default:
      return "";
  }
}
