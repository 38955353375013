export enum AuthResponseEnum {
  ACTIVE_USER = 'ACTIVE_USER',
  USER_TEMP_PASSWORD_SET = 'USER_TEMP_PASSWORD_SET',
  INVALID_ACCOUNT = 'INVALID_ACCOUNT',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  CANNOT_CHANGE_PASSWORD = 'CANNOT_CHANGE_PASSWORD',
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  USER_INACTIVE = 'USER_INACTIVE',
  AUTHORIZED_LOGIN = 'AUTHORIZED_LOGIN',
  TOKEN_SENT = 'TOKEN_SENT',
  INVALID_TOKEN = 'INVALID_TOKEN',
  VALID_TOKEN = 'VALID_TOKEN'
}