/*-- Ant Design --*/
import { notification, message } from "antd";

/**
 * Pasa todos los atributos indefinidos a nulos en un objeto.
 * @param object
 */
export function undefinedAttributesToNull(object: any) {
  let data = { ...object };
  for (const prop in data) {
    data[prop] = typeof data[prop] !== "undefined" ? data[prop] : null;
  }
  return data;
}

/**
 * Pasa a null el atributo
 * @param {string} attribute
 * @param {object} object
 */
export function undefinedAttributeToNull(attribute: string, object: any) {
  let data = { ...object };
  data[attribute] =
    typeof data[attribute] !== "undefined" ? data[attribute] : null;
  return data;
}

/**
 * Permite esperar un tiempo determinado.
 * @param time
 */
export async function sleep(time: number = 0) {
  const timeToSleep = time ? time : 1000;
  await new Promise((r) => setTimeout(r, timeToSleep));
}

/**
 * Permite validar si un valor es numerico
 * @param n
 */
export function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Obtiene la url base para direccionar en react
 */
export function getBaseReactUrl() {
  let url = window.location.href;
  let idx = url.indexOf("#") + 1;
  return url.substring(0, idx);
}

/**
 * Retorna el nombre completo del usuario
 * @param user
 */
export function getUserFullName(user: any) {
  let name = `${user?.first_name || ""} ${user?.middle_name || ""}`.trim();
  let lastname = `${user?.paternal_last_name || ""} ${
    user?.maternal_last_name || ""
  }`.trim();
  return `${name} ${lastname}`.trim();
}

/**
 * Permite crear un array con numeros hasta n cantidad
 * @param n
 */
export function rangeCustom(n: number) {
  return Array.from({ length: n }, (value, key) => key);
}

/**
 * Permite levantar Alertas flotantes en la parte superior derecha
 * @param type
 * @param title
 * @param msg
 * @param duration
 */
export function openNotificationWithIcon(
  type: "success" | "info" | "warning" | "error",
  title: string,
  msg: string,
  duration?: number
) {
  notification[type]({
    message: title,
    description: msg,
    duration: duration,
  });
}

export function openMessage(
  type: "success" | "info" | "warning" | "error",
  msg: string
) {
  message[type](msg);
}

export function findObjectById(
  listToSearch: Array<any>,
  keyToFind: string,
  id: string | number
) {
  return listToSearch.find((p: any) => p[keyToFind] == id);
}

/**
 * Retorna la url de la foto del usuario, en caso de
 * no tener foto devuelve null
 * @param user
 */
export function getUserPhotoUrl(user: any) {
  //legacy_service_configuration.client_photo_url_directory
  return "";
}

/**
 * Retorna el paygo account correspondiente al service configuration
 * seleccionado.
 * Nullish Coalescing Operator
 * Ref: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html
 * @param user
 * @param serviceConfig
 */
export function getPaygoAccountFromServiceConfigSelected(
  user: any,
  serviceConfigId: string
) {
  let paygoAccount = user?.paygo_accounts?.find((p: any) => {
    return p.legacy_service_configuration.ii == serviceConfigId;
  });

  return paygoAccount ?? null;
}

/**
 * Permite iliminar un elemento de un array
 * @param arr
 * @param item
 */
export function removeItemFromArr(arr: Array<any>, item: any) {
  const i = arr.indexOf(item);
  i !== -1 && arr.splice(i, 1);
}

/**
 * Detecta si es IOS
 */
export function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
