import React from "react";

/*-- Ant Design --*/
import Text from "antd/lib/typography/Text";
import { Divider, Row, Col } from "antd";

/*-- Tools --*/
import { currencyFormatter } from "../../shared/utils/value-formatters";

/*-- Components --*/
import InputIn from "../../shared/components/InputIn";
import { useSelector } from "react-redux";

interface Props {
  periodName: string;
  originServiceCenterName: string;
  deliveryServiceCenterName: string;
  products: any[];
  inputIn?: boolean;
  handleQuantities?: (productId: any, quantity: number) => {};
  wallets?: any[];
  systemWallets?: any;
}

const OrderConfirmation = (props: Props) => {
  const {
    periodName,
    originServiceCenterName,
    deliveryServiceCenterName,
    products,
    inputIn = false,
    handleQuantities = (productId: any, quantity: number) => {},
  } = props;

  const taxConfigurations = useSelector(
    (state: any) => state.taxes.tax_configurations
  );

  const taxRates = useSelector((state: any) => state.taxes.tax_rates);

  function renderProducts() {
    return products.map((prod: any) => (
      <Row
        key={prod.product.id}
        justify="space-between"
        align="middle"
        style={{ width: "100%" }}
      >
        <Col xs={10}>
          <Text strong>{prod.product.name}</Text>
        </Col>

        <Col xs={6} className="text-center">
          {!inputIn ? (
            prod.quantity
          ) : (
            <InputIn
              min={0}
              value={prod.quantity}
              onChange={(quantity: number) =>
                handleQuantities(prod.product, quantity)
              }
            />
          )}
        </Col>

        <Col xs={8} className="text-right">
          <Text style={{ fontSize: "1rem" }}>
            {currencyFormatter(Number(prod.product.price) * prod.quantity)}
          </Text>
        </Col>
      </Row>
    ));
  }

  function renderWallets() {
    let totalsByWallet: any = {};
    props.products?.map(({ product: { wallet_id, price }, quantity }) => {
      totalsByWallet[wallet_id] =
        (totalsByWallet[wallet_id] ?? 0) + price * quantity;
    });

    return (
      <>
        {" "}
        {props.wallets?.map((wallet) => {
          const foundWallet = props.systemWallets.find(
            (systemWallet: any) =>
              systemWallet.wallet_type_slug === wallet.subaccount
          );

          if (!foundWallet) return <></>;
          totalsByWallet[foundWallet.wallet_type_id] =
            totalsByWallet[foundWallet.wallet_type_id] ?? 0;

          return (
            <Row
              justify="space-between"
              style={{ fontSize: "1.1rem", width: "100%" }}
            >
              <Text strong>Saldo {foundWallet.name}</Text>
              <Text strong style={{ color: "#3BC62E" }}>
                {currencyFormatter(
                  wallet.balance - totalsByWallet[foundWallet.wallet_type_id]
                )}
              </Text>
            </Row>
          );
        })}
      </>
    );
  }

  function renderTotals() {
    const totalReduced =
      props.products?.reduce(
        (acc: number, v: any) => acc + v.product.price * v.quantity,
        0
      ) || 0;

    //Calcular taxes de todos los productos y sumarlos en el acumulador
    const taxes =
      props.products?.reduce((acc, { product, quantity }: any) => {
        const taxConfiguration = taxConfigurations?.find(
          ({ tax_configuration_id }: any) =>
            tax_configuration_id === product.tax_configuration_id
        );
        if (taxConfiguration) {
          return (
            acc +
            taxConfiguration.tax_rate_ids.reduce(
              (taxesAcc: number, taxRateId: any) =>
                taxesAcc +
                product.price * quantity -
                (product.price * quantity) /
                  (1 +
                    (taxRates
                      ? parseFloat(
                          taxRates?.find(
                            ({ tax_rate_id }: any) => tax_rate_id === taxRateId
                          ).rate
                        ) / 100
                      : 0)),
              0
            )
          );
        }
        return acc;
      }, 0) ?? 0;

    return (
      <>
        <Row
          justify="space-between"
          style={{ fontSize: "1.1rem", width: "100%" }}
        >
          <Text strong>IMPUESTOS:</Text>{" "}
          <Text strong>{currencyFormatter(taxes)}</Text>
        </Row>
        <Row
          justify="space-between"
          style={{ fontSize: "1.1rem", width: "100%" }}
        >
          <Text strong>SUBTOTAL:</Text>{" "}
          <Text strong>{currencyFormatter(totalReduced - taxes)}</Text>
        </Row>
        <Row
          justify="space-between"
          style={{ fontSize: "1.1rem", width: "100%" }}
        >
          <Text strong>TOTAL:</Text>{" "}
          <Text strong>{currencyFormatter(totalReduced)}</Text>
        </Row>
      </>
    );
  }

  return (
    <Row>
      <Row className="mt-3">
        <Text type="secondary">
          <b>Punto de Venta:</b> {deliveryServiceCenterName}
        </Text>{" "}
      </Row>
      <Row justify="space-between" style={{ width: "100%" }}>
        <Text type="secondary">
          <b>Periodo:</b> {periodName}
        </Text>{" "}
        <br />
        <Text type="secondary">
          <b>Entrega:</b> {originServiceCenterName}
        </Text>
      </Row>

      <Divider style={{ margin: "12px 0" }} />

      {renderProducts() || <b>No tiene productos seleccionados</b>}

      <Divider style={{ margin: "10px 0" }} />

      {renderTotals()}
      {renderWallets()}
    </Row>
  );
};

export default OrderConfirmation;
