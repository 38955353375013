/*-- Actions Types --*/
import {
  LOADING_SERVICE_PERIODS,
  LOADING_TIME_RULES,
  READ_SERVICE_PERIODS,
  READ_TIME_RULES,
} from "./actionTypes";

/*-- Tools --*/
import { sleep } from "../../shared/utils/utils";
import { getUrl } from "./urls";
import { RestDataSource } from "../../shared/webservice/RestDataSource";

export const readServicePeriods = (
  args: any[],
  kwargs: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  dispatch({ type: LOADING_SERVICE_PERIODS, payload: true });
  await sleep();

  try {
    const dataSource = await new RestDataSource(
      getUrl(READ_SERVICE_PERIODS, [], kwargs)
    ).getData();

    dispatch({
      type: READ_SERVICE_PERIODS,
      payload: dataSource,
    });
    dispatch({ type: LOADING_SERVICE_PERIODS, payload: false });
    successCallback(dataSource);
  } catch (error) {
    dispatch({ type: LOADING_SERVICE_PERIODS, payload: false });
    errorCallback(error);
  }
};

export const readTimeRules = (params: object = {}) => async (
  dispatch: Function
) => {
  dispatch({ type: LOADING_TIME_RULES, payload: true });
  await sleep();

  try {
    const dataSource = await new RestDataSource(
      getUrl(READ_TIME_RULES, [], params)
    ).getData();

    dispatch({
      type: READ_TIME_RULES,
      payload: dataSource?.results,
    });
    dispatch({ type: LOADING_TIME_RULES, payload: false });
  } catch (e) {
    dispatch({ type: LOADING_TIME_RULES, payload: false });
  }
};
