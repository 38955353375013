import React, { forwardRef } from "react";

/*-- Ant Design --*/
import { FormInstance } from "antd/lib/form";
import { Form, Input, Button, Row, Col } from "antd";

/*-- Font-Awesome --*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*-- Tools --*/
import { isRequired } from "../../shared/validators/generics";

interface Props {
  onSubmit: any;
  loadingBtnSubmit: boolean;
  onEmailChange: any;
}

const EmailForm = (props: Props, ref: React.Ref<FormInstance>) => {
  const { onSubmit, loadingBtnSubmit } = props;

  return (
    <>
      <Form ref={ref} onFinish={onSubmit}>
        <Row>
          <Col span={18} offset={3} style={{ textAlign: "center" }}>
            <h4 style={{ color: "#ababab" }}>
              {" "}
              Por favor ingrese su email y<br /> contraseña
            </h4>
          </Col>
        </Row>
        <Row className="mt-4" justify="center">
          <Col sm={18} md={6}>
            <Form.Item
              name="email"
              rules={[
                isRequired,
                { type: "email", message: "¡Debe ser un email valido!" },
              ]}
            >
              <Input
                className="one-border-input"
                size="large"
                prefix={
                  <FontAwesomeIcon icon={["far", "user"]} color="#3BC62E" />
                }
                placeholder="Email"
                bordered={false}
                onChange={props.onEmailChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Form.Item>
            <Button
              className="btn-success mt-3"
              shape="round"
              htmlType="submit"
              loading={loadingBtnSubmit}
              size="large"
              style={{ width: 150 }}
            >
              Ingresar
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
};

const WrappedLoginForm = forwardRef(EmailForm);
export default WrappedLoginForm;
