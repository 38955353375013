import { GENERATE_USER_QR } from "./actionTypes";
import { generateParamsDynamicUrl } from "../../shared/store/urls";

const base = process.env.REACT_APP_API_URL;

/**
 * Obtiene la url al end point de la acción a realizar.
 *
 * La URL debe terminar en una barra inclinada. Django no puede redirigir a
 * la barra diagonal URL mientras mantiene los datos POST.
 *
 * @param {string} actionType - Tipo de acción.
 * @param {Array} args - Array de parametros a mapear en la URL.
 */
export function getUrl(
  actionType: any,
  args: any[] = [],
  kwargs: object = {}
): string {
  const paramsUrl = generateParamsDynamicUrl(args, kwargs);
  switch (actionType) {
    case GENERATE_USER_QR:
      return `${base}/business/paygo-accounts${paramsUrl}`;
    default:
      return "";
  }
}
