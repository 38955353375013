import React, { useState } from "react";

/*-- Ant Design --*/
import {
  Input,
  Tabs,
  Row,
  Badge,
  Spin,
  List,
  Typography,
  Modal,
  Form,
} from "antd";

/*-- Font-Awesome --*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*-- Tools --*/
import ShoppingCart from "../../sales/components/ShoppingCart";
import { currencyFormatter } from "../../shared/utils/value-formatters";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { InventoryPurposesEnums } from "../../catalog/enums/ProductsEnums";

interface Props {
  handleQuantities: any;
  handleSendSale: any;
  shoppingCartItems: number;
  categories: Array<any>;
  products: Array<any>;
  stockProducts: Array<any>;
  loadingProducts: boolean;
  onChangeProduct: Function;
  onChangeSearchProduct: Function;
  onResetView: Function;
  currentAccount: any;
}

/*-- Const vars --*/
const { Search } = Input;
const { TabPane } = Tabs;
const { Text } = Typography;
const { confirm } = Modal;

const ProductList = (props: Props) => {
  const { categories, products, stockProducts, loadingProducts } = props;
  const [isSearch, setIsSearch] = useState(false);
  const [form] = Form.useForm();

  const onChangeProduct = (product: any) => {
    if (props.onChangeProduct) {
      props.onChangeProduct(product);
    }
    if (isSearch) {
      onChangeSearchProduct("");
      form.resetFields();
    }
  };

  const onChangeSearchProduct = (keyword: string) => {
    if (props.onChangeSearchProduct) {
      props.onChangeSearchProduct(keyword, () => {
        setIsSearch(keyword.length > 0);
      });
    }
  };

  const onResetView = () => {
    if (props.onResetView) {
      props.onResetView();
    }
  };

  const renderProductsList = (index: number, categoriesAreDisplayed = true) => {
    const category = categories[index];
    let productsByCategory = [];
    if (products && products.length && category) {
      productsByCategory = filterProducts(category, !categoriesAreDisplayed);
    }
    return (
      <List
        size="small"
        dataSource={productsByCategory}
        renderItem={(product, key) => (
          <List.Item key={key}>
            <div onClick={() => onChangeProduct(product)}>
              <p className="mb-0 font-w600">{product.name}</p>
              <p className="mb-0">{currencyFormatter(product.price)}</p>
            </div>
          </List.Item>
        )}
      />
    );
  };

  /**
   * Aplica un filter a productos
   * @param category - objecto de la categoria
   * @param filterByCategory - bandera para saber si se filtra por categoria
   */
  const filterProducts = (category: any, filterByCategory = false) => {
    if (filterByCategory) {
      return products.filter(
        (item) =>
          !props.currentAccount.legacy_client.blocked_products.find(
            // eslint-disable-next-line
            (prd: any) => prd.ii_product == item.id
          ) && checkInventoryProduct(item)
      );
    } else {
      return products.filter(
        (item) =>
          item.categories.includes(category.id) &&
          !props.currentAccount.legacy_client.blocked_products.find(
            // eslint-disable-next-line
            (prd: any) => prd.ii_product == item.id
          ) &&
          checkInventoryProduct(item)
      );
    }
  };

  const checkInventoryProduct = (product: any): boolean => {
    let showProduct = false;
    if (
      product.inventory_purpose_id ===
        InventoryPurposesEnums.SALE_AND_INVENTORY &&
      stockProducts.includes(String(product.id))
    ) {
      showProduct = true;
    } else if (
      [InventoryPurposesEnums.SALE, null].includes(product.inventory_purpose_id)
    ) {
      showProduct = true;
    }
    return showProduct;
  };

  const renderName = (name: any) => {
    return <div className="white-normal text-left">{name}</div>;
  };

  return (
    <div className="m-3">
      <Row align="middle" justify="space-between" className="mb-2">
        <div>
          <FontAwesomeIcon
            onClick={() => {
              confirm({
                title: "¿Estás seguro de eliminar tu pedido?",
                icon: <ExclamationCircleOutlined />,
                content: "",
                okText: "Si",
                okType: "primary",
                cancelText: "Cancelar",
                onOk: () => onResetView(),
              });
            }}
            icon={["fas", "angle-left"]}
            style={{ fontSize: "1.5em", marginRight: "1rem" }}
          />
          <Text strong style={{ fontSize: 22 }}>
            Comprar
          </Text>
        </div>

        <Badge
          count={props.shoppingCartItems}
          style={{ backgroundColor: "#52c41a" }}
        >
          <ShoppingCart
            handleQuantities={props.handleQuantities}
            handleSendSale={props.handleSendSale}
          />
        </Badge>
      </Row>

      <Form form={form}>
        <Form.Item name="search">
          <Search
            loading={isSearch && loadingProducts}
            placeholder="Ingrese su criterio de busqueda"
            onSearch={onChangeSearchProduct}
            onChange={(e: any) => {
              if (e.target.value === "" && isSearch) {
                onChangeSearchProduct("");
              }
            }}
            allowClear
          />
        </Form.Item>
      </Form>
      <div className="text-center mt-2">
        <Spin spinning={loadingProducts} tip="Cargando..." />
      </div>

      {isSearch ? (
        renderProductsList(0, false)
      ) : (
        <Tabs
          tabBarStyle={{ maxWidth: 180 }}
          defaultActiveKey="0"
          tabPosition="left"
          tabBarGutter={6}
        >
          {categories &&
            Array.isArray(categories) &&
            categories.map((category, i) =>
              filterProducts(category).length ? (
                <TabPane tab={renderName(category.name)} key={i}>
                  {renderProductsList(i)}
                </TabPane>
              ) : (
                ""
              )
            )}
        </Tabs>
      )}
    </div>
  );
};

export default ProductList;
