/**
 * Font Awesome Icons
 * Solo los iconos importados explícitamente se utilizarán en la aplicación.
 *
 * Para importar una biblioteca completa:
 * import brands from '@fortawesome/fontawesome-free-brands'
 * fontawesome.library.add(brands);
 *
 */
import * as fontawesome from "@fortawesome/fontawesome-svg-core";

/** SOLID ICONS (PREFIX: fas (default)) */
import {
  faUnlockAlt,
  faBars,
  faShoppingCart,
  faPlus,
  faMinus,
  faAngleLeft,
  faChevronRight,
  faSearch,
  faQrcode,
  faWrench,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

/** REGULAR ICONS (PREFIX: far) */
import {
  faUser,
  faListAlt,
  faComment,
} from "@fortawesome/free-regular-svg-icons";

/** BRAND ICONS (PREFIX: fab) */
import { faGoogle, faApple } from "@fortawesome/free-brands-svg-icons";

export default {
  init: () =>
    fontawesome.library.add(
      faUser,
      faUnlockAlt,
      faGoogle,
      faBars,
      faShoppingCart,
      faListAlt,
      faComment,
      faMinus,
      faPlus,
      faAngleLeft,
      faChevronRight,
      faSearch,
      faQrcode,
      faApple,
      faWrench,
      faTimes
    ),
};
