import {
  CLEAR_SALES,
  LOADING_SALES,
  READ_SALES,
  READ_SALES_SCROLL_INFINITE,
  DELETE_PRODUCT_CART,
  ADD_PRODUCT_CART,
  CHANGE_PRODUCT_CART_QUANTITY,
  SELECT_PRE_PURCHASE,
  LOADING_RESERVATION_STATUS,
  LOADING_APPLY_BULK_ACTIONS_ORDERS,
  SELECT_CLIENT,
  SELECT_ORDER_TO_DELIVER,
  CLEAR_SHOPPING_CART,
} from "./actionTypes";

const INITIAL_STATE: any = {
  selected_client: null, //Cliente seleccionado para entregarle pedidos
  selected_order: null, //Pedido seleccionado para entregarle al cliente

  orders: [],
  columns_orders: [],
  loading_orders: false,
  total_orders: 0,
  is_last_orders: true,
  is_loading_reservation_status: false,
  pre_order_buy: null,
  shopping_cart: [],
  loading_apply_actions: false,
};

function mapProductQuantity(
  productId: number,
  shopping_cart: any[],
  quantity: any = null
) {
  return shopping_cart.map((d: any) => {
    if (d.product.id === productId) {
      if (quantity === null) {
        d.quantity++;
      } else {
        d.quantity = quantity;
      }
    }

    return d;
  });
}

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case LOADING_RESERVATION_STATUS:
      return {
        ...state,
        is_loading_reservation_status: action.payload,
      };
    case READ_SALES:
      return {
        ...state,
        orders: action.payload.results,
        columns_orders: action.payload.columns,
        total_orders: action.payload.count,
        is_last_orders: !action.payload.next,
      };
    case CLEAR_SALES:
      return {
        ...state,
        orders: [],
        columns_orders: [],
        total_orders: 0,
        is_last_orders: true,
      };
    case LOADING_SALES:
      return {
        ...state,
        loading_orders: action.payload,
      };
    case READ_SALES_SCROLL_INFINITE:
      return {
        ...state,
        orders: state.orders.concat(action.payload.results),
        is_last_orders: !action.payload.next,
      };
    case CLEAR_SHOPPING_CART:
      return {
        ...state,
        shopping_cart: [],
      };
    case ADD_PRODUCT_CART:
      const productExist = state.shopping_cart.find(
        (detail: any) => detail.product.id === action.payload.product.id
      );
      if (!productExist) {
        return {
          ...state,
          shopping_cart: state.shopping_cart.concat({
            quantity: 1,
            product: action.payload.product,
          }),
        };
      } else {
        return {
          ...state,
          shopping_cart: mapProductQuantity(
            action.payload.productId,
            state.shopping_cart
          ),
        };
      }
    case CHANGE_PRODUCT_CART_QUANTITY:
      return {
        ...state,
        shopping_cart: mapProductQuantity(
          action.payload.productId,
          state.shopping_cart,
          action.payload.quantity
        ),
      };
    case DELETE_PRODUCT_CART:
      return {
        ...state,
        shopping_cart: state.shopping_cart.filter(
          (d: any) => d.product.id !== action.payload.productId
        ),
      };
    case SELECT_PRE_PURCHASE:
      return {
        ...state,
        pre_order_buy: action.payload,
      };
    case SELECT_CLIENT:
      return {
        ...state,
        selected_client: action.payload,
      };
    case SELECT_ORDER_TO_DELIVER:
      return {
        ...state,
        selected_order: action.payload,
      };
    case LOADING_APPLY_BULK_ACTIONS_ORDERS:
      return {
        ...state,
        loading_apply_actions: action.payload,
      };
    default:
      return state;
  }
}
