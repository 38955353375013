import React, { forwardRef, useState } from "react";

/*-- Ant Design --*/
import { Form, Button, Select, DatePicker, Col, Row, Typography } from "antd";
import { FormInstance } from "antd/lib/form";

/*-- Tools --*/
import { Callbacks } from "rc-field-form/lib/interface";
import { isRequired } from "../../shared/validators/generics";
import moment from "moment";
import { findObjectById } from "../../shared/utils/utils";

/*-- Const vars --*/
const { Option } = Select;
const { Text } = Typography;

interface Props {
  servicePeriods: Array<any>;
  serviceCenters: Array<any>;
  onSubmit: Callbacks["onFinish"];
  loading: boolean;
}

const PrePurchaseForm = (props: Props, ref: React.Ref<FormInstance>) => {
  const [deliveryNodes, setDeliveryNodes] = useState([] as any);
  const [receivingNodes, setReceivingNodes] = useState([] as any);
  const { servicePeriods = [], serviceCenters } = props;

  /**
   * Permite poblar los selects de origin y destino de acuerdo a la configuracion del periodo selecionado
   * @param period - periodo de servicio selecionado en el primer select
   */
  const handleOnChangePeriod = (period: any) => {
    if (
      servicePeriods.length &&
      serviceCenters &&
      Array.isArray(serviceCenters)
    ) {
      let delivery = [];
      let receiving = [];
      let periodSelect = findObjectById(
        servicePeriods,
        "service_period_id",
        period.value
      );
      if (periodSelect !== undefined) {
        delivery =
          periodSelect.delivery_nodes === null
            ? serviceCenters
            : serviceCenters.filter((p: any) =>
                periodSelect.delivery_nodes.includes(p.id)
              );

        receiving =
          periodSelect.receiving_nodes === null
            ? serviceCenters
            : serviceCenters.filter((p: any) =>
                periodSelect.receiving_nodes.includes(p.id)
              );
        setDeliveryNodes(delivery.length ? delivery : []);
        setReceivingNodes(receiving.length ? receiving : []);
      }
    }
  };

  return (
    <div style={{ padding: "2rem" }}>
      <Row align="middle" className="text-lato-title pb-3">
        <Text strong style={{ fontSize: 22 }}>
          Comprar
        </Text>
      </Row>

      <Form ref={ref} onFinish={props.onSubmit} layout={"vertical"}>
        <Row gutter={[16, 4]}>
          <Col className="gutter-row" xs={24} sm={8} md={12} lg={6}>
            <Form.Item
              label="Selecciona el periodo"
              name="service_period"
              rules={[isRequired]}
            >
              <Select
                placeholder="Selecciona una opción"
                labelInValue
                onChange={handleOnChangePeriod}
              >
                {servicePeriods &&
                  Array.isArray(servicePeriods) &&
                  servicePeriods.map((item: any, index: number) => (
                    <Option value={item.service_period_id} key={index}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={8} md={12} lg={6}>
            <Form.Item
              label="Selecciona la fecha"
              name="datetime_due"
              rules={[isRequired]}
              initialValue={moment()}
            >
              <DatePicker className="w-100-pr" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={8} md={12} lg={6}>
            <Form.Item
              label="Selecciona el origen"
              name="receiving_node"
              rules={[isRequired]}
            >
              <Select placeholder="Selecciona una opción" labelInValue>
                {receivingNodes.map((item: any, index: number) => (
                  <Option value={item.id} key={index}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={8} md={12} lg={6}>
            <Form.Item
              label="Selecciona el destino"
              name="delivery_node"
              rules={[isRequired]}
            >
              <Select placeholder="Selecciona una opción" labelInValue>
                {deliveryNodes.map((item: any, index: number) => (
                  <Option value={item.id} key={index}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className="gutter-row text-center mt-auto"
            xs={24}
            sm={16}
            md={24}
            lg={24}
          >
            <Form.Item>
              <Button
                type="primary"
                shape="round"
                size="large"
                className="btn-success"
                htmlType="submit"
                loading={props.loading}
                disabled={props.loading}
              >
                Continuar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const WrappedPrePurchaseForm = forwardRef(PrePurchaseForm);
export default WrappedPrePurchaseForm;
