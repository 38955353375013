import React, { Component } from "react";
import { connect } from "react-redux";

/*-- Store --*/
import { alertFeedback } from "../../shared/store/actionCreators";
import { changePassword, signIn } from "../../auth/store/actionCreators";

/*-- Ant Design --*/
import { FormInstance } from "antd/lib/form";
import { Spin, Row, Col } from "antd";

/*-- Components --*/
import NewPasswordForm from "../components/NewPasswordForm";
import logo from "./../../../logo.png"; // Tell webpack this JS file uses this image

class SetPasswordMain extends Component<any, any> {
  private formRef = React.createRef<FormInstance>();
  private form: any;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount(): void {
    this.form = this.formRef.current;
  }

  /**
   * Maneja el evento submit.
   * @param {Event} e
   */
  private handleSubmit = (values: any) => {
    let login = this.props.loginData?.login;
    let token = this.props.loginData?.token;

    values = { ...values, login, token };

    const onError = (msg: string) => {
      this.props.alertFeedback("error", "", msg);
    };

    const onSuccess = (res: any) => {
      this.props.signIn(
        { login, password: values.new_password },
        () => {
          this.props.history.push("/home");
        },
        () => {
          console.log("Error");
        }
      );
    };

    this.props.changePassword(values, onSuccess, onError);
  };

  render() {
    return (
      <>
        <Spin spinning={this.state.loading} delay={200}>
          <Row justify="center" align="middle">
            <Col className="m-2">
              <img
                className="center"
                style={{ width: 280 }}
                src={logo}
                alt="logo payGO"
              />
            </Col>
          </Row>
          <NewPasswordForm ref={this.formRef} onSubmit={this.handleSubmit} />
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  loginData: storeData.auth.data,
});

const mapDispatchToProps = {
  alertFeedback,
  changePassword,
  signIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordMain);
