import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

/*-- Redux --*/
import { createStore, applyMiddleware, combineReducers } from "redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import { SIGN_OUT } from "./modules/auth/store/actionTypes";

/* -- Reducers -- */
import authReducer from "./modules/auth/store/reducer";
import sharedReducer from "./modules/shared/store/reducer";
import salesReducer from "./modules/sales/store/reducer";
import businessReducer from "./modules/business/store/reducer";
import servicePeriodsReducer from "./modules/service_periods/store/reducer";
import messagingReducer from "./modules/messaging/store/reducer";
import catalogReducer from "./modules/catalog/store/reducer";
import securityReducer from "./modules/security/store/reducer";
import homeReducer from "./modules/home/store/reducer";
import systemReducer from "./modules/system/store/reducer";
import taxesReducer from "./modules/taxes/store/reducer";

/*-- Ant design --*/
import { ConfigProvider } from "antd";
import esES from "antd/es/locale/es_ES";

/*-- Font-Awesome --*/
import fontAwesomeLib from "./modules/shared/utils/iconsLibrary";

/*-- Tools --*/
import "moment/locale/es";
import moment from "moment";
import { setResponseInterceptor } from "./modules/shared/webservice/ResponseInterceptor";

/*-- Capacitor --*/
import { Plugins, Capacitor } from "@capacitor/core";

/*-- Google OAuth --*/
import { GoogleOAuthProvider } from "@react-oauth/google";

const { FCMPlugin } = Plugins;
const CLIENTIDGOOGLE = process.env.REACT_APP_CLIENT_ID_GOOGLE || "";

fontAwesomeLib.init();
moment.locale("es");

const reducers = combineReducers({
  auth: authReducer,
  shared: sharedReducer,
  sales: salesReducer,
  service_periods: servicePeriodsReducer,
  business: businessReducer,
  catalog: catalogReducer,
  messaging: messagingReducer,
  security: securityReducer,
  home: homeReducer,
  system: systemReducer,
  taxes: taxesReducer,
});

const middlewares = [reduxThunk];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const persistKey = "root";
const persistConfig = {
  key: persistKey,
  storage: storage,
  whitelist: ["auth", "shared"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const rootReducer = (state: any, action: any) => {
  if (action.type === SIGN_OUT) {
    if (Capacitor.isNative) {
      let user = state.auth.user;
      FCMPlugin.unsubscribeFrom({ topic: "" + user.user_id });
    }

    storage.removeItem(`persist:${persistKey}`);
    state = undefined;
  }

  return persistedReducer(state, action);
};

const store = createStore(
  rootReducer, //Todos los reducers
  {}, // Estado inicial
  applyMiddleware(...middlewares)
);

let persistor = persistStore(store);

//Se establece el interceptor de respuesta
setResponseInterceptor(store);

ReactDOM.render(
  <ConfigProvider locale={esES}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId={CLIENTIDGOOGLE}>
          <App />
        </GoogleOAuthProvider>
        ;
      </PersistGate>
    </Provider>
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
