import {
  LOADING_CATEGORIES,
  LOADING_PRODUCTS,
  LOADING_STOCK_PRODUCTS,
  READ_CATEGORIES,
  READ_PRODUCTS,
  READ_STOCK_PRODUCTS,
  RESET_STORE_CATALOG,
} from "./actionTypes";

const INITIAL_STATE = {
  categories: [],
  products: [],
  stock_products: [],

  // Loading
  loading_categories: false,
  loading_products: false,
  loading_stock_products: false,

  // Paginate
  products_total_rows: 0,
};

export default function (storeData: any = INITIAL_STATE, action: any) {
  switch (action.type) {
    case READ_CATEGORIES:
      return {
        ...storeData,
        categories: action.payload,
      };
    case LOADING_CATEGORIES:
      return {
        ...storeData,
        loading_categories: action.payload,
      };
    case READ_PRODUCTS:
      return {
        ...storeData,
        products: action.payload.results,
        products_total_rows: action.payload.count || 0,
      };
    case READ_STOCK_PRODUCTS:
      return {
        ...storeData,
        stock_products: action.payload,
      };
    case LOADING_PRODUCTS:
      return {
        ...storeData,
        loading_products: action.payload,
      };
    case LOADING_STOCK_PRODUCTS:
      return {
        ...storeData,
        loading_stock_products: action.payload,
      };
    case RESET_STORE_CATALOG:
      return INITIAL_STATE;
    default:
      return storeData;
  }
}
