import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "./../../../logo.png"; // Tell webpack this JS file uses this image

/*-- Store --*/
import {
  alertFeedback,
  selectPayGOAccount,
  selectServiceConfigurationId,
} from "../../shared/store/actionCreators";
import {
  storeLoginData,
  validateUserLogin,
  recoverPassword,
  signIn,
  socialSignIn,
  readAuthUser,
  validateSecurityTokenToChangePassword,
} from "../../auth/store/actionCreators";

/*-- Ant Design --*/
import { Row, Col, Spin, Button } from "antd";
import { FormInstance } from "antd/lib/form";

/*-- Components --*/
import EmailForm from "../components/EmailForm";
import PasswordForm from "../components/PasswordForm";
import SecurityTokenForm from "../components/SecurityTokenForm";

/*-- Enums --*/
import { AuthStep } from "../enums/AuthEnum";

/*-- Socials Auth --*/
import { GoogleLogin } from "@react-oauth/google";
import "@codetrix-studio/capacitor-google-auth";

/*-- Tools --*/
import { sleep } from "../../shared/utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*-- Capacitor --*/
import { Plugins, Capacitor } from "@capacitor/core";

const { FCMPlugin, Device, SignInWithApple } = Plugins;

class LoginMain extends Component<any, any> {
  private loginFormRef = React.createRef<FormInstance>();

  constructor(props: any) {
    super(props);

    this.state = {
      email: "",
      password: "",
      currentStep: AuthStep.EMAIL,
      loadingStep: false,
    };
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/");
    }
  }

  /**
   * Se envia correo con token para cambiar contraseña
   * @param e
   */
  handleRecoverPassword = async (e: any) => {
    e.preventDefault();
    this.setState({ loadingStep: true });

    if (!this.state.email || this.state.email === "") {
      this.props.alertFeedback(
        "error",
        "",
        "Debe escribir un correo electronico para cambiar contraseña"
      );
      this.setState({ loadingStep: false });
      return;
    }

    this.props.recoverPassword(
      { login: this.state.email },
      () => {
        this.setState({ currentStep: AuthStep.SECURITY_CODE });
        this.setState({ loadingStep: false });
      },
      (msg: string) => {
        this.props.alertFeedback("error", "", msg);
        this.setState({ loadingStep: false });
      }
    );
  };

  /**
   * Selecciona el paygoAccount inicial
   */
  private handleSelectPayGOAccount = (user: any) => {
    const { selectServiceConfigurationId, selectPayGOAccount } = this.props;

    if (user?.paygo_accounts?.length) {
      let paygoAccountSelected = user.paygo_accounts[0];
      let serviceConfigId =
        paygoAccountSelected.legacy_service_configuration.ii;
      selectPayGOAccount(paygoAccountSelected.account_id);
      selectServiceConfigurationId(serviceConfigId);
    }
  };

  /**
   * Maneja el evento submit.
   * @param {Event} e
   */
  private handleSubmit = async (values: any) => {
    const { currentStep, email } = this.state;
    this.setState({ loadingStep: true });

    if (currentStep === AuthStep.EMAIL) {
      // Paso de email

      const onError = (msg: string) => {
        this.props.alertFeedback("error", "", msg);
        this.setState({ loadingStep: false });
      };

      const onSuccess = (res: any) => {
        //Verifico la respuesta del servidor y se cambia al paso de pass o temporal pass
        let step = res.temporalPassword
          ? AuthStep.TEMPORAL_PASS
          : AuthStep.PASS;
        this.setState({
          email: values.email,
          currentStep: step,
          loadingStep: false,
        });
      };

      this.props.validateUserLogin({ login: values.email }, onSuccess, onError);
    } else if (currentStep === AuthStep.PASS) {
      // Paso de contraseña

      let data = { login: email, password: values.password };
      this.props.signIn(
        data,
        () => {
          this.props.readAuthUser(async (user: any) => {
            if (Capacitor.isNative) {
              await FCMPlugin.subscribeTo({ topic: "" + user.user_id });
            }

            this.handleSelectPayGOAccount(user);
            sleep();
            this.props.history.push("/");
          });
        },
        () => {
          this.props.alertFeedback(
            "error",
            "",
            "Email o contraseña incorrecta."
          );
          this.setState({ loadingStep: false });
        }
      );
    } else if (currentStep === AuthStep.TEMPORAL_PASS) {
      // Paso de contraseña temporal

      let data = { login: email, password: values.password };
      this.props.storeLoginData(data);

      this.props.signIn(
        data,
        () => {
          this.props.history.push("/change-password/");
        },
        () => {
          this.props.alertFeedback(
            "error",
            "",
            "Usuario o contraseña incorrecta."
          );
          this.setState({ loadingStep: false });
        }
      );
    } else if (currentStep === AuthStep.SECURITY_CODE) {
      let data = { login: email, token: values.token };

      this.props.validateSecurityTokenToChangePassword(
        data,
        () => {
          this.setState({ token: values.token });
          this.props.storeLoginData(data);
          this.props.history.push("/change-password");
        },
        () => {
          this.props.alertFeedback(
            "error",
            "",
            "Código incorrecto o expirado."
          );
          this.setState({ loadingStep: false });
        }
      );
    }
  };

  private responseGoogle = async (response: any) => {
    let payload: any = null;

    if (Capacitor.isNative) {
      let googleUser = await Plugins.GoogleAuth.signIn();
      payload = {
        platform: "google",
        token_id: googleUser.authentication.idToken,
      };
    } else {
      payload = { platform: "google", token: response.credential };
    }

    if (payload) {
      this.sendSocialSignIn(payload);
    }
  };

  /**
   * Permite hacer login con apple
   */
  private openAppleSignIn = async () => {
    const info = await Device.getInfo();
    if (Number(info.osVersion) < 13) {
      this.props.alertFeedback(
        "error",
        "",
        "No es posible iniciar sesión con Apple en versiones anteriores a ios 13."
      );
      return;
    }

    SignInWithApple.Authorize()
      .then(async (res: any) => {
        if (res.response && res.response.authorizationCode) {
          const payload = {
            platform: "apple",
            token: res.response.authorizationCode,
          };
          this.sendSocialSignIn(payload);
        } else {
          this.props.alertFeedback(
            "error",
            "",
            "No se pudo iniciar sesión con Apple. Vuelva a intentarlo más tarde."
          );
        }
      })
      .catch((response: any) => {
        this.props.alertFeedback(
          "error",
          "",
          "Se produjo un error al iniciar sesión con Apple. Vuelva a intentarlo más tarde."
        );
      });
  };

  /**
   * Envia la peticion con los datos para hacer login con redes sociales
   * @param payload - informacion a enviar (objecto)
   */
  private sendSocialSignIn = (payload: any) => {
    this.props.socialSignIn(
      payload,
      () => {
        this.props.readAuthUser(async (user: any) => {
          if (Capacitor.isNative) {
            await FCMPlugin.subscribeTo({ topic: "" + user.user_id });
          }

          this.handleSelectPayGOAccount(user);
          sleep();
          this.props.history.push("/");
        });
      },
      (error: any) => {
        this.props.alertFeedback(
          "error",
          "",
          "Usted no se encuentra registrado en payGO."
        );
      }
    );
  };

  handleEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  };

  renderStep() {
    const { currentStep } = this.state;
    const showAppleSignIn = false; //Capacitor.platform === "ios"; // cambiar ios

    if (currentStep === AuthStep.EMAIL) {
      return (
        <>
          <EmailForm
            ref={this.loginFormRef}
            onSubmit={this.handleSubmit}
            onEmailChange={this.handleEmailChange}
            loadingBtnSubmit={this.state.loadingStep}
          />

          <Row justify="center">
            <b
              style={{ color: "#1A60B4" }}
              onClick={this.handleRecoverPassword}
            >
              Restablecer contraseña
            </b>
          </Row>

          <Row justify="center" align="middle" className="mt-5">
            {Capacitor.isNative ? (
              <Button
                size="large"
                style={{
                  width: "240px",
                  borderRadius: 3,
                  color: "#000000",
                  borderColor: "#000000",
                }}
                onClick={this.responseGoogle}
              >
                <Row justify="center">
                  <Col xs={3}>
                    <div style={{ background: "rgb(255, 255, 255)" }}>
                      <svg
                        aria-hidden="true"
                        style={{ verticalAlign: "middle" }}
                        className="native svg-icon iconGoogle"
                        width="18"
                        height="18"
                        viewBox="0 0 21 21"
                      >
                        <path
                          d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 002.38-5.88c0-.57-.05-.66-.15-1.18z"
                          fill="#4285F4"
                        ></path>
                        <path
                          d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 01-7.18-2.54H1.83v2.07A8 8 0 008.98 17z"
                          fill="#34A853"
                        ></path>
                        <path
                          d="M4.5 10.52a4.8 4.8 0 010-3.04V5.41H1.83a8 8 0 000 7.18l2.67-2.07z"
                          fill="#FBBC05"
                        ></path>
                        <path
                          d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 001.83 5.4L4.5 7.49a4.77 4.77 0 014.48-3.3z"
                          fill="#EA4335"
                        ></path>
                      </svg>
                    </div>
                  </Col>

                  <Col xs={21}>
                    <span style={{ fontSize: "14px" }}>
                      Iniciar sesión con Google
                    </span>
                  </Col>
                </Row>
              </Button>
            ) : (
              <GoogleLogin
                width="240px"
                text="signin_with"
                onSuccess={this.responseGoogle}
                onError={() => this.responseGoogle}
              />
            )}
          </Row>

          {!showAppleSignIn ? null : (
            <Row justify="center" className="mt-3">
              <Button
                size="large"
                style={{
                  width: "240px",
                  borderRadius: 3,
                  color: "#000000",
                  borderColor: "#000000",
                  fontWeight: 550,
                }}
                onClick={this.openAppleSignIn}
              >
                <Row justify="center">
                  <Col xs={3}>
                    <FontAwesomeIcon icon={["fab", "apple"]} size="1x" />
                  </Col>

                  <Col xs={21}>
                    <span style={{ fontSize: "14px" }}>
                      Iniciar sesión con Apple
                    </span>
                  </Col>
                </Row>
              </Button>
            </Row>
          )}
        </>
      );
    } else if (
      currentStep === AuthStep.PASS ||
      currentStep === AuthStep.TEMPORAL_PASS
    ) {
      return (
        <PasswordForm
          ref={this.loginFormRef}
          temporalPass={currentStep === AuthStep.TEMPORAL_PASS}
          onSubmit={this.handleSubmit}
          recoverPassword={this.handleRecoverPassword}
          history={this.props.history}
        />
      );
    } else if (currentStep === AuthStep.SECURITY_CODE) {
      return (
        <SecurityTokenForm
          ref={this.loginFormRef}
          onSubmit={this.handleSubmit}
          history={this.props.history}
        />
      );
    }
  }

  render() {
    return (
      <Spin spinning={this.state.loadingStep} delay={200}>
        <Row justify="center" style={{ marginTop: "13vh" }}>
          <Col className="m-6" span={22}>
            <img
              className="center"
              style={{ width: 290 }}
              src={logo}
              alt="logo payGO"
            />

            {this.renderStep()}
          </Col>
        </Row>
      </Spin>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  isLoggedIn: storeData.auth.is_logged_in,
  user: storeData.auth.user,
});

const mapDispatchToProps = {
  alertFeedback,
  validateUserLogin,
  recoverPassword,
  signIn,
  socialSignIn,
  validateSecurityTokenToChangePassword,
  storeLoginData,
  selectPayGOAccount,
  selectServiceConfigurationId,
  readAuthUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginMain);
