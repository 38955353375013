/*-- Actions Types --*/
import {
  LOADING_CATEGORIES,
  LOADING_PRODUCTS,
  LOADING_STOCK_PRODUCTS,
  READ_CATEGORIES,
  READ_PRODUCTS,
  READ_STOCK_PRODUCTS,
  RESET_STORE_CATALOG,
} from "./actionTypes";

import { RestDataSource } from "../../shared/webservice/RestDataSource";
import { getUrl } from "./urls";

export const readCategories = (
  args: any[],
  kwargs: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    dispatch({ type: LOADING_CATEGORIES, payload: true });
    const dataSource = await new RestDataSource(
      getUrl(READ_CATEGORIES, args, kwargs)
    ).getData();
    dispatch({
      type: READ_CATEGORIES,
      payload: dataSource.results,
    });
    successCallback(dataSource);
    dispatch({ type: LOADING_CATEGORIES, payload: false });
  } catch (error) {
    dispatch({ type: LOADING_CATEGORIES, payload: false });
    errorCallback(error);
  }
};

export const readProducts = (
  args: any[],
  kwargs: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    dispatch({ type: LOADING_PRODUCTS, payload: true });
    const dataSource = await new RestDataSource(
      getUrl(READ_PRODUCTS, args, kwargs)
    ).getData();
    dispatch({
      type: READ_PRODUCTS,
      payload: dataSource,
    });
    dispatch({ type: LOADING_PRODUCTS, payload: false });
    successCallback(dataSource);
  } catch (error) {
    errorCallback(error);
    dispatch({ type: LOADING_PRODUCTS, payload: false });
  }
};

export const readStockProducts = (
  args: any[],
  kwargs: Object,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  try {
    dispatch({ type: LOADING_STOCK_PRODUCTS, payload: true });

    const dataSource = await new RestDataSource(
      getUrl(READ_STOCK_PRODUCTS, args, kwargs)
    )
      .method("POST")
      .simpleRequest();

    if (dataSource.status) {
      let newKeys = [];
      for (let key in dataSource.data) {
        newKeys.push(key);
      }
      dispatch({
        type: READ_STOCK_PRODUCTS,
        payload: newKeys,
      });
    }
    dispatch({ type: LOADING_STOCK_PRODUCTS, payload: false });
    successCallback(dataSource);
  } catch (error) {
    errorCallback(error);
    dispatch({ type: LOADING_STOCK_PRODUCTS, payload: false });
  }
};

export const resetStoreCatalog = () => {
  return {
    type: RESET_STORE_CATALOG,
  };
};
