import {
  READ_CATEGORIES,
  READ_PRODUCTS,
  READ_STOCK_PRODUCTS,
} from "./actionTypes";

import { generateParamsDynamicUrl } from "../../shared/store/urls";

const base = process.env.REACT_APP_API_URL;
const baseLaravel = process.env.REACT_APP_API_LARAVEL_ENDPOINT;

/**
 * Obtiene la url al end point de la acción a realizar.
 *
 * La URL debe terminar en una barra inclinada. Django no puede redirigir a
 * la barra diagonal URL mientras mantiene los datos POST.
 *
 * @param {string} actionType - Tipo de acción.
 * @param {Array} args - Array de parametros a mapear en la URL.
 * @param kwargs
 */
export function getUrl(
  actionType: any,
  args: any[] = [],
  kwargs: object = {}
): string {
  const paramsUrl = generateParamsDynamicUrl(args, kwargs);
  switch (actionType) {
    case READ_CATEGORIES:
      return `${base}/catalog/categories${paramsUrl}`;
    case READ_PRODUCTS:
      return `${base}/catalog/products${paramsUrl}`;
    case READ_STOCK_PRODUCTS:
      if (args.length === 2) {
        return `${baseLaravel}/legacyapi/pos/getCatalogCountNode/${args[0]}/${args[1]}`;
      }
    default:
      return "";
  }
}
