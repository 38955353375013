export enum statusOrderEnum {
  "PEN" = "Ordenado",
  "TAK" = "En proceso",
  "HLD" = "Preparado",
  "DSP" = "Despachado",
  "FIN" = "Entregado",
  "CAN" = "Cancelado",
}

export enum statusOrderColorsEnum {
  "PEN" = "#FF8000",
  "TAK" = "#FF8000",
  "HLD" = "#FF8000",
  "DSP" = "#3BC62E",
  "FIN" = "#2DABD9",
  "CAN" = "#F0123E",
}

export const pagination = {
  page_size: 20,
};

export const dateFormat: string = "YYYY-MM-DD";
export const dateFormatExtend: string = "LL";
export const hourFormat: string = "hh:mm a";
export const dateTimeFormat: string = "YYYY-MM-DD hh:mm:ss A";
export const columnsDateFormat = ["datetime_due"];
export const columnsDateTimeFormat = ["datetime_atention", "datetime_served"];
