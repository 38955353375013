import {
  SIGN_IN,
  SOCIAL_SIGN_IN,
  VALIDATE_USER_LOGIN,
  RECOVER_PASSWORD,
  CHANGE_PASSWORD,
  VALIDATE_SECURITY_TOKEN_AUTH,
  REFRESH_TOKEN,
  READ_AUTH_USER,
} from "./actionTypes";
import { generateParamsDynamicUrl } from "../../shared/store/urls";

const base = process.env.REACT_APP_API_URL;

/**
 * Obtiene la url al end point de la acción a realizar.
 *
 * La URL debe terminar en una barra inclinada. Django no puede redirigir a
 * la barra diagonal URL mientras mantiene los datos POST.
 *
 * @param {string} actionType - Tipo de acción.
 * @param {Array} args - Array de parametros a mapear en la URL.
 */
export function getUrl(
  actionType: any,
  args: any = [],
  kwargs: object = {}
): string {
  const paramsUrl = generateParamsDynamicUrl(args, kwargs);

  switch (actionType) {
    case VALIDATE_SECURITY_TOKEN_AUTH:
      return `${base}/security/verify_token/`;
    case VALIDATE_USER_LOGIN:
      return `${base}/security/verify_login/`;
    case RECOVER_PASSWORD:
      return `${base}/security/new_password_token/`;
    case CHANGE_PASSWORD:
      return `${base}/security/new_password/`;
    case SIGN_IN:
      return `${base}/security/token/`;
    case REFRESH_TOKEN:
      return `${base}/security/token/refresh/`;
    case SOCIAL_SIGN_IN:
      return `${base}/auth/authorize${paramsUrl}`;
    case READ_AUTH_USER:
      return `${base}/security/user/`;
    default:
      return "";
  }
}
