import React from "react";

/*-- React --*/
import InfiniteScroll from "react-infinite-scroll-component";

/*-- Ant Design --*/
import { Collapse, Row, Col, Tag, Spin } from "antd";

/*-- Enums --*/
import {
  dateFormatExtend,
  hourFormat,
  statusOrderColorsEnum,
  statusOrderEnum,
} from "../enums/OrdersEnums";

/*-- Tools --*/
import moment from "moment";
import { currencyFormatter } from "../../shared/utils/value-formatters";

interface Props {
  sales: Array<any>;
  isLastOrders: boolean;
  loader?: boolean;
  onChangePage: Function;
}

/*-- Const --*/
const { Panel } = Collapse;

const ExpandableItem = (props: Props) => {
  const mb_0 = {
    marginBottom: "0",
  };

  const fetchMoreData = () => {
    props.onChangePage(false);
  };

  const refresh = () => {
    props.onChangePage(true);
  };

  const customHeader = (order: any) => {
    const stOrder = order.attention_state
      ? statusOrderEnum[order.attention_state as keyof typeof statusOrderEnum]
      : "";
    const dynamicColor = stOrder
      ? statusOrderColorsEnum[
          order.attention_state as keyof typeof statusOrderColorsEnum
        ]
      : "#2dabd9";
    return (
      <>
        <Row>
          <Col span={12}>
            <p style={mb_0}>{order?.service_period?.name}</p>
            <p style={mb_0}>{order?.node?.name}</p>
            <p style={mb_0}>{order?.delivery_node?.name}</p>
          </Col>
          <Col span={12}>
            <Row justify="end">
              <Col className="text-left">
                {order.attention_state !== "FIN" ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="help-text">Se entregará:</span>
                    <p style={mb_0}>
                      {moment(order.datetime_due).format(dateFormatExtend)}
                    </p>
                  </div>
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="help-text">Entregado:</span>
                    <p style={mb_0}>
                      {moment(order.datetime_served).format(dateFormatExtend)}
                    </p>
                    <p style={mb_0}>
                      {moment(order.datetime_served).format(hourFormat)}
                    </p>
                  </div>
                )}
                <Tag className="br-13" color={dynamicColor}>
                  {stOrder}
                </Tag>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <InfiniteScroll
      dataLength={
        props.sales && Array.isArray(props.sales) ? props.sales.length : 0
      } //This is important field to render the next data
      next={fetchMoreData}
      hasMore={!props.isLastOrders}
      loader={
        <div style={{ textAlign: "center" }}>
          <Spin style={{ margin: "1rem" }} />
        </div>
      }
      // below props only if you need pull down functionality
      refreshFunction={refresh}
      pullDownToRefresh
      pullDownToRefreshContent={
        <h3 style={{ textAlign: "center" }}>
          &#8595; Tire hacia abajo para refrescar
        </h3>
      }
      releaseToRefreshContent={
        <h3 style={{ textAlign: "center" }}>
          &#8593; Suéltelo para actualizar
        </h3>
      }
      pullDownToRefreshThreshold={100}
    >
      <div className="text-center my-2">
        <Spin spinning={!!props.loader} />
      </div>
      <Collapse expandIconPosition={"right"}>
        {props.sales &&
          Array.isArray(props.sales) &&
          props.sales.map((order: any, key: number) => (
            <Panel header={customHeader(order)} key={key}>
              {order.products &&
                Array.isArray(order.products) &&
                order.products.map((product: any, key: number) => (
                  <Row
                    key={key}
                    style={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}
                  >
                    <Col span={12}>
                      <p style={mb_0}>
                        {product.quantity + " " + product.name_product}
                      </p>
                    </Col>
                    <Col span={12}>
                      <div style={{ textAlign: "right" }}>
                        <p style={mb_0}>
                          {currencyFormatter(product.total_net)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ))}
              <Row style={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}>
                <Col span={12}>
                  <p>
                    <strong>TOTAL</strong>
                  </p>
                </Col>
                <Col span={12}>
                  <div style={{ textAlign: "right" }}>
                    <p>
                      <strong>
                        {currencyFormatter(
                          (order.products &&
                            Array.isArray(order.products) &&
                            order.products.reduce(
                              (total: number, product: any) => {
                                return total + product.total_net;
                              },
                              0
                            )) ||
                            0
                        )}
                      </strong>
                    </p>
                  </div>
                </Col>
              </Row>
            </Panel>
          ))}
      </Collapse>
    </InfiniteScroll>
  );
};

export default ExpandableItem;
