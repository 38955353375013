export const READ_WALLETS = "READ_WALLETS";
export const START_LOAD_READ_WALLETS = "START_LOAD_READ_WALLETS";
export const END_LOADING_READ_WALLETS = "END_LOADING_READ_WALLETS";
export const READ_SERVICE_CONFIGURATIONS = "READ_SERVICE_CONFIGURATIONS";
export const READ_SERVICE_CENTERS = "READ_SERVICE_CENTERS";
export const READ_SERVICE_CENTERS_PAGINATE = "READ_SERVICE_CENTERS_PAGINATE";
export const READ_CASH_REGISTERS = "READ_CASH_REGISTERS";
export const LOADING_SERVICE_CONFIGURATIONS = "LOADING_SERVICE_CONFIGURATIONS";
export const LOADING_SERVICE_CENTERS = "LOADING_SERVICE_CENTERS";
export const LOADING_CASH_REGISTERS = "LOADING_CASH_REGISTERS";

/* Saldos del cliente */
export const READ_PAYGO_ACCOUNT_WALLETS = "READ_PAYGO_ACCOUNT_WALLETS";
export const LOADING_PAYGO_ACCOUNT_WALLETS = "LOADING_PAYGO_ACCOUNT_WALLETS";
