import React from "react";
import "./App.css";

import { HashRouter } from "react-router-dom";
import Routes from "./routes";

import { Notifications } from "react-push-notification";

/*-- Capacitor --*/
import { Plugins, Capacitor } from "@capacitor/core";

/*-- Utils --*/
import { isIOS } from "./modules/shared/utils/utils";

const { PushNotifications } = Plugins;

const subscribePushNotification = async () => {
  try {
    let result = await PushNotifications.requestPermission();

    if (result.granted) {
      // Register with Apple / Google to receive push via APNS/FCM
      await PushNotifications.register();
    }
  } catch (e) {
    console.log("error notificaciones push", e);
  }
};

const App = () => {
  if (Capacitor.isNative) {
    subscribePushNotification();
  }

  if (isIOS()) {
    const meta = document.querySelector('meta[name="viewport"]');
    if (meta)
      meta.setAttribute(
        "content",
        "width=device-width, initial-scale=1, maximum-scale=1"
      );
  }

  return (
    <>
      <Notifications />
      <HashRouter>
        <Routes />
      </HashRouter>
    </>
  );
};

export default App;
