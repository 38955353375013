import React from "react";
import BuyMain from "../containers/BuyMain";

const Buy = (props: any) => {
  return (
    <React.Fragment>
      <BuyMain {...props} />
    </React.Fragment>
  );
};

export default Buy;
