import { READ_TAX_CONFIGURATIONS, READ_TAX_RATES } from "./actionTypes";

import { generateParamsDynamicUrl } from "../../shared/store/urls";

const base = process.env.REACT_APP_API_URL;

/**
 * Obtiene la url al end point de la acción a realizar.
 *
 * @param {string} actionType - Tipo de acción.
 * @param {Array} args - Array de parametros a mapear en la URL.
 * @param kwargs
 */
export function getUrl(
  actionType: any,
  args: any[] = [],
  kwargs: object = {}
): string {
  const paramsUrl = generateParamsDynamicUrl(args, kwargs);
  switch (actionType) {
    case READ_TAX_CONFIGURATIONS:
      return `${base}/taxes/tax-configurations${paramsUrl}`;
    case READ_TAX_RATES:
      return `${base}/taxes/tax-rates${paramsUrl}`;
    default:
      return "";
  }
}
