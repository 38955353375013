import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

/*-- Redux --*/
import { alertFeedback } from "../../shared/store/actionCreators";
import { signOut } from "../../auth/store/actionCreators";
import { deleteAccount } from "../store/actionCreators";

/*-- Ant Design --*/
import { FormInstance } from "antd/lib/form";
import { Row, Col, Button, Modal, Input, Form } from "antd";

/*-- Components --*/

class SettingsMain extends Component<any, any> {
  private formRef = React.createRef<FormInstance>();
  private form: any;

  constructor(props: any) {
    super(props);

    this.state = {
      isModalVisible: false,
      isModalInputVisible: false,
      inputTextModal: "",
    };
  }

  componentDidMount(): void {
    this.form = this.formRef.current;
  }

  /**
   * Maneja el evento submit.
   * @param {Event} e
   */
  private handleSubmit = () => {
    this.handleCancelInputModal();
    if (this.state.inputTextModal != "ELIMINAR") {
      this.props.alertFeedback(
        "error",
        "",
        "Por favor escriba la palabra ELIMINAR para continuar"
      );
      return;
    }

    const onError = (msg: string) => {
      this.props.alertFeedback("error", "", msg);
    };

    const onSuccess = () => {
      Modal.success({
        title: "Eliminar Cuenta",
        content: "El registro ha sido eliminado correctamente",
        okText: "Confirmar",
        onOk: () => {
          this.props.signOut();
        },
      });
    };

    this.props.deleteAccount({}, onSuccess, onError);
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancelInputModal = () => {
    this.setState({ isModalInputVisible: false });
  };

  handleOk = () => {
    this.setState({ isModalVisible: false, isModalInputVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  changeValueInput = (e: { target: { value: any } }) => {
    this.setState({ inputTextModal: e.target.value });
  };

  render() {
    return (
      <>
        <Row className={"p-4"}>
          <Col span={24}>
            <h2>
              <FontAwesomeIcon icon={["fas", "wrench"]} className={"mr-3"} />
              Configurar Cuenta
            </h2>
            <hr />
            <Button
              type="primary"
              onClick={this.showModal}
              className={"mt-3"}
              danger
            >
              <FontAwesomeIcon icon={["fas", "times"]} className={"mr-2"} />{" "}
              Eliminar mi cuenta de payGO
            </Button>

            <Modal
              title="Eliminar Cuenta"
              visible={this.state.isModalVisible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              okText={"SI"}
              cancelText={"NO"}
            >
              <p>¿Está seguro que quiere eliminar su cuenta de payGO?</p>
            </Modal>

            <Modal
              title="Eliminar Cuenta"
              visible={this.state.isModalInputVisible}
              onCancel={this.handleCancelInputModal}
              footer={[
                <Button
                  key="cancel"
                  type="primary"
                  onClick={this.handleCancelInputModal}
                  danger
                >
                  Cancelar
                </Button>,
                <Button
                  key="confirm"
                  type="primary"
                  disabled={this.state.inputTextModal != "ELIMINAR"}
                  onClick={this.handleSubmit}
                  loading={this.props.loadingDeleteAccount}
                >
                  Eliminar mi cuenta
                </Button>,
              ]}
            >
              <p>
                Para eliminar su cuenta, escriba la palabra ELIMINAR en
                mayúsculas en el siguiente campo, y presione el botón que dice
                "Eliminar mi cuenta".
              </p>

              <Input onChange={this.changeValueInput} placeholder="ELIMINAR" />
            </Modal>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  loadingDeleteAccount: storeData.security.loading_delete_account,
});

const mapDispatchToProps = {
  alertFeedback,
  deleteAccount,
  signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMain);
