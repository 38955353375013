import {
  LOADING_SERVICE_PERIODS,
  LOADING_TIME_RULES,
  READ_SERVICE_PERIODS,
  READ_TIME_RULES,
} from "./actionTypes";

const INITIAL_STATE: any = {
  service_period: null,
  service_periods: [],
  time_rules: [],
  loading_service_periods: false,
  loading_time_rules: false,

  // Paginate vars
  service_period_total_rows: 0,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case READ_SERVICE_PERIODS:
      return {
        ...state,
        service_periods: action.payload.results,
        service_period_total_rows: action.payload.count,
      };
    case LOADING_SERVICE_PERIODS:
      return {
        ...state,
        loading_service_periods: action.payload,
      };
    case READ_TIME_RULES:
      return {
        ...state,
        time_rules: action.payload,
      };
    case LOADING_TIME_RULES:
      return {
        ...state,
        loading_time_rules: action.payload,
      };
    default:
      return state;
  }
}
