import React from "react";

/*-- Ant design ---*/
import { Tabs } from "antd";
import { statusOrderEnum } from "../enums/OrdersEnums";

/*-- Const --*/
const { TabPane } = Tabs;

interface Props {
  onChangeTab: Function;
}

const FilterOrdersTabs = (props: Props) => {
  const callback = (key: any) => {
    if (props.onChangeTab) {
      props.onChangeTab(key);
    }
  };

  return (
    <div className="mx-4">
      <Tabs defaultActiveKey="" onChange={callback}>
        <TabPane tab="Todos" key="PEN,TAK,HLD,DSP,CAN" />
        {Object.keys(statusOrderEnum).map((item: string) => {
          if (item !== "FIN") {
            return (
              <TabPane
                tab={statusOrderEnum[item as keyof typeof statusOrderEnum]}
                key={item}
              />
            );
          }
        })}
      </Tabs>
    </div>
  );
};

export default FilterOrdersTabs;
