import React, { Component } from "react";

/*-- Store --*/
import { connect } from "react-redux";
import {
  readWallets,
  readPaygoAccountWallets,
} from "../../business/store/actionCreators";
import { applyActionsOrders } from "../store/actionCreators";
import { setMessageInQrScanMain } from "../../home/store/actionCreators";
import { clearUser } from "../../security/store/actionCreators";

/*-- Components --*/
import CustomerDeliveryInformation from "../components/CustomerDeliveryInformation";
import CustomerBalances from "../../home/components/CustomerBalances";

/*-- Ant Design --*/
import { Button, Card, Col, Divider, Row } from "antd";
import Text from "antd/lib/typography/Text";

/*-- Tools --*/
import { currencyFormatter } from "../../shared/utils/value-formatters";
import {
  getPaygoAccountFromServiceConfigSelected,
  getUserFullName,
  getUserPhotoUrl,
} from "../../shared/utils/utils";

/*-- Enums --*/
import { MassiveActionsEnums } from "../enums/MassiveActionsEnums";

class ConfirmDeliveryMain extends Component<any, any> {
  private paygoAccWallets: any = [];

  constructor(props: any) {
    super(props);

    this.state = {
      paygoAccWallets: [],
    };
  }

  componentDidMount() {
    this.getWallets();
  }

  /**
   * Obtiene las billeteras de la configuración
   * de servicio
   */
  getWallets() {
    const { serviceConfigId } = this.props;

    this.props.readWallets(
      [],
      { service_configuration_id: serviceConfigId },
      (wallets: any[]) => {
        this.paygoAccWallets = wallets.map((w: any) => {
          return { name: w.name, slug: w.wallet_type_slug, value: 0 };
        });

        this.setState({ paygoAccWallets: this.paygoAccWallets });
        this.getBalances();
      }
    );
  }

  /**
   * Obtiene los balances del cliente
   */
  getBalances() {
    const { selectedClient, serviceConfigId } = this.props;
    let paygoAccount = getPaygoAccountFromServiceConfigSelected(
      selectedClient,
      serviceConfigId
    );

    if (paygoAccount) {
      this.props.readPaygoAccountWallets(
        [paygoAccount.account_id, "wallets"],
        {},
        (paygoAccountWallets: any[]) => {
          for (let paygoAccW of paygoAccountWallets) {
            let idx = this.paygoAccWallets.findIndex((e: any) => {
              return e.slug === paygoAccW.subaccount;
            });

            if (idx !== -1) {
              this.paygoAccWallets[idx].value = paygoAccW.balance;
            }
          }

          this.setState({ paygoAccWallets: this.paygoAccWallets });
        }
      );
    }
  }

  handleSubmitBtn = () => {
    const { selectedOrder, serviceConfigId, selectedClient } = this.props;
    const selectedOrders: any[] = [selectedOrder.ii];
    const params = {
      service_configuration_id: serviceConfigId,
      selected_items: selectedOrders,
      massive_action_id: MassiveActionsEnums.DELIVER, //Entregar
      type_view: "3", //vista tipo DETAILED
    };

    this.props.applyActionsOrders(
      params,
      (res: any) => {
        this.props.setMessageInQrScanMain({
          text: `El pedido #: ${
            selectedOrder.ii
          } se ha entregado exitosamente a ${getUserFullName(selectedClient)}`,
          type: "success",
          time: 6000,
        });
        this.props.clearUser();
        this.props.history.push("/");
      },
      (res: any) => {
        this.props.setMessageInQrScanMain({
          text: `El pedido #: ${
            selectedOrder.ii
          } no se pudo entregar a ${getUserFullName(
            selectedClient
          )}, inténtalo de nuevo más tarde`,
          type: "error",
          time: 5000,
        });
        this.props.clearUser();
        this.props.history.push("/");
      }
    );
  };

  render() {
    const {
      orders,
      selectedClient,
      selectedOrder,
      loaderApplyAction,
      loadingWallets,
    } = this.props;
    let customerFullName = getUserFullName(selectedClient);
    let customerPhoto = getUserPhotoUrl(selectedClient);

    return (
      <div className="p-3 mx-2">
        <h2>Confirmar entrega</h2>

        <CustomerDeliveryInformation
          userName={customerFullName}
          userPhoto={customerPhoto}
          orderNumber={selectedOrder?.ii}
          delivery_node_name={selectedOrder?.delivery_node?.name}
          orderDate={selectedOrder?.datetime_due}
          servicePeriodName={selectedOrder?.service_period?.name}
        />

        <Card
          bordered={false}
          style={{ backgroundColor: "#f7f7f7" }}
          bodyStyle={{ padding: "12px" }}
        >
          {selectedOrder?.products.map((prod: any, index: number) => (
            <Row key={index}>
              <Col xs={13}>{prod.name_product}</Col>

              <Col xs={3} className="text-center">
                {prod.quantity}
              </Col>

              <Col xs={8} className="text-right">
                {currencyFormatter(prod.total_gross)}
              </Col>
            </Row>
          ))}

          <Divider style={{ margin: "10px 0" }} />

          <Row>
            <Col xs={{ span: 11 }}>
              <Text strong>Total</Text>
            </Col>

            <Col xs={{ span: 10, offset: 3 }} className="text-right">
              <Text strong>
                {currencyFormatter(selectedOrder?.total_gross)}
              </Text>
            </Col>
          </Row>
        </Card>

        <div className="my-3">
          <Text type="secondary" strong>
            Saldos
          </Text>

          <CustomerBalances
            loading={loadingWallets}
            balances={this.state.paygoAccWallets}
          />
        </div>

        <Row className="my-2" justify="center" gutter={16}>
          <Col xs={24} md={4} className="text-center">
            <Button
              className="btn-success mt-3"
              shape="round"
              type="primary"
              disabled={loaderApplyAction}
              loading={loaderApplyAction}
              onClick={this.handleSubmitBtn}
            >
              Confirmar
            </Button>
          </Col>

          {orders?.length <= 1 ? null : (
            <Col xs={24} md={4} className="text-center">
              <Button
                className="btn-success mt-3"
                shape="round"
                type="primary"
                onClick={() =>
                  this.props.history.push("/sales/order-selection")
                }
              >
                Entregar otro pedido
              </Button>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  selectedClient: storeData.sales.selected_client,
  serviceConfigId: storeData.shared.selected_service_configuration_id,
  selectedOrder: storeData.sales.selected_order,
  orders: storeData.sales.orders,
  loaderApplyAction: storeData.sales.loading_apply_actions,
  loadingWallets: storeData.business.loading_wallets,
});

const mapDispatchToProps = {
  readWallets,
  readPaygoAccountWallets,
  applyActionsOrders,
  setMessageInQrScanMain,
  clearUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmDeliveryMain);
