import {
  READ_WALLETS,
  READ_SERVICE_CONFIGURATIONS,
  READ_SERVICE_CENTERS,
  READ_CASH_REGISTERS,
  READ_PAYGO_ACCOUNT_WALLETS,
} from "./actionTypes";

import { generateParamsDynamicUrl } from "../../shared/store/urls";

const base = process.env.REACT_APP_API_URL;

/**
 * Obtiene la url al end point de la acción a realizar.
 *
 * La URL debe terminar en una barra inclinada. Django no puede redirigir a
 * la barra diagonal URL mientras mantiene los datos POST.
 *
 * @param {string} actionType - Tipo de acción.
 * @param {Array} args - Array de parametros a mapear en la URL.
 * @param kwargs
 */
export function getUrl(
  actionType: any,
  args: any[] = [],
  kwargs: object = {}
): string {
  const paramsUrl = generateParamsDynamicUrl(args, kwargs);
  switch (actionType) {
    case READ_WALLETS:
      return `${base}/wallets${paramsUrl}`;
    case READ_SERVICE_CONFIGURATIONS:
      return `${base}/business/service-configurations${paramsUrl}`;
    case READ_SERVICE_CENTERS:
      return `${base}/business/service-centers${paramsUrl}`;
    case READ_CASH_REGISTERS:
      return `${base}/business/cash-registers${paramsUrl}`;
    case READ_PAYGO_ACCOUNT_WALLETS:
      return `${base}/business/paygo-accounts${paramsUrl}`;
    default:
      return "";
  }
}
