import React, { forwardRef } from "react";

/*-- Ant Design --*/
import { FormInstance } from "antd/lib/form";
import { Form, Input, Button, Col, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*-- Tools --*/
import { isRequired, isPassword } from "../../shared/validators/generics";

interface Props {
  onSubmit: any;
}

const NewPasswordForm = (props: Props, ref: React.Ref<FormInstance>) => {
  const { onSubmit } = props;

  return (
    <Form ref={ref} onFinish={onSubmit}>
      <Row>
        <Col span={18} offset={3} style={{ textAlign: "center" }}>
          <h4 style={{ color: "#ababab" }}>
            {" "}
            Por favor ingrese su nueva contraseña
            <br /> permanente dos veces.
          </h4>
        </Col>
      </Row>

      <Row className="mt-4" justify="center">
        <Col sm={18} md={6}>
          <Form.Item name="new_password" rules={[isRequired, isPassword]}>
            <Input.Password
              bordered={false}
              prefix={
                <FontAwesomeIcon icon={["fas", "unlock-alt"]} color="#3BC62E" />
              }
              placeholder="Ingresar nueva contraseña"
              className="one-border-input"
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center">
        <Col sm={18} md={6}>
          <Form.Item
            name="confirm_new_password"
            dependencies={["new_password"]}
            rules={[
              isRequired,
              isPassword,
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Las contraseñas deben ser iguales");
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Repetir nueva contraseña"
              prefix={
                <FontAwesomeIcon icon={["fas", "unlock-alt"]} color="#3BC62E" />
              }
              bordered={false}
              className="one-border-input"
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center">
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="btn-success mt-3"
            shape="round"
          >
            Cambiar contraseña
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

const WrappedLoginForm = forwardRef(NewPasswordForm);
export default WrappedLoginForm;
