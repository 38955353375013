import Axios from "axios";

/*-- Store --*/
import { getUrl } from "../../auth/store/urls";
import { REFRESH_TOKEN } from "../../auth/store/actionTypes";

/*-- Tools --*/
import { RefreshTokenError } from "../utils/exceptions";

interface Token {
  access?: string;
  refresh?: string;
}

type ApiMethod =
  | "POST"
  | "post"
  | "GET"
  | "get"
  | "PUT"
  | "put"
  | "PATCH"
  | "patch"
  | "DELETE"
  | "delete";

/**
 * Servicio a APIs externas.
 */
export class RestDataSource {
  private BASE_URL: string = "";
  private authInfo: Token = {};
  private token: string | undefined = undefined;
  private _headers: any = {};
  private _method: ApiMethod = "GET";
  private _options: any = {};

  constructor(base_url: string) {
    this.authInfo = JSON.parse(localStorage.getItem("clientsAuthV2") || "{}");
    this.token = this.authInfo?.access;
    this.BASE_URL = base_url;
    if (this.token) this._headers.Authorization = `Secret ${this.token || ""}`;
  }

  /**
   * Establece el methodo de la petición a realizar.
   * @param newMethod
   */
  method(newMethod: ApiMethod) {
    this._method = newMethod;
    return this;
  }

  /**
   * Establece nuevos headers a la petición que se realizará.
   * example: .headers({Accept: 'application/json'})
   */
  headers(newHeaders: Object) {
    this._headers = { ...this._headers, newHeaders };
    return this;
  }

  options(newOptions: Object) {
    this._options = newOptions;
    return this;
  }

  /**
   * Función para operaciones con cualquier método y
   * sin token de autenticación.
   * @param data
   */
  simpleRequest(data?: any) {
    delete this._headers.Authorization;
    return this.sendRequest(data);
  }

  /**
   * Función para operaciones con el método GET.
   */
  getData() {
    this._method = "GET";
    return this.sendRequest();
  }

  /**
   * Función para operaciones con el método POST.
   * @param data
   */
  store(data: any) {
    this._method = "POST";
    return this.sendRequest(data);
  }

  /**
   * Función para operaciones con el método PUT.
   * @param data
   */
  update(data: any) {
    this._method = "PUT";
    return this.sendRequest(data);
  }

  /**
   * Función para operaciones con el método DELETE.
   * @param data
   */
  delete(data: any) {
    this._method = "DELETE";
    return this.sendRequest(data);
  }

  /**
   * Permite la descarga de archivos
   */
  export() {
    this._options = { ...this._options, responseType: "blob" };
    return this.sendRequest();
  }

  /**
   * Efectúa la solicitud a la API.
   * @param method
   * @param url
   * @param data
   * @param opts
   */
  private async attemptRequest(
    method: ApiMethod,
    url: string,
    data: any = null
  ) {
    let options: any = {
      headers: this._headers,
      method: method,
      url: url,
      data: data,
      ...this._options,
    };

    try {
      let response = await Axios.request(options);
      return { status: true, data: response.data };
    } catch (error) {
      return { status: false, data: error.response?.data };
    }
  }

  /**
   * Función que permite la autenticación y el manejo de excepciones.
   * @param data
   */
  private async sendRequest(data: any = null) {
    let response = await this.attemptRequest(this._method, this.BASE_URL, data);

    if (!response.status) {
      //throw new Error(response.data); //Esta lanzado los errores en un modal al usuario
    }

    return response.data;
  }
}
