import {
  END_LOADING_READ_WALLETS,
  LOADING_CASH_REGISTERS,
  LOADING_SERVICE_CENTERS,
  LOADING_SERVICE_CONFIGURATIONS,
  READ_CASH_REGISTERS,
  READ_SERVICE_CENTERS,
  READ_SERVICE_CENTERS_PAGINATE,
  READ_SERVICE_CONFIGURATIONS,
  READ_WALLETS,
  START_LOAD_READ_WALLETS,
  READ_PAYGO_ACCOUNT_WALLETS,
  LOADING_PAYGO_ACCOUNT_WALLETS,
} from "./actionTypes";
import { RestDataSource } from "../../shared/webservice/RestDataSource";
import { getUrl } from "./urls";
import { sleep } from "../../shared/utils/utils";

/**
 * Obtiene todas las billeteras existentes para la configuración de servicio
 * indicada.
 * @param args
 * @param kwargs
 * @param onSuccess
 * @param onError
 */
export const readWallets = (
  args: any[],
  kwargs: Object,
  onSuccess: Function = () => {},
  onError: Function = () => {}
) => async (dispatch: Function) => {
  // Mostrar indicador de carga.
  dispatch({ type: START_LOAD_READ_WALLETS });
  await sleep();

  try {
    const dataSource = new RestDataSource(getUrl(READ_WALLETS, args, kwargs));
    const response = await dataSource.getData();
    let data = response.result;

    onSuccess(data);
    dispatch({
      type: READ_WALLETS,
      payload: data,
    });

    // Ocultar indicador de carga.
    dispatch({ type: END_LOADING_READ_WALLETS });
  } catch (error) {
    onError(error);
    dispatch({ type: END_LOADING_READ_WALLETS });
  }
};

/**
 * Obtiene todas las configuraciones de servicio en las cuales el
 * usuario identificado tiene permisos.
 */
export const readServiceConfigurations = () => async (dispatch: Function) => {
  try {
    const dataSource = new RestDataSource(getUrl(READ_SERVICE_CONFIGURATIONS));

    // Mostrar indicador de carga.
    dispatch({ type: LOADING_SERVICE_CONFIGURATIONS, payload: true });
    await sleep();

    // Realizar solicitud.
    const response = await dataSource.getData();
    dispatch({
      type: READ_SERVICE_CONFIGURATIONS,
      payload: response.result,
    });
    dispatch({ type: LOADING_SERVICE_CONFIGURATIONS, payload: false });
  } catch (error) {
    dispatch({ type: LOADING_SERVICE_CONFIGURATIONS, payload: false });
  }
};

/**
 * Obtiene los centros de servicio para una configuración de servicio.
 * de servicio.
 * @param args
 * @param kwargs
 * @param successCallback
 * @param errorCallback
 */
export const readServiceCenters = (
  args: any[],
  kwargs: any,
  successCallback: Function = () => {},
  errorCallback: Function = () => {}
) => async (dispatch: Function) => {
  // Mostrar indicador de carga.
  dispatch({ type: LOADING_SERVICE_CENTERS, payload: true });
  await sleep();

  try {
    const dataSource = await new RestDataSource(
      getUrl(READ_SERVICE_CENTERS, args, kwargs)
    ).getData();

    dispatch({
      type: kwargs["page"]
        ? READ_SERVICE_CENTERS_PAGINATE
        : READ_SERVICE_CENTERS,
      payload: dataSource,
    });

    // Ocultar indicador de carga.
    dispatch({ type: LOADING_SERVICE_CENTERS, payload: false });
    successCallback(dataSource);
  } catch (error) {
    dispatch({ type: LOADING_SERVICE_CENTERS, payload: false });
    errorCallback(error);
  }
};

/**
 * Obtiene las cajas para una configuración de servicio.
 * @param args
 * @param kwargs
 */
export const readCashRegisters = (args: any[], kwargs: Object) => async (
  dispatch: Function
) => {
  const dataSource = new RestDataSource(
    getUrl(READ_CASH_REGISTERS, args, kwargs)
  );

  // Mostrar indicador de carga.
  dispatch({ type: LOADING_CASH_REGISTERS, payload: true });
  await sleep();

  try {
    const responseData = await dataSource.getData();
    dispatch({
      type: READ_CASH_REGISTERS,
      payload: responseData.result,
    });

    // Ocultar indicador de carga.
    dispatch({ type: LOADING_CASH_REGISTERS, payload: false });
  } catch (error) {
    // Ocultar indicador de carga.
    dispatch({ type: LOADING_CASH_REGISTERS, payload: false });
  }
};

/**
 * Obtiene los balances del cliente en la configuración
 * de servicio seleccionada.
 * @param args
 * @param kwargs
 * @param onSuccess
 * @param onError
 */
export const readPaygoAccountWallets = (
  args: any[],
  kwargs: Object,
  onSuccess: Function = () => {},
  onError: Function = () => {}
) => async (dispatch: Function) => {
  // Mostrar indicador de carga.
  dispatch({ type: LOADING_PAYGO_ACCOUNT_WALLETS, payload: true });
  await sleep();
  try {
    const dataSource = new RestDataSource(
      getUrl(READ_PAYGO_ACCOUNT_WALLETS, args, kwargs)
    );
    const response = await dataSource.getData();
    let data = response.results;

    onSuccess(data);
    dispatch({ type: READ_PAYGO_ACCOUNT_WALLETS, payload: data });
    // Ocultar indicador de carga.
    dispatch({ type: LOADING_PAYGO_ACCOUNT_WALLETS, payload: false });
  } catch (error) {
    onError(error);
    dispatch({ type: LOADING_PAYGO_ACCOUNT_WALLETS, payload: false });
  }
};
