import React, { Component } from "react";

/*-- Store --*/
import { connect } from "react-redux";

/*-- Ant Design --*/
import { Button } from "antd";

/*-- Custom Components --*/
import OrderConfirmation from "../components/OrderConfirmation";
import { storeSale } from "./../store/actionCreators";
import { openMessage } from "../../shared/utils/utils";
import { getProductComponents } from "../services/SaleService";

class OrderConfirmationMain extends Component<any, any> {
  handleSubmit = () => {
    const ii_client = this.props.accounts.find(
      (account: any) => account.account_id === this.props.selectedAccount
    )["legacy_client"]["ii"];

    let sale = {
      products: this.props.shoppingCart.map((detail: any) => ({
        paygo_id: detail.product.id,
        quantity: detail.quantity,
        price: detail.product.price,
        inventoryInfo: getProductComponents(detail.product),
      })),
      ii_delivery_node: this.props.preOrderBuy.delivery_node.value,
      datetime_due: this.props.preOrderBuy.datetime_due,
      ii_client: ii_client,
      ii_associated_entity: this.props.selectedServiceConfigurationId,
      ii_node: this.props.preOrderBuy.receiving_node.value,
      service_period_id: this.props.preOrderBuy.service_period.value,
    };

    this.props.storeSale(
      sale,
      () => {
        this.props.history.push("/sales/list");
      },
      (msg: string) => {
        openMessage(
          "error",
          msg ||
            "Lo sentimos pero algunos de los productos no estan disponibles para comprar."
        );
      }
    );
  };

  render() {
    const { preOrderBuy, products } = this.props;

    return (
      <div className="p-3 mx-2">
        <h2>Confirmar pedido</h2>

        <OrderConfirmation
          periodName={preOrderBuy?.service_period.label}
          originServiceCenterName={preOrderBuy?.delivery_node.label}
          deliveryServiceCenterName={preOrderBuy?.receiving_node.label}
          products={products}
          handleQuantities={this.props.handleQuantities}
        />

        <div className="my-3 text-center">
          <Button
            className="btn-success mt-3"
            shape="round"
            onClick={this.handleSubmit}
            size="large"
            disabled={this.props.loadingReservations}
          >
            CONFIRMAR PEDIDO
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  accounts: storeData.auth.user.paygo_accounts,
  preOrderBuy: storeData.sales.pre_order_buy,
  products: storeData.sales.shopping_cart,
  shoppingCart: storeData.sales.shopping_cart,
  loadingReservations: storeData.sales.is_loading_reservation_status,
  selectedServiceConfigurationId:
    storeData.shared.selected_service_configuration_id,
  selectedAccount: storeData.shared.selected_paygo_account_id,
});
const mapDispatchToProps = {
  storeSale,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderConfirmationMain);
