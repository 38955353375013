import { generateParamsDynamicUrl } from "../../shared/store/urls";
import { DELETE_ACCOUNT, READ_USERS } from "./actionTypes";

const base = process.env.REACT_APP_API_URL;

/**
 * Obtiene la url al end point de la acción a realizar.
 *
 * La URL debe terminar en una barra inclinada. Django no puede redirigir a
 * la barra diagonal URL mientras mantiene los datos POST.
 *
 * @param {string} actionType - Tipo de acción.
 * @param {Array} args - Array de parametros a mapear en la URL.
 * @param kwargs - argumentos en ur de tipo clave valor
 */
export function getUrl(
  actionType: any,
  args: any[] = [],
  kwargs: object = {}
): string {
  const paramsUrl = generateParamsDynamicUrl(args, kwargs);
  switch (actionType) {
    case READ_USERS:
      return `${base}/security/users${paramsUrl}`;
    case DELETE_ACCOUNT:
      return `${base}/security/delete_account`;
    default:
      return "";
  }
}
