export function getProductComponents(product: any) {
  let components: any = null;

  if (product.component_type === "COMBO" && product.components) {
    components = { type: "combo", components: [] };

    for (let component of product.components) {
      components["components"].push({
        component_quantity: component.quantity,
        ii_catalog: component.product_id,
      });
    }
  }

  return components;
}

/**
 * Función que obtiene todas las billeteras existentes para la configuración de servicio, requiere la función de readWallets,
 * el id de la configuración de servicio seleccionada y la función callback que se ejecuta con las wallets de respuesta.
 */
export function getWallets({
  selectedServiceConfigurationId,
  readWallets,
  cb,
}: any) {
  readWallets(
    [],
    { service_configuration_id: selectedServiceConfigurationId },
    (wallets: any[]) => {
      const paygoAccWallets = wallets.map((w: any) => {
        return { name: w.name, slug: w.wallet_type_slug, value: 0 };
      });
      cb({ paygoAccWallets });
    }
  );
}

/**
 * Función que consulta los balances del cliente, requiere la función de readWallets,
 * el id de la configuración de servicio seleccionada y la función callback.
 */
export function getBalances({
  paygoAccWallets,
  selectedAccount,
  readPaygoAccountWallets,
  cb,
}: any) {
  readPaygoAccountWallets(
    [selectedAccount, "wallets"],
    {},
    (paygoAccountWallets: any[]) => {
      for (let paygoAccW of paygoAccountWallets) {
        let idx = paygoAccWallets.findIndex((e: any) => {
          return e.slug == paygoAccW.subaccount;
        });

        if (idx !== -1) {
          paygoAccWallets[idx].value = paygoAccW.balance;
        }
      }

      cb({
        balancesLoading: false,
        paygoAccWallets: paygoAccWallets,
        error: false,
      });
    },
    () => {
      cb({
        balancesLoading: false,
        error: true,
      });
    }
  );
}
