const base = process.env.REACT_APP_API_BASE_ENDPOINT;

/**
 * Obtiene la url al end point de la acción a realizar.
 *
 * La URL debe terminar en una barra inclinada. Django no puede redirigir a
 * la barra diagonal URL mientras mantiene los datos POST.
 *
 * @param {string} actionType - Tipo de acción.
 * @param {Array} args - Array de parametros a mapear en la URL.
 */
export function getUrl(
  actionType: any,
  args: any[] = [],
  kwargs: object = {}
): string {
  switch (actionType) {
    default:
      return `${base}`;
  }
}

/**
 * Agrega parametros a la url de las peteciones GET
 * @param args array de los argumentos por url como id
 * @param kwargs object de tipo => {parametro: valor, ...} parametros adicionales como filtros
 * @param toURI
 */
export function generateParamsDynamicUrl(
  args: any[],
  kwargs: any,
  toURI: boolean = false
) {
  let resUrl = "";
  let paramsUrl: any = [];

  if (args.length) {
    resUrl = "/" + args.map((item: any) => item).join("/");
  }

  if (typeof kwargs === "object" && Object.keys(kwargs).length) {
    for (let idx in kwargs) {
      if (Array.isArray(kwargs[idx])) {
        for (let multiParam of kwargs[idx]) {
          paramsUrl.push({
            key: idx,
            value: toURI ? encodeURIComponent(multiParam) : multiParam,
          });
        }
      } else {
        paramsUrl.push({
          key: idx,
          value: toURI ? encodeURIComponent(kwargs[idx]) : kwargs[idx],
        });
      }
    }

    if (paramsUrl.length) {
      resUrl +=
        "/?" +
        paramsUrl.map((item: any) => `${item.key}=${item.value}`).join("&");
    }
  } else {
    resUrl += "/";
  }

  return resUrl;
}
