import React, { forwardRef } from "react";

/*-- Ant Design --*/
import { Form, DatePicker, Button, Col, Row, Select, Input } from "antd";

/*-- Tools --*/
import { Callbacks } from "rc-field-form/lib/interface";
import { FormInstance } from "antd/lib/form";
import { isRequired } from "../../shared/validators/generics";

/*-- Enums --*/
import { statusOrderEnum } from "../enums/OrdersEnums";

/*-- Const --*/
const { Option } = Select;

interface Props {
  servicePeriods: Array<any>;
  onSubmit: Callbacks["onFinish"];
  loading: boolean;
}

const FiltersOrdersForDispatchForm = (
  props: Props,
  ref: React.Ref<FormInstance>
) => {
  const { servicePeriods } = props;

  return (
    <div className="mr-3 ml-3">
      <h1>
        <strong>Pedidos</strong>
      </h1>
      <Form layout="vertical" ref={ref} onFinish={props.onSubmit}>
        <Row gutter={[8, 16]}>
          <Col xs={24} md={8}>
            <Form.Item label="Fecha" name="datetime_due" rules={[isRequired]}>
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              label="Periodo"
              name="service_period_id"
              rules={[isRequired]}
            >
              <Select placeholder="Selecciona una opción">
                {servicePeriods &&
                  Array.isArray(servicePeriods) &&
                  servicePeriods.map((item: any, index: number) => (
                    <Option value={item.service_period_id} key={index}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              label="Estado"
              name="attention_state"
              rules={[isRequired]}
            >
              <Select placeholder="Selecciona una opción">
                <Option value="PEN">{statusOrderEnum.PEN}</Option>
                <Option value="TAK">{statusOrderEnum.TAK}</Option>
                <Option value="HLD">{statusOrderEnum.HLD}</Option>
                <Option value="DSP">{statusOrderEnum.DSP}</Option>
                <Option value="FIN">{statusOrderEnum.FIN}</Option>
                <Option value="CAN">{statusOrderEnum.CAN}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[8, 16]}>
          <Col xs={24} md={8}>
            <Form.Item label="Cliente" name="client">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Button
              className="btn-success"
              htmlType="submit"
              size="large"
              shape="round"
              loading={props.loading}
              disabled={props.loading}
            >
              Filtrar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const WrappedFiltersOrdersForDispatchForm = forwardRef(
  FiltersOrdersForDispatchForm
);
export default WrappedFiltersOrdersForDispatchForm;
