import React from "react";

/*-- Ant-design --*/
import { Layout, Dropdown, Menu } from "antd";

/*-- Css --*/
import "./CustomHeader.css";

/*-- Custom --*/
import CustomAvatar from "../../../shared/components/Avatar";
import logo from "./../../../../logo.png"; // Tell webpack this JS file uses this image

const { Header } = Layout;

interface Props {
  userName: string;
  userPhoto: string;
  serviceConfigName: string;
  serviceConfigPhoto: string;
  payGOAccounts: any[];
  handlePayGOAccountSelected: any;
  handleSignOut: any;
  handleConfigAccount: any;
}

CustomHeader.defaultProps = {
  userName: "",
  userPhoto: "",
  serviceConfigName: "",
  serviceConfigPhoto: "",
};

export function CustomHeader(props: any) {
  const {
    payGOAccounts,
    handleSignOut,
    handleConfigAccount,
    userName,
    userPhoto,
    serviceConfigName,
    serviceConfigPhoto,
  } = props;

  function handlePayGOAccountSelected(e: any) {
    props.handlePayGOAccountSelected(Number(e.key));
  }

  return (
    <Header className="custom-header">
      <div className="custom-header-logo">
        <img
          style={{ width: "115px", height: "52px" }}
          src={logo}
          alt="logo payGO"
        />
      </div>

      <div className="avatar-menu">
        <div className="avatar-menu-item">
          <Dropdown
            overlay={
              <Menu onClick={handlePayGOAccountSelected}>
                {payGOAccounts?.map((item: any) => {
                  let serviceConfig = item.legacy_service_configuration;

                  return (
                    <Menu.Item key={item.account_id}>
                      {serviceConfig.name}
                    </Menu.Item>
                  );
                })}
              </Menu>
            }
            trigger={["click"]}
          >
            <CustomAvatar
              name={serviceConfigName}
              src={serviceConfigPhoto}
              size="small"
            />
          </Dropdown>
        </div>
        <div className="avatar-menu-item">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={handleSignOut}>
                  Cerrar Sesíon
                </Menu.Item>
                <Menu.Item key="2" onClick={handleConfigAccount}>
                  Configuracion cuenta
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <CustomAvatar name={userName} src={userPhoto} size="small" />
          </Dropdown>
        </div>
      </div>
    </Header>
  );
}
