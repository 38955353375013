import React, { Component } from "react";

/*-- Store --*/
import { connect } from "react-redux";
import { readUser, readUsers } from "../../security/store/actionCreators";
import { selectCustomerToDeliver } from "../../sales/store/actionCreators";
import { readAuthUser } from "../../auth/store/actionCreators";
import { clearMessageInQrScanMain } from "../store/actionCreators";
import { setMessageInQrScanMain } from "../../home/store/actionCreators";

/*-- Ant Design --*/
import { Input, Skeleton, List, Alert, Col, Row } from "antd";
import Text from "antd/lib/typography/Text";

/*-- Font-Awesome --*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*-- Tools --*/
import QrReader from "react-qr-reader";
import jwtDecode from "jwt-decode";
import {
  getPaygoAccountFromServiceConfigSelected,
  getUserFullName,
} from "../../shared/utils/utils";
import {
  Plugins,
  Permissions,
  PermissionType,
  Capacitor,
} from "@capacitor/core";

/*-- Const vars --*/
const { Search } = Input;

class QrScanMain extends Component<any, any> {
  private intervalRefreshAuthUser: any;

  constructor(props: any) {
    super(props);

    Permissions.query({ name: PermissionType.Camera });
  }

  componentDidMount() {
    this.refreshAuthUser();
    const { clearMessageInQrScanMain, showMessage } = this.props;

    if (showMessage.text) {
      setTimeout(() => clearMessageInQrScanMain(), showMessage.time);
    }

    if (Capacitor.platform === "ios") {
      setTimeout(() => {
        console.log("init scan platform ios");
        this.openCodeScanner();
      }, 2000);
    }
  }

  componentWillUnmount() {
    //Cerrar interval
    clearInterval(this.intervalRefreshAuthUser);
  }

  /**
   * Ejecuta la consulta de la información para el
   * usuario autenticado, después de esperar 5 minutos y
   * se repite la ejecución.
   */
  refreshAuthUser() {
    this.intervalRefreshAuthUser = setInterval(
      () => this.props.readAuthUser(),
      60 * 5000
    ); // Cada 5 minutos
  }

  handleScan = (data: string | null) => {
    if (data) {
      try {
        let decoded = jwtDecode(data);
        let userId = decoded.user_id;

        if (userId) {
          this.props.readUser([userId], {}, (user: any) => {
            if (user) {
              this.props.selectCustomerToDeliver(user);
              this.props.history.push("/sales/order-selection");
            }
          });
        }
      } catch (error) {
        console.log("error", error);
        this.props.setMessageInQrScanMain({
          text: `Lo sentimos, no ha sido posible leer el código QR.\n
          Por favor verifique que el QR sea válido.`,
          type: "error",
          time: 4000,
        });
      }
    }
  };

  openCodeScanner() {
    console.log("my libreria ios");
  }

  /**
   * Maneja el evento de busqueda
   * @param value
   */
  handleSearch = (value: string) => {
    let data = {
      search: value,
      temp_role: "CLIENT",
      service_configuration_id: this.props.serviceConfigId,
    };

    this.props.readUsers([], data);
  };

  /**
   * Maneja el evento de seleccionar el cliente
   * @param user
   */
  handleSelectClient(user: any) {
    this.props.selectCustomerToDeliver(user);
    this.props.history.push("/sales/order-selection");
  }

  render() {
    const { serviceConfigId, showMessage } = this.props;
    const platformActive = Capacitor.platform;

    return (
      <div className="p-3 mx-2">
        {showMessage.text ? (
          <Alert
            className="mb-3 text-center"
            message={showMessage.text}
            type={showMessage.type}
          />
        ) : null}
        <h3>Escanear código QR</h3>

        <Row justify="center">
          <Col xs={24} sm={24} md={8}>
            {platformActive === "ios" ? (
              <div></div>
            ) : (
              <QrReader
                delay={1000}
                onError={(error: any) => {}}
                onScan={this.handleScan}
                style={{ width: "100%" }}
              />
            )}
          </Col>
        </Row>

        <Search
          className="my-3"
          placeholder="Buscar por nombre o código"
          onSearch={this.handleSearch}
        />

        <List
          loading={this.props.loadingUsers}
          itemLayout="horizontal"
          dataSource={this.props.users}
          renderItem={(user: any) => {
            let paygoAccount = getPaygoAccountFromServiceConfigSelected(
              user,
              serviceConfigId
            );
            let clientCode = paygoAccount?.client_code || "";

            return (
              <List.Item
                onClick={() => this.handleSelectClient(user)}
                actions={[<FontAwesomeIcon icon={["fas", "chevron-right"]} />]}
              >
                <Skeleton avatar title={false} loading={false} active>
                  <List.Item.Meta
                    title={
                      <Text className="text-capitalize">
                        {getUserFullName(user)}
                      </Text>
                    }
                    description={`codigo: ${clientCode}`}
                  />
                </Skeleton>
              </List.Item>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  users: storeData.security.users,
  loadingUsers: storeData.security.loading_users,
  serviceConfigId: storeData.shared.selected_service_configuration_id,
  showMessage: storeData.home.show_message,
});

const mapDispatchToProps = {
  readUser,
  readUsers,
  readAuthUser,
  selectCustomerToDeliver,
  clearMessageInQrScanMain,
  setMessageInQrScanMain,
};

export default connect(mapStateToProps, mapDispatchToProps)(QrScanMain);
