import React, { Component } from "react";

/*-- Store --*/
import { connect } from "react-redux";
import { readSales } from "../store/actionCreators";

/*-- Enums --*/
import { dateFormat, pagination } from "../enums/OrdersEnums";

/*-- Custom Components --*/
import WrappedFiltersOrders from "../components/FiltersOrders";
import ExpandableItem from "../components/ExpandableItem";
import FilterOrdersTabs from "../components/FiltersOrdersTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row } from "antd";

class OrderManagementMain extends Component<any, any> {
  actualPage = 1;
  filtersApply = {
    page: 1,
    page_size: pagination.page_size,
    service_configuration_id: this.props.serviceConfigId,
    expand: "products,client_info,service_period,node,delivery_node",
    attention_state: "PEN,TAK,HLD,DSP,CAN",
  };

  constructor(props: any) {
    super(props);

    this.state = {
      activeViewHistory: false,
    };
  }

  componentDidMount(): void {
    this.props.readSales([], this.filtersApply);
  }

  /**
   * Evento que aplica los filtros selecionados en la vista historial
   * @param values - valores dekl formulario
   */
  private handleOnSubmit = (values: any) => {
    const cleanData = this.clearDataForm(values);
    const kwargs = {
      ...this.filtersApply,
      ...cleanData,
      attention_state: "FIN",
      page: 1,
    };
    this.actualPage = 1; // Reset page pagination
    this.filtersApply = kwargs;
    this.props.readSales([], kwargs);
  };

  /**
   * Evento que se llama cuando se desea traer la pagina siguiente
   * @param reset - bandera para resetear los valores acumulados
   */
  private handleOnChangePage = (reset: boolean) => {
    if (!reset && this.props.isLastOrders) {
      return;
    }
    this.actualPage = reset ? 1 : this.actualPage + 1;
    const kwargs = {
      ...this.filtersApply,
      page: this.actualPage,
    };
    this.props.readSales([], kwargs, !reset);
  };

  /**
   * Maneja el cambio de vista entre pedidos e historial.
   */
  private handleChangeTypeFilterView = () => {
    let viewIsHistory = !this.state.activeViewHistory;

    const kwargs = {
      page: 1,
      page_size: pagination.page_size,
      service_configuration_id: this.props.serviceConfigId,
      expand: "products,client_info,service_period,node,delivery_node",
      attention_state: viewIsHistory ? "FIN" : "PEN,TAK,HLD,DSP,CAN",
    };
    this.filtersApply = kwargs;
    this.actualPage = 1; // Reset page pagination
    this.props.readSales([], kwargs);
    this.setState({ activeViewHistory: viewIsHistory });
  };

  /**
   * Trae las ordenes dado un estado
   * @param state - string del stado a consultar
   */
  private getOrdersByStatus = (state: string) => {
    const kwargs = {
      ...this.filtersApply,
      page: 1,
      attention_state: state,
    };
    this.filtersApply = kwargs;
    this.actualPage = 1; // Reset page pagination
    this.props.readSales([], kwargs);
  };

  /**
   * Limpia la data del fomulario de filtros
   * @param values objecto de todos los valores
   */
  private clearDataForm = (values: any) => {
    const serializerData = values;
    const inputDate = ["datetime_due_lte", "datetime_due_gte"];
    for (let key in values) {
      if (values[key] === undefined || values[key] === null) {
        delete serializerData[key];
      }
      if (inputDate.includes(key) && values[key]) {
        serializerData[key] = values[key].format(dateFormat);
      }
    }

    return serializerData;
  };

  render() {
    const { isLastOrders, loadingOrders } = this.props;
    let { orders } = this.props;

    return (
      <div>
        <Row justify="space-between" align="middle" className="mx-4 mt-4">
          <h1 className="my-0">
            <strong>Pedidos</strong>
          </h1>
          <p
            className={`font-w600 mb-0 ${
              this.state.activeViewHistory ? "text-active" : ""
            }`}
            onClick={this.handleChangeTypeFilterView}
          >
            Historial
            <FontAwesomeIcon className="ml-2" icon={["fas", "search"]} />
          </p>
        </Row>
        {this.state.activeViewHistory ? (
          <WrappedFiltersOrders
            loading={loadingOrders}
            onSubmit={this.handleOnSubmit}
          />
        ) : (
          <FilterOrdersTabs onChangeTab={this.getOrdersByStatus} />
        )}

        <div className="mx-2">
          <ExpandableItem
            onChangePage={this.handleOnChangePage}
            isLastOrders={isLastOrders}
            loader={loadingOrders}
            sales={orders}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  loadingOrders: storeData.sales.loading_orders,
  orders: storeData.sales.orders,
  isLastOrders: storeData.sales.is_last_orders,
  serviceConfigId: storeData.shared.selected_service_configuration_id,
});

const mapDispatchToProps = {
  readSales,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderManagementMain);
