import {
  CLEAR_USERS,
  LOADING_USERS,
  READ_USERS,
  READ_USER,
  LOADING_DELETE_ACCOUNT,
} from "./actionTypes";

const INITIAL_STATE: any = {
  users: [],
  user: null,
  loading_users: false,
  loading_delete_account: false,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case READ_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case LOADING_USERS:
      return {
        ...state,
        loading_users: action.payload,
      };
    case READ_USER:
      return {
        ...state,
        user: action.payload,
      };
    case CLEAR_USERS:
      return {
        ...state,
        users: [],
      };
    case LOADING_DELETE_ACCOUNT:
      return {
        ...state,
        loading_delete_account: action.payload,
      };
    default:
      return state;
  }
}
