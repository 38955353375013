import React, { Component } from "react";
import { connect } from "react-redux";

/*-- Store --*/
import { alertFeedback } from "../../shared/store/actionCreators";
import { generateQRCode } from "../../home/store/actionCreators";
import { signOut, readAuthUser } from "../../auth/store/actionCreators";
import {
  readWallets,
  readPaygoAccountWallets,
} from "../../business/store/actionCreators";
import { readSales } from "../../sales/store/actionCreators";

/*-- Ant Design --*/
import { Col, Row } from "antd";
import Text from "antd/lib/typography/Text";

/*-- Components --*/
import CustomerInformation from "../components/CustomerInformation";
import CustomerBalances from "../components/CustomerBalances";
import ExpandableItem from "../../sales/components/ExpandableItem";

/*-- Tools --*/
import { getUserFullName, openMessage } from "../../shared/utils/utils";

/*-- Enums --*/
import { pagination } from "../../sales/enums/OrdersEnums";

/*-- Capacitor --*/
import {
  Plugins,
  PushNotification,
  PushNotificationActionPerformed,
  Capacitor,
} from "@capacitor/core";

/*-- Custom Services --*/
import { getWallets, getBalances } from "../../sales/services/SaleService";

const { PushNotifications } = Plugins;

class HomeMain extends Component<any, any> {
  private intervalRefreshQR: any;
  private intervalRefreshPaygoAccWallets: any;
  private intervalRefreshAuthUser: any;
  private paygoAccountSelected: any;
  private currentPage = 1;

  constructor(props: any) {
    super(props);

    this.state = {
      qr_string: "",
      paygoAccWallets: [],
      balancesLoading: false,
    };
  }

  async componentDidMount() {
    this.getInitialData();
    this.refreshQr();
    this.refreshAuthUser();

    if (Capacitor.isNative) {
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotification) => {
          openMessage("info", notification.body || "");
        }
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: PushNotificationActionPerformed) => {
          this.props.history.push("/messages");
        }
      );
    }
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (prevProps.serviceConfigId !== this.props.serviceConfigId) {
      this.currentPage = 1;
      this.getInitialData();
    }
  }

  componentWillUnmount() {
    //Cerrar interval
    clearInterval(this.intervalRefreshQR);
    clearInterval(this.intervalRefreshAuthUser);
    clearInterval(this.intervalRefreshPaygoAccWallets);

    if (Capacitor.isNative) {
      PushNotifications.removeAllListeners();
    }
  }

  /**
   * Obtiene la información inicial
   */
  getInitialData() {
    this.generateQr();
    this.getOrders(this.currentPage);
    this.props.readAuthUser();

    const { user, selectedPaygoAccountId, serviceConfigId } = this.props;
    this.paygoAccountSelected = user?.paygo_accounts?.find(
      (p: any) => p.account_id == selectedPaygoAccountId
    );

    if (this.paygoAccountSelected != undefined) {
      this.setState({ balancesLoading: true });
      getWallets({
        readWallets: this.props.readWallets,
        selectedServiceConfigurationId: serviceConfigId,
        cb: this.getWalletsCB,
      });
      this.refreshBalances();
    }
  }

  /**
   * Función callback de getWallets que consulta los balances del cliente.
   */
  private getWalletsCB = (response: any) => {
    getBalances({
      paygoAccWallets: response.paygoAccWallets,
      selectedAccount: this.props.selectedPaygoAccountId,
      readPaygoAccountWallets: this.props.readPaygoAccountWallets,
      cb: this.getBalancesCB,
    });
  };

  /**
   * Función callback de getBalances que actualiza el estado de las accwallets
   */
  private getBalancesCB = (response: any) => {
    if (!response.error)
      this.setState({ paygoAccWallets: response.paygoAccWallets });
    this.setState({ balancesLoading: false });
  };

  /**
   * Ejecuta la lectura de los balances del cliente, después de
   * esperar 4 minutos y se repite la ejecución.
   */
  refreshBalances() {
    this.intervalRefreshPaygoAccWallets = setInterval(
      () =>
        getBalances({
          paygoAccWallets: this.state.paygoAccWallets,
          selectedAccount: this.props.selectedPaygoAccountId,
          readPaygoAccountWallets: this.props.readPaygoAccountWallets,
          cb: this.getBalancesCB,
        }),
      60 * 4000
    ); // Cada 4 minutos
  }

  /**
   * Obtiene los pedidos pendientes por entregar
   * @param page
   * @param reset
   */
  getOrders(page: number, reset: boolean = true) {
    const kwargs = {
      page: page,
      page_size: pagination.page_size,
      service_configuration_id: this.props.serviceConfigId,
      attention_state: "DSP",
      expand: "products,client_info,service_period,node,delivery_node",
    };
    this.props.readSales([], kwargs, !reset);
  }

  /**
   * Realiza la petición para generar el código QR
   */
  generateQr() {
    const { selectedPaygoAccountId } = this.props;
    const onSuccess = (token: string) => {
      this.setState({ qr_string: token ?? "" });
    };
    const onError = () => {};
    if (selectedPaygoAccountId) {
      this.props.generateQRCode(selectedPaygoAccountId, onSuccess, onError);
    }
  }

  /**
   * Ejecuta la generación del qr, después de
   * esperar 9 minutos y se repite la ejecución.
   */
  refreshQr() {
    this.intervalRefreshQR = setInterval(() => this.generateQr(), 60 * 9000); // Cada 9 minutos
  }

  /**
   * Ejecuta la consulta de la información para el
   * usuario autenticado, después de esperar 5 minutos y
   * se repite la ejecución.
   */
  refreshAuthUser() {
    this.intervalRefreshAuthUser = setInterval(
      () => this.props.readAuthUser(),
      60 * 5000
    ); // Cada 5 minutos
  }

  /**
   * Maneja el scroll infinito de las ordenes
   * @param reset
   */
  handleOnChangeOrdersPage = (reset: boolean = false) => {
    if (this.props.isLastOrders) {
      return;
    }

    this.currentPage = reset ? 1 : this.currentPage + 1;
    this.getOrders(this.currentPage, reset);
  };

  render() {
    const { user, orders, isLastOrders } = this.props;
    let fullName = getUserFullName(user);
    let userPhoto = "",
      userGroup = "",
      userBulkGroup = "",
      group = null;

    if (this.paygoAccountSelected != undefined) {
      userPhoto = this.paygoAccountSelected.legacy_client.photo;
      userBulkGroup = this.paygoAccountSelected.legacy_client?.bulk_group;
      userGroup = this.paygoAccountSelected.legacy_client?.group;
    }

    if (userBulkGroup && userGroup) {
      group = (
        <Text type="secondary">
          {userBulkGroup}: {userGroup}
        </Text>
      );
    } else if (userBulkGroup || userGroup) {
      group = <Text type="secondary">{userBulkGroup || userGroup}</Text>;
    }

    return (
      <div className="p-3 mx-2">
        <Row>
          <Col xs={24} sm={24} md={24}>
            <h2 className="text-capitalize m-block-none">{fullName}</h2>
            {group}
          </Col>
        </Row>

        <Row gutter={10} className="my-3">
          <Col xs={24} sm={24} md={14}>
            <CustomerInformation
              qrValue={this.state.qr_string}
              userName={fullName}
              userPhoto={userPhoto}
            />
          </Col>

          <Col xs={24} sm={24} md={7}>
            <Text type="secondary" strong>
              Saldos
            </Text>

            <CustomerBalances
              loading={this.state.balancesLoading}
              balances={this.state.paygoAccWallets}
            />
          </Col>
        </Row>

        {orders?.length == 0 ? null : (
          <div className="my-2">
            <h2>Pedidos próximos a llegar</h2>

            <ExpandableItem
              onChangePage={this.handleOnChangeOrdersPage}
              isLastOrders={isLastOrders}
              sales={orders}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeData: any) => ({
  user: storeData.auth.user,
  wallets: storeData.business.wallets,
  paygoAccountWallets: storeData.business.paygo_account_wallets,
  selectedPaygoAccountId: storeData.shared.selected_paygo_account_id,
  serviceConfigId: storeData.shared.selected_service_configuration_id,
  orders: storeData.sales.orders,
  isLastOrders: storeData.sales.is_last_orders,
});

const mapDispatchToProps = {
  alertFeedback,
  generateQRCode,
  signOut,
  readWallets,
  readPaygoAccountWallets,
  readSales,
  readAuthUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeMain);
