import React from "react";

import Text from "antd/lib/typography/Text";
import { Divider, Row, Col, Button } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  messages: any;
  messageId: any;
  history: any;
}

const MessageRead = (props: Props) => {
  const message = props.messages?.results?.find(
    (msg: any) => msg.message_id == props.messageId
  );

  if (!message) return <></>;

  return (
    <div className="message-till">
      <Row
        onClick={() => {
          props.history.push("/messages");
        }}
        align="middle"
        style={{ background: "#F8F8F8", padding: "20px 20px 20px 2rem" }}
        className="text-lato-title"
      >
        <FontAwesomeIcon
          icon={["fas", "angle-left"]}
          style={{ fontSize: "1.5em", marginRight: "1rem" }}
        />
        <Text strong style={{ fontSize: 22 }}>
          {message.title}
        </Text>
      </Row>
      <Row className="text-lato message-globe">
        <Col span={24}>
          <Text>{message.text}</Text>
        </Col>
        <Col span={24} style={{ textAlign: "right" }}>
          <Text style={{ color: "#939393a6" }}>
            {moment(message.dispatch_datetime).format("MMMM DD h:mm a")}
          </Text>
        </Col>
      </Row>
    </div>
  );
};

export default MessageRead;
