/*-- Date Rules --*/
export const READ_DATE_RULES = "READ_DATE_RULES";

/*-- Service Periods --*/
export const READ_SERVICE_PERIOD = "READ_SERVICE_PERIOD";
export const READ_SERVICE_PERIODS = "READ_SERVICE_PERIODS";
export const LOADING_SERVICE_PERIODS = "LOADING_SERVICE_PERIODS";
export const STORE_SERVICE_PERIOD = "STORE_SERVICE_PERIOD";
export const RESET_SERVICE_PERIOD = "RESET_SERVICE_PERIOD";
export const ENABLE_SERVICE_PERIOD_BUTTON_SAVE =
  "ENABLE_SERVICE_PERIOD_BUTTON_SAVE";
export const UPDATE_SERVICE_PERIOD = "UPDATE_SERVICE_PERIOD";
export const DELETE_SERVICE_PERIOD = "DELETE_SERVICE_PERIOD";
export const READ_TIME_RULES = "READ_TIME_RULES";
export const LOADING_TIME_RULES = "LOADING_TIME_RULES";
