import {
  ALERT_FEEDBACK,
  SELECT_PAYGO_ACCOUNT,
  SELECT_SERVICE_CONFIGURATION,
} from "./actionTypes";

const INITIAL_STATE = {
  alertFeedback: {
    show: false,
    description: "",
    message: "",
    type: "info",
  },
  selected_paygo_account_id: null,
  selected_service_configuration_id: null,
};

export default function (state: any = INITIAL_STATE, action: any) {
  switch (action.type) {
    case ALERT_FEEDBACK:
      return {
        ...state,
        alertFeedback: action.payload,
      };
    case SELECT_PAYGO_ACCOUNT:
      return {
        ...state,
        selected_paygo_account_id: action.payload,
      };
    case SELECT_SERVICE_CONFIGURATION:
      return {
        ...state,
        selected_service_configuration_id: action.payload,
      };
    default:
      return state;
  }
}
