import React, { useEffect, useState } from "react";

/*-- Components --*/
import WrappedFiltersOrdersForDispatchForm from "../components/FiltersOrdersForDispatchForm";
import OrdersForDispatchList from "../components/OrdersForDispatchList";

/*-- Enums --*/
import { dateFormat } from "../enums/OrdersEnums";

/*-- Store --*/
import { connect } from "react-redux";
import { readServicePeriods } from "../../service_periods/store/actionCreators";
import {
  readSales,
  applyActionsOrders,
  clearSales,
} from "../store/actionCreators";
import { readUsers } from "../../security/store/actionCreators";

/*-- Ant-design --*/
import { Divider } from "antd";

/*-- Utils --*/
import { openMessage } from "../../shared/utils/utils";

function ReadyForDispatchMain(props: any) {
  const {
    selectedServiceConfigurationId,
    servicePeriods,
    loadingOrders,
    orders,
    readServicePeriods,
    clearSales,
    readUsers,
    users,
    loaderApplyAction,
  } = props;

  const [groups, setGroups] = useState([] as any);
  const [children, setChildren] = useState({} as any);
  const [showReceiveButton, setShowReceiveButton] = useState(false);

  /**
   * Limpia la data del fomulario de filtros
   * @param values objecto de todos los valores
   */
  function clearDataForm(values: any) {
    const serializerData = values;
    const inputDate = ["datetime_due"];
    for (let key in values) {
      if (values[key] === undefined || values[key] === null) {
        delete serializerData[key];
      }
      if (inputDate.includes(key) && values[key]) {
        serializerData[key] = values[key].format(dateFormat);
      }
    }

    return serializerData;
  }

  function handleOnSubmit(values: any) {
    if (selectedServiceConfigurationId) {
      const cleanData = clearDataForm(values);
      cleanData["service_configuration_id"] = selectedServiceConfigurationId;
      const kwargs = {
        page: 1,
        page_size: 1000,
        expand: "client_info,service_period,delivery_node",
        ...cleanData,
      };

      if (values.client) {
        let data = {
          search: values.client,
          temp_role: "CLIENT",
          service_configuration_id: selectedServiceConfigurationId,
        };

        readUsers([], data, (data: any) => {
          let ii_clients = [];

          for (let client of data) {
            let ii_client: any = null;

            for (let account of client.paygo_accounts) {
              if (
                account.service_configuration_id ===
                selectedServiceConfigurationId
              ) {
                ii_client = account.legacy_client?.ii;
                break;
              }
            }

            if (ii_client) ii_clients.push(ii_client);
          }

          if (ii_clients.length > 0) {
            kwargs["client"] = ii_clients.join(",");
          }
        });
      }

      // Permitir recibir pedidos si estos están en estado "Preparados" (En espera - HLD)
      if (cleanData["attention_state"] === "HLD") {
        setShowReceiveButton(true);
      } else {
        setShowReceiveButton(false);
      }

      props.readSales([], kwargs);
    } else {
      openMessage("error", "Indique la entidad asociada.");
    }
  }

  function handleReceiveButton(selectedItems: Array<any>) {
    const selectedOrders = [];
    for (const item of selectedItems) {
      const splitItem = item.split("_");
      const orderId = parseInt(splitItem[1]);
      selectedOrders.push(orderId);
    }
    const params = {
      service_configuration_id: selectedServiceConfigurationId,
      selected_items: selectedOrders,
      massive_action_id: 3, //Despachar
      type_view: "3", //vista tipo DETAILED
    };
    props.applyActionsOrders(
      params,
      () => {
        openMessage("success", "Operación exitosa");
        props.clearSales();
      },
      (res: any) => {
        openMessage("error", "Operación fallida. " + res);
      }
    );
  }

  function makeChildren(group: any, prefix: string) {
    const children = [];
    for (const item of group) {
      const clientInfo = item["client_info"];
      const childInfo = {
        child_id: prefix + "_" + item["ii"],
        sale_id: item["ii"],
        client_id: item["ii_client"],
        client_name: clientInfo["names"] + " " + clientInfo["lastnames"],
      };
      children.push(childInfo);
    }
    return children;
  }

  useEffect(() => {
    clearSales();
  }, [clearSales]);

  useEffect(() => {
    if (selectedServiceConfigurationId) {
      const params = {
        page: 1,
        page_size: 10000,
        service_configuration_id: selectedServiceConfigurationId,
        is_active: true,
      };
      readServicePeriods([], params);
    }
  }, [selectedServiceConfigurationId, readServicePeriods]); // Solo se vuelve a ejecutar si selectedServiceConfigurationId cambia

  useEffect(() => {
    function setGroupAndChildrenStructures(groupedOrders: any) {
      const auxGroups = [];
      const auxChildren: any = {};
      let idxGroup = 1;
      for (const item in groupedOrders) {
        const firstElement = groupedOrders[item][0];
        const prefix = "group" + idxGroup;
        const groupInfo = {
          group_id: prefix,
          service_period_name: firstElement["service_period"]
            ? firstElement["service_period"]["name"]
            : "",
          delivery_node_name: firstElement["delivery_node"]
            ? firstElement["delivery_node"]["name"]
            : "",
        };
        // Para un el grupo de pedidos en la iteración se obtiene la información los clientes.
        const childrenInfo = makeChildren(groupedOrders[item], prefix);
        auxGroups.push(groupInfo);
        auxChildren[prefix] = childrenInfo;
        idxGroup++; // Se incrementa el id del grupo.
      }
      setGroups(auxGroups);
      setChildren(auxChildren);
    }

    function groupOrders(values: any) {
      const groups: any = {};
      for (const order of values) {
        const group_key = order["ii_delivery_node"];
        if (!groups.hasOwnProperty(group_key)) {
          groups[group_key] = [];
        }
        groups[group_key].push(order);
      }
      return groups;
    }

    let groupedOrders: any = {};
    if (orders.length > 0) {
      groupedOrders = groupOrders(orders);
    }
    setGroupAndChildrenStructures(groupedOrders);
  }, [orders]);

  return (
    <div>
      <WrappedFiltersOrdersForDispatchForm
        servicePeriods={servicePeriods}
        loading={loadingOrders}
        onSubmit={handleOnSubmit}
      />
      <Divider orientation="left" />
      <OrdersForDispatchList
        groups={groups}
        children={children}
        loading={loadingOrders}
        loadingApplyAction={loaderApplyAction}
        showReceiveButton={showReceiveButton}
        onReceiveButton={handleReceiveButton}
      />
    </div>
  );
}

const mapStateToProps = (storeData: any) => ({
  servicePeriods: storeData.service_periods.service_periods,
  selectedServiceConfigurationId:
    storeData.shared.selected_service_configuration_id,
  loadingOrders: storeData.sales.loading_orders,
  loaderApplyAction: storeData.sales.loading_apply_actions,
  orders: storeData.sales.orders,
  users: storeData.security.users,
});

const mapDispatchToProps = {
  readServicePeriods,
  readSales,
  applyActionsOrders,
  clearSales,
  readUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadyForDispatchMain);
