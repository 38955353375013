import React, { useEffect, useState } from "react";

/*-- Ant-design --*/
import { Button, Checkbox, Collapse, Empty, List } from "antd/es";
import { Row, Col, Divider, Spin } from "antd";

const { Panel } = Collapse;

interface Props {
  groups: Array<any>;
  children: any; // Object
  loading: boolean;
  loadingApplyAction: boolean;
  showReceiveButton: boolean;
  onReceiveButton: Function;
}

export default function OrdersForDispatchList(props: Props) {
  const {
    groups,
    children,
    showReceiveButton,
    loading,
    loadingApplyAction,
  } = props;
  const empty = "--empty--";

  const [checkAll, setCheckAll] = useState(false);
  const [groupsCheckbox, setGroupsCheckbox] = useState([] as Array<any>);
  const [childrenCheckbox, setChildrenCheckbox] = useState({} as any);

  function onCheckAllChange(event: any) {
    setCheckAll(event.target.checked);
  }

  function onGroupCheckChange(event: any, groupId: any) {
    const auxGroupsCheckbox = groupsCheckbox.map((item) => {
      return item.group_id === groupId
        ? { ...item, checked: event.target.checked }
        : item;
    });
    setGroupsCheckbox(auxGroupsCheckbox);
  }

  function onChildCheckChange(event: any, childId: any) {
    const groupId = childId.split("_")[0];
    const auxChildrenCheckbox = { ...childrenCheckbox };
    auxChildrenCheckbox[groupId] = childrenCheckbox[groupId].map(
      (item: any) => {
        return item.child_id === childId
          ? { ...item, checked: event.target.checked }
          : item;
      }
    );
    setChildrenCheckbox(auxChildrenCheckbox);

    const auxGroupsCheckbox = groupsCheckbox.map((item) => {
      const unmarked = auxChildrenCheckbox[item.group_id].filter(
        (el: any) => !el.checked
      );
      return { ...item, checked: unmarked.length === 0 };
    });
    setGroupsCheckbox(auxGroupsCheckbox);
  }

  function onReceiveButton() {
    const selectedChildren: Array<any> = [];
    for (const key in childrenCheckbox) {
      for (const child of childrenCheckbox[key]) {
        if (child.checked) {
          selectedChildren.push(child.child_id);
        }
      }
    }
    props.onReceiveButton(selectedChildren);
  }

  useEffect(
    () => {
      if (checkAll) {
        // Se marcan todos los grupos.
        const auxGroupsCheckbox = groupsCheckbox.map((item) => {
          return { ...item, checked: true };
        });
        setGroupsCheckbox(auxGroupsCheckbox);
      }
    },
    // eslint-disable-next-line
    [checkAll]
  );

  useEffect(
    () => {
      let auxCheckAll = true;
      const auxChildrenCheckbox = { ...childrenCheckbox };
      for (const el of groupsCheckbox) {
        // Si uno de los grupos ha sido marcado, se marcan los hijos tambien.
        if (el.checked) {
          auxChildrenCheckbox[el.group_id] = childrenCheckbox[el.group_id].map(
            (item: any) => {
              return { ...item, checked: true };
            }
          );
        } else {
          auxCheckAll = false;
        }
      }

      setChildrenCheckbox(auxChildrenCheckbox);
      // Si un grupo es desmarcado, se desmarca la casilla de Check All.
      setCheckAll(auxCheckAll);
    },
    // eslint-disable-next-line
    [groupsCheckbox]
  );

  useEffect(() => {
    const auxGroupsCheckbox = [];
    const auxChildrenCheckbox: any = {};

    for (const group of groups) {
      auxGroupsCheckbox.push({ ...group, checked: false });
    }

    for (const key in children) {
      const auxChildrenList = [];
      for (const child of children[key]) {
        auxChildrenList.push({ ...child, checked: false });
      }
      auxChildrenCheckbox[key] = auxChildrenList;
    }
    setGroupsCheckbox(auxGroupsCheckbox);
    setChildrenCheckbox(auxChildrenCheckbox);
  }, [groups, children]);

  return (
    <div className="mr-3 ml-3 mb-3">
      <Row gutter={[8, 8]}>
        <Col span={12} offset={6}>
          <Checkbox checked={checkAll} onChange={onCheckAllChange}>
            Seleccionar todo
          </Checkbox>
          <Divider />
        </Col>
      </Row>
      {loading && (
        <div style={{ textAlign: "center" }}>
          <Spin style={{ margin: "1rem" }} />
          Cargando...
        </div>
      )}
      {groupsCheckbox.length > 0 && Object.keys(childrenCheckbox).length > 0 ? (
        <>
          <Row gutter={[8, 24]}>
            <Col span={24}>
              <Collapse expandIconPosition="right">
                {groupsCheckbox.map((item: any) => (
                  <Panel
                    header={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div>
                            <strong>
                              {item.service_period_name
                                ? item.service_period_name
                                : empty}
                            </strong>
                          </div>
                          <div>
                            {item.delivery_node_name
                              ? item.delivery_node_name
                              : empty}
                          </div>
                        </div>
                        <div style={{ marginLeft: "auto", order: 2 }}>
                          <Checkbox
                            checked={item.checked}
                            onChange={(e: any) =>
                              onGroupCheckChange(e, item.group_id)
                            }
                            onClick={(event: any) => {
                              // If you don't want click extra trigger collapse, you can prevent this:
                              event.stopPropagation();
                            }}
                          />
                        </div>
                      </div>
                    }
                    key={item.group_id}
                  >
                    <List
                      itemLayout="horizontal"
                      dataSource={childrenCheckbox[item.group_id]}
                      renderItem={(child: any) => (
                        <List.Item>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "95%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <strong># Pedido: {child.sale_id}</strong>
                              </div>
                              <div>{child.client_name}</div>
                            </div>
                            <div style={{ marginLeft: "auto", order: 2 }}>
                              <Checkbox
                                checked={child.checked}
                                onChange={(e: any) =>
                                  onChildCheckChange(e, child.child_id)
                                }
                              />
                            </div>
                          </div>
                        </List.Item>
                      )}
                    />
                  </Panel>
                ))}
              </Collapse>
            </Col>
          </Row>
          {showReceiveButton && (
            <Row justify="center">
              <Col>
                <Button
                  className="btn-success"
                  shape="round"
                  onClick={onReceiveButton}
                  loading={loadingApplyAction}
                  disabled={loadingApplyAction}
                >
                  RECIBIR
                </Button>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Empty />
      )}
    </div>
  );
}
