import React from "react";
import AlertFeedback from "../../shared/components/AlertFeedback";
import SettingsMain from "../containers/SettingsMain";

const Settings = (props: any) => {
  return (
    <React.Fragment>
      <AlertFeedback />
      <SettingsMain {...props} />
    </React.Fragment>
  );
};

export default Settings;
