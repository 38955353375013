import React, { forwardRef } from "react";

/*-- Ant Design --*/
import { Form, Input, DatePicker, Button, Row, Col } from "antd";

/*-- Tools --*/
import { Callbacks } from "rc-field-form/lib/interface";
import { FormInstance } from "antd/lib/form";

interface Props {
  onSubmit: Callbacks["onFinish"];
  loading: boolean;
}

const FiltersOrders = (props: Props, ref: React.Ref<FormInstance>) => {
  return (
    <div className="px-4">
      <Form ref={ref} onFinish={props.onSubmit} layout={"vertical"}>
        <Row gutter={[8, 2]}>
          <Col className="gutter-row" xs={12} sm={8} md={12} lg={6}>
            <Form.Item label="Desde" name="datetime_due_gte" rules={[]}>
              <DatePicker className="w-100-pr" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12} sm={8} md={12} lg={6}>
            <Form.Item label="Hasta" name="datetime_due_lte" rules={[]}>
              <DatePicker className="w-100-pr" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12} sm={8} md={12} lg={6}>
            <Form.Item label="Número de factura" name="invoice_id" rules={[]}>
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12} sm={8} md={12} lg={6}>
            <Form.Item label="Producto" name="product_name" rules={[]}>
              <Input />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row text-center mt-auto"
            xs={24}
            sm={16}
            md={24}
            lg={24}
          >
            <Form.Item>
              <Button
                style={{ marginTop: "1rem" }}
                type="primary"
                shape="round"
                size="large"
                className="btn-success"
                htmlType="submit"
                disabled={props.loading}
              >
                Filtrar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const WrappedFiltersOrders = forwardRef(FiltersOrders);
export default WrappedFiltersOrders;
