import React from "react";

/*-- Ant Design --*/
import { Row, Col, Divider, Typography } from "antd";
import Text from "antd/lib/typography/Text";

/*-- Components --*/
import CustomAvatar from "../../shared/components/Avatar";

/*-- Enums --*/
import { dateFormatExtend } from "../enums/OrdersEnums";

/*-- Tools --*/
import moment from "moment";

interface Props {
  userName: string;
  userPhoto?: string;
  orderNumber: string;
  servicePeriodName: string;
  orderDate: string;
  delivery_node_name: string;
}

const { Title } = Typography;

const CustomerDeliveryInformation = (props: Props) => {
  const {
    userName,
    userPhoto,
    orderNumber,
    delivery_node_name,
    orderDate,
    servicePeriodName,
  } = props;

  return (
    <>
      <Row className="my-2 mb-3" align="middle">
        <Col xs={11} sm={12} md={6} lg={8} xl={10}>
          <CustomAvatar src={userPhoto} name={userName} />
        </Col>

        <Col xs={13} sm={24} md={12} lg={8} xl={10}>
          <h4 className="text-capitalize m-block-none">{userName}</h4>
          <Text strong>
            Pedido #:{" "}
            <Title style={{ color: "#31C81B" }} level={4}>
              {orderNumber}
            </Title>
          </Text>
          <Divider style={{ margin: "4px 0" }} />
          <Text strong>{servicePeriodName}</Text> <br />
          <Text strong>{moment(orderDate).format(dateFormatExtend)}</Text>{" "}
          <br />
          <Text strong>{delivery_node_name}</Text>
        </Col>
      </Row>
    </>
  );
};

export default CustomerDeliveryInformation;
